import CurrenciesOperations from '../../services/operations/currenciesOperations';
import currenciesApiHandler from '../../services/api/currenciesApiHandler';
import CurrenciesController from '../../controllers/currenciesController';
import {CURRENCY_TYPES} from '../../core/constants/actionTypes';

const currenciesOperations = new CurrenciesOperations(currenciesApiHandler);
const currenciesController = new CurrenciesController(currenciesOperations);

const currencyConfig = {
  [CURRENCY_TYPES.CNTRL_GET_CURRENCIES]: currenciesController.getCurrencies,
};

export default currencyConfig;
