import React, { useState } from 'react';
import LastMatches from './lastMatches';
import GameHeader from './gameHeader';
import GameBody from './gameBody';
import UpcomingGameFooter from './upcomingGameFooter';
import ForChange from './forChange';
import {EGameStatuses, ERoundStatuses, SelectionTypes} from '../../../core/constants/enums';
import GameFooter from './GameFooter';
import t from '../../../core/staticData/translation';
import PointDetails from './pointDeatails';
import useDidUpdateEffect from '../../../core/customHooks/useDidUpdateEffect';
import useTranslation from '../../../core/customHooks/useTranslation';
import { getPartnerSettings } from '../../../state-management/accessors/partnerAccessors';
import { connect } from 'react-redux';
import { STATUS_CLASSNAMES} from '../../../core/constants/statusClassnames';
import { getRoundInfo } from '../../../state-management/accessors/lobbyAccessors';
import { SCROLLABLE_CHILDREN } from '../../../core/constants/userTooltipGuide';


const Game = ({ match: { prediction, predictionResult,  status, date, homeTeam, awayTeam, roundMatchId, matchId, matchResult },
                predictionsForBet,
                setPredictionsForBet,
                onPredictionsClick,
                smartPick,
                clearAll,
                usesLogoImages,
                isParticipant,
                roundStatus,
                tooltipValidationData,
                zIndex,
                isRotateDisabled,
                index
              }) => {
  const translate = useTranslation();
  const [ showBackSpace, setShowBackSpace ] = useState(false);
  const [ icon, setIcon ] = useState(null);
  const isUpcoming = status === EGameStatuses.UPCOMING;
  const isCanceledOrIsPostponed = status === EGameStatuses.CANCELED || status === EGameStatuses.POSTPONED;
  const isLiveOrFinished = status === EGameStatuses.LIVE || status === EGameStatuses.FINISHED;

  useDidUpdateEffect(() => {
    if(showBackSpace) {
      setShowBackSpace(false);
    }
  }, [smartPick, clearAll]);

  const getMatchResult = () => {
    return matchResult ? matchResult : { homeTeamScore: '', awayTeamScore: '' }
  };

  const homeTeamUrl = usesLogoImages ? homeTeam.imageUrl: homeTeam.jerseyUrl;
  const awayTeamUrl = usesLogoImages ? awayTeam.imageUrl : awayTeam.jerseyUrl;

  const getGameFooterImageUrl = () => {
    const selectionValues  = prediction?.selectionValues;
    const ftts = selectionValues?.find(item => item.selectionType === SelectionTypes.FirstTeamToScore)?.selectionSubValues?.[0]?.value;
    return ftts === 1 ? homeTeamUrl : ftts === 2 ? awayTeamUrl : null;
  };

  const handleShowBackSpace = (show) => {
    if (isRotateDisabled) return;
    setShowBackSpace(show);
  };

  const gameFooterImageUrl = getGameFooterImageUrl();
  const firstScoredTeamUrl = matchResult?.ftts === 1 ? homeTeamUrl: matchResult?.ftts === 2 ? awayTeamUrl: null;
  const [ predictionFtts ] = prediction?.selectionValues?.find(item => item.selectionType === SelectionTypes.FirstTeamToScore)?.selectionSubValues || [];
  const gameFooterTitle = predictionFtts?.value === 1 ? (homeTeam.shortName && homeTeam.shortName.length > 0 ? homeTeam.shortName : homeTeam.name) : predictionFtts?.value === 2 ? (awayTeam.shortName && awayTeam.shortName.length > 0 ? awayTeam.shortName : awayTeam.name) : translate(t.NONE);
  const fttsTitle = matchResult?.ftts === 1 ? (homeTeam.shortName && homeTeam.shortName.length > 0 ? homeTeam.shortName : homeTeam.name) : matchResult?.ftts === 2 ? (awayTeam.shortName && awayTeam.shortName.length > 0 ? awayTeam.shortName : awayTeam.name) : translate(t.NONE);
  const isRoundCanceled = roundStatus === ERoundStatuses.CANCELED;
  const showPredictionTooltips = isUpcoming && !isRoundCanceled;

  return (
    <div
      data-scroll-child={SCROLLABLE_CHILDREN.GAME_ITEM}
      style={{ zIndex }}
      className={`game-list-item ${showBackSpace? 'for-backspace' : ''} ${isLiveOrFinished && prediction?.status !== null && !isRoundCanceled && (!!predictionResult || isParticipant)? STATUS_CLASSNAMES[prediction?.status]: ''} active`}>{/*add/remove class active*/}{/*add/remove class status[1-4]*/}
      <div className="for-game-list-item">
        <div className="inside-game-list-item">
          <GameHeader setShowBackSpace={handleShowBackSpace}
                      date={date} status={status}
                      isRotateDisabled={isRotateDisabled}
                      point={predictionResult? predictionResult.wonPoint : 0}
                      currentMinute={matchResult && matchResult.currentMinute}
                      hasPredictionResult={!!predictionResult}
          />
          <div className="for-bottom-of-inside-game-list-i">
            <div className="bottom-inside-game-list-i-wr">
              <GameBody matchResult={getMatchResult()}
                        predictionsForBet={predictionsForBet}
                        setPredictionsForBet={setPredictionsForBet}
                        isDoubled={prediction?.isDoubled}
                        roundMatchId={roundMatchId}
                        homeTeam={homeTeam}
                        awayTeam={awayTeam}
                        setIcon={setIcon}
                        isUpcoming={isUpcoming}
                        showPredictionTooltips={showPredictionTooltips}
                        isCanceledOrIsPostponed={isCanceledOrIsPostponed}
                        status={status}
                        smartPick={smartPick}
                        clearAll={clearAll}
                        onPredictionsClick={onPredictionsClick}
              />
              {(isUpcoming || isCanceledOrIsPostponed) ?
                <UpcomingGameFooter homeTeam={homeTeam}
                                    awayTeam={awayTeam}
                                    predictionsForBet={predictionsForBet}
                                    setPredictionsForBet={setPredictionsForBet}
                                    onPredictionsClick={onPredictionsClick}
                                    status={status}
                                    roundMatchId={roundMatchId}
                                    setIcon={setIcon}
                                    clearAll={clearAll}
                                    showPredictionTooltips={showPredictionTooltips}
                                    tooltipValidationData={tooltipValidationData}
                                    index={index}
                /> :
              <GameFooter prediction={prediction}
                          imageUrl={gameFooterImageUrl}
                          title={gameFooterTitle}
                          firstScoredTeamUrl={firstScoredTeamUrl}
                          fttsTitle={fttsTitle}
                          ftts={matchResult?.ftts}
              />}
            </div>
          </div>
        </div>
        <div className="inside-game-list-history-wr">
          {isUpcoming?
            <LastMatches setShowBackSpace={handleShowBackSpace}
                         showBackSpace={showBackSpace}
                         matchId={matchId}
                         status={status}/> :
            <PointDetails setShowBackSpace={handleShowBackSpace}
                          predictionResult={predictionResult} />}
        </div>
        {icon && <ForChange iconType={icon} />}
        {isCanceledOrIsPostponed && <ForChange />}
      </div>
    </div>
  )
};

Game.defaultProps = {

};

const mapStateToProps = state => ({
  usesLogoImages: getPartnerSettings(state).usesLogoImages,
  roundStatus: getRoundInfo(state).status,
});

export default connect(mapStateToProps)(Game);
