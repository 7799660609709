import {useEffect} from 'react';
import { noop } from '../helpers/noop';

const useOutsideClick = (
  ref,
  handler = noop,
  openerElementId
) => {

  useEffect(() => {
    const openerElement = document.getElementById(openerElementId);
    const listener = event => {
        if (!ref.current
            || ref.current.contains(event.target)
            || openerElement?.contains(event.target)) {
            return;
        }
        handler(event);
    };
    document.addEventListener('click', listener);
    return () => {
        document.removeEventListener('click', listener);
    };
  }, [ref, handler]);
};

export default useOutsideClick
