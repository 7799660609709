import React from 'react';
import TeamsInfoRow from './teamsInfoRow';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation';

const TeamsInfoTable = ({matches}) => {
  const translate = useTranslation();
  return (
    <div className="bottom-bottom-inside-game-list">
      <div className="bottom-bottom-inside-game-list-container">
        <div className="scroll-wr">
          {matches ?matches.map((match, index) => <TeamsInfoRow match={match} key={index}/>) :
            <div style={{display: 'flex',  justifyContent:'center', height: 'inherit', paddingTop: 32}}>
              <p title={translate(t.NO_MATCHES_FOUND)}>{translate(t.NO_MATCHES_FOUND)}</p>
            </div>}
        </div>
      </div>
    </div>
  )
};

export default TeamsInfoTable;
