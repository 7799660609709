import PredictorApiHandler from '../pedictorWebApiHandler.js';

const END_POINT = {
  prefix : 'socialMediaService',
  sharedToken: 'sharedToken',
};

class SocialMediaApiHandler extends PredictorApiHandler{
  constructor(prefix){
    super(prefix);
  }

  getSharedToken(payload) {
    return this.post(END_POINT.sharedToken, payload, '', null, true);
  }

}


const socialMediaApiHandler = new SocialMediaApiHandler(END_POINT.prefix);
export default socialMediaApiHandler;
