import PartnerModule from '../moduls/PartnerModul';
import {APP_CONSTANTS} from '../constants/constants';
import currencies from '../constants/currencies';
import store from '../../state-management/store';

export function getCurrencySign() {

  const urlCurrency = PartnerModule.getConfigItem(APP_CONSTANTS.CURRENCY);

  if (urlCurrency) {
    return currencies[urlCurrency.toUpperCase()]?.sign || urlCurrency;
  }

  const userCurrency = store.getState().userInfo.data?.currencyName;

  if (userCurrency) {
    return currencies[userCurrency.toUpperCase()]?.sign || userCurrency;
  }

  if(store.getState().partnerSettings.data?.currencyId){
    const currencyId = store.getState().partnerSettings.data?.currencyId;
    const currencyState = store.getState().currencies.data;
    const currency = currencyState.find(currency => currency.id === currencyId)?.name;
    return currency ? currencies[currency.toUpperCase()]?.sign || currency : '';
  }
}

