import StatisticOperations from '../../services/operations/statisticsOperations';
import statisticsApiHandler from '../../services/api/statisticsApiHandler';
import StatisticsController from '../../controllers/statisticsController';
import { STATISTICS_TYPES} from '../../core/constants/actionTypes';

//operations
const statisticOperations = new StatisticOperations(statisticsApiHandler);


//controller
const statisticsController = new StatisticsController(statisticOperations);

const statisticsConfig = {
  [STATISTICS_TYPES.GET_COMPETITIONS_TABLES.CNTRL] : statisticsController.getCompetitionsTables,
  [STATISTICS_TYPES.GET_MATCH_STAT.CNTRL]: statisticsController.getMatchStat,
  [STATISTICS_TYPES.CNTRL_GET_COMPETITION_TABLE]: statisticsController.getCompetitionTable,
};

export default statisticsConfig;
