import React, { useState, useEffect } from 'react';
import predictorLogo from '../../../images/predictor-logo.png'
import NicknameSection from './nicknameSection';
import { getUserInfo } from '../../../state-management/accessors/usersAccessors';
import { connect } from 'react-redux';
import {cntrlGetGlobalLobby, cntrlGetLobby} from '../../../state-management/actions/lobbyActions';
import { useHistory, useLocation } from 'react-router';
import PartnerModule from '../../../core/moduls/PartnerModul';
import { APP_CONSTANTS, ENVIRONMENT_TYPE } from '../../../core/constants/constants';
import { combineQuery } from '../../../core/helpers/combineQuery';
import { parseQuery } from '../../../core/helpers/parseQuery';
import useTranslation from '../../../core/customHooks/useTranslation';
import {
  getLobby,
  getRoundId,
} from '../../../state-management/accessors/lobbyAccessors';
import signIn from '../../../images/signIn.png';
import signOut from '../../../images/signOut.png';
import postMessageService from '../../../services/services/PostMessageService';
import { cntrlLogOut } from '../../../state-management/actions/authActions';
import {
  cntrlCloseLeaderBoardConnection,
  cntrlClosePredictionsConnection
} from '../../../state-management/actions/signalRActions';
import { MODAL_CONSTANTS } from '../../../core/constants/modalConstants';
import t from '../../../core/staticData/translation';
import notificationObserver from '../../../services/services/ErrorHandler';
import { getPartnerSettings } from '../../../state-management/accessors/partnerAccessors';
import {
  cntrlChangeChristmasMood,
} from '../../../state-management/actions/usersActions';
import { TOOLTIP_TARGET_IDS } from '../../../core/constants/userTooltipGuide';
import Toaster from '../notification';

const Header = ({ setModalState,
                  user,
                  getLobby,
                  roundId,
                  logOut,
                  closePredictionsConnection,
                  closeLeaderBoardConnection,
                  roundIsGlobal,
                  getGlobalLobby,
                  settings,
                  showSnowfallForUnauthorizedUser,
                  setShowSnowfallForUnauthorizedUser,
                  showSnowfallForAuthorizedUser,
                  setShowSnowFallForAuthorizedUser,
                  changeChristmasMood
}) => {
  const translate = useTranslation();
  const [fullScreen, setFullScreen ] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const partnerHasChristmasMood = settings.hasChristmasMood;

  const activeSnowButton = user.id ? showSnowfallForAuthorizedUser : showSnowfallForUnauthorizedUser;

  const showBackButton =
    process.env.REACT_APP_ENV === ENVIRONMENT_TYPE.PRODUCTION &&
    !postMessageService.isInIframe();

  const goToHomePage = () => {
    const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);
    getLobby({partnerId});
    history.push(combineQuery({...parseQuery(location.search), [APP_CONSTANTS.ROUNDID]: null, [APP_CONSTANTS.USERID]: null, [APP_CONSTANTS.GLOBAL_ROUND]: null}, ));
  };

  const fullScreenChange = () => {
    if (document.fullscreenEnabled && !document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setFullScreen(true);

    } else if( document.fullscreenEnabled && document.fullscreenElement){
      document.exitFullscreen();
      setFullScreen(false)
    }
  };

  const logOutOnClick = () => {
    const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);
    logOut();
    closePredictionsConnection();
    closeLeaderBoardConnection();
    if(roundIsGlobal) {
      getGlobalLobby({ partnerId, skip: 0, take: 30 });
      history.push(combineQuery({
        ...parseQuery(location.search),
        [APP_CONSTANTS.USERID]: null}));
    } else {
      getLobby({partnerId, roundId});
      history.push(combineQuery({
        ...parseQuery(location.search),
        [APP_CONSTANTS.ROUNDID]: roundId,
        [APP_CONSTANTS.USERID]: null}));
    }
  };

  const handleSnowIconClick = () => {
    if(user.id) {
      changeChristmasMood({ chistmasMoodEnabled: !user.chistmasMoodEnabled})
        .then (res => {
          !res && setShowSnowFallForAuthorizedUser(prev => !prev);
        })
    } else {
      setShowSnowfallForUnauthorizedUser(prev => !prev);
    }

  };


  return (
    <div className="header" data-tooltip={TOOLTIP_TARGET_IDS.HEADER}>
      <div className="inside-header">
        { showBackButton &&
        <div className="header-back-button" onClick={() => postMessageService.openHome()}>
            <span className="bc-i-small-arrow-left" />
        </div> }
        <div className="right-head-b">
          <div className="inside-right-head-b" style={{'justifyContent': (process.env.REACT_APP_ENV === ENVIRONMENT_TYPE.PRODUCTION) || (process.env.REACT_APP_ENV !== ENVIRONMENT_TYPE.PRODUCTION && !user.id)? 'flex-end' : 'space-between'}}>
            {user.id && <NicknameSection setModalState={()=>setModalState(MODAL_CONSTANTS.USER_PROFILE)} user={user} />}
            <div className="head-links-b">

              {
                partnerHasChristmasMood &&
                <div className={`head-links-item ${activeSnowButton ? 'active': ''}`} onClick={handleSnowIconClick} title={translate(t.SNOW)}>
                  <span className="bc-i-predictor-snow-icon-01" />
                </div>
              }

              {
                process.env.REACT_APP_ENV !== ENVIRONMENT_TYPE.PRODUCTION && !postMessageService.isInIframe() ? user.id ?
                <div className="head-links-item"
                     onClick={logOutOnClick}
                     title={translate(t.LOG_OUT)}>
                  <img src={signOut} alt="" style={{position: 'absolute', width: 15, height: 15, filter: ' brightness(0) invert(1)'}}/>
                </div> :

                <div className="head-links-item" onClick={()=>setModalState(MODAL_CONSTANTS.SIGN_IN_POPUP)} title={translate(t.SIGN_IN)}>
                  <img src={signIn} alt="" style={{position: 'absolute', width: 24, height: 24, filter: ' brightness(0) invert(1)'}}/>
                </div>: null}

              <div className="head-links-item" onClick={()=>setModalState(MODAL_CONSTANTS.INFORMATION)} title={translate(t.RULES)}>
                <span className="bc-i-info"/>
              </div>
              <div className="head-links-item full-sc-link" title={translate(t.FULL_SCREEN)} onClick={fullScreenChange}>
                {fullScreen ? <span className="bc-i-icon-copy"/> :
                  <span className="bc-i-full-screen"/>   }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Notification start*/}
      <div className="notification-holder">
        <div className="notification-list-w">
          <Toaster />
        </div>
      </div>
      {/*Notification END*/}
    </div>
  );
};

const mapStateToProps = state => ({
  user: getUserInfo(state),
  roundId: getRoundId(state),
  roundIsGlobal: !!getLobby(state).data.globalRound,
  settings: getPartnerSettings(state),
});

const mapDispatchToProps = dispatch => ({
  getLobby: payload => dispatch(cntrlGetLobby(payload)),
  logOut: () =>dispatch(cntrlLogOut()),
  closePredictionsConnection: () => dispatch(cntrlClosePredictionsConnection()),
  closeLeaderBoardConnection: () => dispatch(cntrlCloseLeaderBoardConnection()),
  getGlobalLobby: payload => dispatch(cntrlGetGlobalLobby(payload)),
  changeChristmasMood: payload => dispatch(cntrlChangeChristmasMood(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

