import statisticsConfig from './statisticsConfig';
import predictionsConfig from './predictions';
import partnerConfig from './partnerConfig';
import lobbyConfig from './lobbyConfig';
import userConfig from './usersConfig';
import appConfig from './appConfig';
import signalRConfig from './signalRConfig';
import translationConfig from './translationConfig';
import prizeInfoConfig from './prizeInfoConfig';
import infoConfig from './infoConfig';
import currencyConfig from './currenciesConfig';
import socialMediaConfig from './socialMediaConfig';
import roundConfig from './roundConfig';

const configs = {
  ...statisticsConfig,
  ...predictionsConfig,
  ...partnerConfig,
  ...lobbyConfig,
  ...userConfig,
  ...appConfig,
  ...signalRConfig,
  ...translationConfig,
  ...prizeInfoConfig,
  ...infoConfig,
  ...currencyConfig,
  ...socialMediaConfig,
  ...roundConfig,
};

export default configs;
