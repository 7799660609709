import React from 'react';

const TeamIconsBlock = ({ children }) => {
  return (
    <div className="teams-choose-block-bottom">
      {children}
    </div>
  );
};

export default TeamIconsBlock;
