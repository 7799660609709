import Controller from '../../core/classes/AppController';


class SocialMediaController extends Controller {
  constructor(socialMediaOperations) {
    super();
    this.socialMediaOperations = socialMediaOperations;
  }

  getSharedToken = async (store, action) => {
    return  await this.socialMediaOperations.getSharedToken(store, action);
  }


}

export default SocialMediaController;
