import UsersController from '../../controllers/users';
import UsersOperations from '../../services/operations/usersOperations';
import usersApiHandler from '../../services/api/usersApiHandler';
import { USERS_TYPES } from '../../core/constants/actionTypes';

/** operation*/
const usersOperations = new UsersOperations(usersApiHandler);

/** controller*/
const userController = new UsersController(usersOperations);

const userConfig = {
  [USERS_TYPES.GET_USER_INFO.CNTRL]: userController.getUserInfo,
  [USERS_TYPES.POST_USER_INFO.CNTRL]: userController.postUserInfo,
  [USERS_TYPES.CNTRL_CHECK_USERNAME]: userController.checkUsername,
  [USERS_TYPES.CNTRL_GENERATE_NICKNAME]: userController.generateNickname,
  [USERS_TYPES.CNTRL_CHANGE_CHRISTMAS_MOOD]: userController.changeChristmasMood,
};

export default userConfig;
