import React from 'react';
import Controller from '../../core/classes/AppController';
import { rxGetDistributionDone, rxGetDistributionPending } from '../../state-management/actions/roundActions';
import t from '../../core/staticData/translation';
import { rxGetLobbyDone } from '../../state-management/actions/lobbyActions';
import Toaster from '../../view/componentsNew/notification';
import { NOTIFICATION_TYPES } from '../../core/constants/notificationTypes';
import { BET_ACTIONS } from '../../core/constants/betActions';
import ErrorHandler from '../../core/helpers/ErrorHandler';
import BetRestrictionMessage from '../../view/componentsNew/betRestrictionMessage';
import { getCurrencySign } from '../../core/helpers/getCurrency';
import { getMatchDate } from '../../core/helpers/dateHelpers';

class RoundController extends Controller {
  constructor(roundOperations) {
    super();
    this.roundOperations = roundOperations;
  }

  getDistribution = async (store, action) => {
    store.dispatch(rxGetDistributionPending());
    const response = await this.roundOperations.getDistribution(store, action);
    store.dispatch(rxGetDistributionDone(response));
  };

  bet = async (store, action) => {
    const { predictions, betAction } = action.payload;
    const { result: res, responseStatus } = await this.roundOperations.bet(predictions);
    let notification;
    const hasError = res?.errorMessage && res?.result;

    if (responseStatus === ErrorHandler.errorCodesEnum.notAcceptable && !hasError) {
      notification = {
        type: NOTIFICATION_TYPES.ERROR,
        message: t.SOMETHING_WENT_WRONG
      };
    }

    if (hasError) {
      const { depositAmount, depositCount, lastDepositDateFrom, lastDepositDateTo } = res.result;
      switch (res.errorCode) {
        case ErrorHandler.errorCodes.lowDepositAmount:
          notification = {
            type: NOTIFICATION_TYPES.ERROR,
            custom: (
              <BetRestrictionMessage
                text={t.DEPOSIT_AMOUNT_RESTRICTION}
                values={{amount: `${depositAmount} ${getCurrencySign()}`}}
              />
            )
          };
          break;
        case ErrorHandler.errorCodes.lowDepositCount:
          notification = {
            type: NOTIFICATION_TYPES.ERROR,
            custom: (
              <BetRestrictionMessage
                text={t.DEPOSIT_COUNT_RESTRICTION}
                values={{count: depositCount}}
              />
            )
          };
          break;
        case ErrorHandler.errorCodes.lowDepositLastDate:
          notification = {
            type: NOTIFICATION_TYPES.ERROR,
            custom: (
              <BetRestrictionMessage
                text={t.DEPOSIT_DATE_RANGE_RESTRICTION}
                values={{dateRange: `${getMatchDate(lastDepositDateFrom)} - ${getMatchDate(lastDepositDateTo)}`}}
              />
            )
          };
          break;
        default:
          break;
      }
    } else if (responseStatus !== ErrorHandler.errorCodesEnum.notAcceptable) {
      switch (betAction) {
        case BET_ACTIONS.FREE:
          notification = {
            type: NOTIFICATION_TYPES.SUCCESS,
            message: t.YOU_HAVE_SUCCESSFULLY_PARTICIPATED
          };
          break;
        case BET_ACTIONS.SAVE:
          notification = {
            type: NOTIFICATION_TYPES.SUCCESS,
            message: t.YOUR_PREDICTIONS_SUCCESSFULLY_SAVED
          };
          break;
        case BET_ACTIONS.BET:
          notification = [
            {
              type: NOTIFICATION_TYPES.SUCCESS,
              message: t.BET_ACCEPTED
            },
            {
              type: NOTIFICATION_TYPES.SUCCESS,
              message: t.YOU_HAVE_SUCCESSFULLY_PARTICIPATED
            }
          ];
          break;
        default:
          break;
      }
    }

    notification && Toaster.alert(notification);
    if (hasError) {
      return true;
    }
    const round = JSON.parse(JSON.stringify(store.getState().lobby.data.round));
    round.isParticipant = true;
    store.dispatch(rxGetLobbyDone({...store.getState().lobby.data, round}));
  }
}

export default RoundController;
