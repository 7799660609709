import { ENVIRONMENT_TYPE } from "./constants";

const GA_TRACKING_ID_DEV = 'UA-121086055-4';
const GA_TRACKING_ID_PROD = 'UA-121086055-3';

export const GA_TRACKING_ID = process.env.REACT_APP_ENV === ENVIRONMENT_TYPE.PRODUCTION ? GA_TRACKING_ID_PROD : GA_TRACKING_ID_DEV;

export const GA_CATEGORIES = {
    USAGE: 'Usage',
    PREDICTION: 'Prediction'
};

export const GA_ACTIONS = {
    SMART_PICK: 'Smart Pick',
    POINT_DETAILS: 'Point Details',
    VERIFY_ACCOUNT: 'Verify Account',
    TABLE_STATISTICS: 'Table Statistics',
    SCORE_PREDICTION: 'Score Prediction',
    LEADERBOARD_OTHER_USER: 'Leaderboard Other User',
    FTTS_PREDICTION: 'First Team to Score Prediction',
    LAST_MATCHES_STATISTICS: 'Last Matches Statistics'
};
