import React, { useContext, useRef, useState } from 'react';
import { createPortal } from 'react-dom'
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation';
import BetButton from './betButton';
import { TOOLTIP_TARGET_IDS, TOOLTIP_TYPES } from '../../../core/constants/userTooltipGuide';
import { FieldStateContext, NavigationContext } from '../../../contexts';
import GuidePopup from '../userTooltipGuide/guidePopup';
import { useSelector, useDispatch } from 'react-redux';
import { getMatches, getRoundId } from '../../../state-management/accessors/lobbyAccessors';
import { checkPredictionsValidation } from '../../../core/helpers/checkPredictionsValidation';
import { TOOLTIP_HINTS, TOOLTIP_HINT_TYPES } from '../../../core/constants/tooltipHints';
import { getParentNodes } from '../../../core/helpers/getParentNodes';
import { scrollIntoView } from '../../../core/helpers/scrollIntoView';
import { isMobile } from '../../../core/helpers/isMobile';
import TypeChecker from '../../../core/helpers/TypeCheker';
import { SelectionSubType, SelectionTypes } from '../../../core/constants/enums';
import { getIsAuth } from '../../../state-management/accessors/appAccessors';
import postMessageService from '../../../services/services/PostMessageService';
import { cntrlBet } from '../../../state-management/actions/roundActions';
import { MOBILE_TABS } from '../../../core/constants/navigationConstants';
import LocalStorageService from '../../../services/services/LocalStorageService';
import useDidUpdateEffect from '../../../core/customHooks/useDidUpdateEffect';
import macrotask from '../../../core/helpers/macrotask';
import { getPartnerSettings } from '../../../state-management/accessors/partnerAccessors';

const hasScoreSelection = prediction => {
  const hasHomeScorePrediction = prediction.hasOwnProperty('homeTeamScore') && !TypeChecker.isEmptyString(prediction.homeTeamScore);
  const hasAwayScorePrediction = prediction.hasOwnProperty('awayTeamScore') && !TypeChecker.isEmptyString(prediction.awayTeamScore);
  return hasHomeScorePrediction || hasAwayScorePrediction;
};

const hasFttsSelection = prediction => {
  return prediction.hasOwnProperty('ftts');
};

const getBetModel = (roundId, predictionResults, matches) => {

  const predictions = [];

  matches.forEach(match => {
    const { roundMatchId } = match;

    const matchPredictionResults = predictionResults[roundMatchId];

    const predictionSelections = [];

    if (matchPredictionResults) {
      if (hasScoreSelection(matchPredictionResults)) {
        predictionSelections.push({
          selectionType: SelectionTypes.CorrectScore,
          selectionSubValues: [
            {
              selectionSubType: SelectionSubType.HomeScore,
              value: matchPredictionResults.homeTeamScore
            },
            {
              selectionSubType: SelectionSubType.AwayScore,
              value: matchPredictionResults.awayTeamScore
            },
          ]
        })
      }
      if(hasFttsSelection(matchPredictionResults)) {
        predictionSelections.push({
          selectionType: SelectionTypes.FirstTeamToScore,
          selectionSubValues: [
            {
              selectionSubType: SelectionSubType.None,
              value: matchPredictionResults.ftts,
            },
          ]
        })
      }
    }

    if (!predictionSelections.length) {
      predictionSelections.push({
        selectionType: SelectionTypes.CorrectScore,
        selectionSubValues: [
          {
            selectionSubType: SelectionSubType.HomeScore,
            value: null
          },
          {
            selectionSubType: SelectionSubType.AwayScore,
            value: null
          },
        ]
      })
    }

    const prediction = {
      roundMatchId,
      isDoubled: roundMatchId === predictionResults.isDoubled,
      predictionSelections
    };

    predictions.push(prediction)
  });

  return {
    roundId,
    predictions
  };

};

const Buttons = ({
  setSmartPick,
  predictionsForBet,
  disabledPredictionsClick,
  handleClearAllButtonClick,
  handleSmartPickButtonClick,
  setTooltipValidationData,
  isActionsDisabled
}) => {

  const matches = useSelector(getMatches);
  const roundId = useSelector(getRoundId);
  const isAuth = useSelector(getIsAuth);
  const { hasX2Mode } = useSelector(getPartnerSettings);

  const dispatch = useDispatch();
  const { activeTab } = useContext(NavigationContext);
  const { isEditing, setIsEditing } = useContext(FieldStateContext);

  const translate = useTranslation();
  const { fieldIsDisabled } = useContext(FieldStateContext);
  const [ hint, setHint ] = useState(null);
  const [ target, setTarget ] = useState(null);
  const scrollWrapperRef = useRef(null);
  const betButtonContainer = document.getElementById('bet-button-container');

  const [ showSuccessAnimation, setShowSuccessAnimation ] = useState(false);

  useDidUpdateEffect(() => {
    setTooltipData(TOOLTIP_HINTS[TOOLTIP_HINT_TYPES.EDIT_BUTTON]);
  }, [ disabledPredictionsClick ]);

  useDidUpdateEffect(() => {
    if (showSuccessAnimation) {
      setTimeout(() => {
        setShowSuccessAnimation(false);
      }, 1000)

    }
  }, [showSuccessAnimation]);

  const checkValidation = predictions => {
    const { isValid, index, reason: validationType } = checkPredictionsValidation(predictions.predictions, hasX2Mode, matches);
    if (isValid) return true;
    if (validationType === TOOLTIP_HINT_TYPES.FIRST_TEAM_TO_SCORE_PREDICTION) {
      setTooltipValidationData({
        index,
        validationType
      })
    }

    setSmartPick(prev => !prev);
    const hint = TOOLTIP_HINTS[validationType];
    setTooltipData(hint, index);
    return false;
  };

  const setTooltipData = (hint, index = 0) => {
    const { mobile, web, scrollWr, calcScrollFrom } = hint;
    const elemId = isMobile() ? mobile.targetElemId : web.targetElemId;
    const target = document.querySelectorAll(`[data-tooltip=${elemId}]`)[index];
    scrollWrapperRef.current = document.querySelector(`[data-scroll-wr=${scrollWr}]`);
    const scrollCalcElem = getParentNodes(target).find(elem => {
      return elem.getAttribute && elem.getAttribute('data-scroll-child') === calcScrollFrom;
    });
    if (!target) return false;
    setHint(hint);
    setTarget(target);
    macrotask(() => {
      scrollIntoView(scrollWrapperRef.current, scrollCalcElem || target);
    });
  };

  const handleClose = () => {
    setHint(null);
    setTarget(null);
  };

  const handleBetButtonClick = betAction => {
    const predictions = getBetModel(roundId, predictionsForBet, matches);
    if (!checkValidation(predictions)) return;

    if (!isAuth) {
      postMessageService.openLoginPopup();
      return;
    }
    dispatch(cntrlBet({predictions, betAction}))
      .then(res => {
        if (!res) {
          setShowSuccessAnimation(true);
          LocalStorageService.remove(roundId);
          setSmartPick(prev => !prev);
        }
        if (!res && isEditing) {
          setIsEditing(false);
        }});
  };

  const openerElemId = isMobile() ? TOOLTIP_TARGET_IDS.BET_OR_FREE_BUTTON_MOBILE : TOOLTIP_TARGET_IDS.BET_OR_FREE_BUTTON;

  const getOpenerButtonId = () => hint.type === TOOLTIP_TYPES.EDIT_BUTTON ? null : openerElemId;

  const handlePopupOutsideClick = event => {
    const openerElem = document.getElementById(openerElemId);
    if (hint.type === TOOLTIP_TYPES.EDIT_BUTTON && !openerElem?.contains(event.target)) return;
    handleClose();
  };

  return (
    <>
      {
        (!hint || !target) ? null :
        createPortal(
          <GuidePopup
            target={target}
            currentStep={hint}
            onClose={handleClose}
            openerButtonId={getOpenerButtonId()}
            onOutsideClick={handlePopupOutsideClick}
          />, target)
      }
      <div className="bottom-of-center-block">
        <div className="buttons-wr">
          <div className="button-block" onClick={handleSmartPickButtonClick} data-tooltip={TOOLTIP_TARGET_IDS.SMART_PICK}>
            <button className={`game-list-btn ${fieldIsDisabled ? 'inactive': ''}`} type="button" title={translate(t.SMART_PICK)}>
              <span className="bc-i-predictor-icon"/>
            </button>
          </div>
          <BetButton checkValidation={checkValidation}
                     handleBetButtonClick={handleBetButtonClick}
                     showSuccessAnimation={showSuccessAnimation}
                     tooltipId={TOOLTIP_TARGET_IDS.BET_OR_FREE_BUTTON}
                     setSmartPick={setSmartPick}
                     disabled={isActionsDisabled}
          />
          {
            !!betButtonContainer &&
            createPortal(<BetButton checkValidation={checkValidation}
                                    handleBetButtonClick={handleBetButtonClick}
                                    showSuccessAnimation={showSuccessAnimation}
                                    tooltipId={TOOLTIP_TARGET_IDS.BET_OR_FREE_BUTTON_MOBILE}
                                    setSmartPick={setSmartPick}
                                    disabled={isActionsDisabled}
                                    hide={activeTab !== MOBILE_TABS.HOME}
            />, betButtonContainer)
          }
          <div className="button-block" onClick={handleClearAllButtonClick} data-tooltip={TOOLTIP_TARGET_IDS.CLEAR_ALL}>
            <button className={`game-list-btn ${fieldIsDisabled ? 'inactive': ''}`} type="button" title={translate(t.CLEAR_ALL)}>
              <span className="bc-i-clear-icon"/>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};


export default Buttons;
