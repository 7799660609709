 import React from 'react';
import { TOOLTIP_TARGET_IDS } from '../../../core/constants/userTooltipGuide';
import { noop } from '../../../core/helpers/noop';

const ScoreInputs = ({ children, showPredictionTooltips, onClick = noop }) => (
  <div className="score-table-item">
    <div className="score-select-block" data-tooltip={showPredictionTooltips ? TOOLTIP_TARGET_IDS.SCORE_PREDICTION : null}>
      <div
        className="inside-score-select-block"
        onClick={onClick}
      >
        {children}
      </div>
    </div>
  </div>
);

export default ScoreInputs;
