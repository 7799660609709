import { parseQuery } from '../helpers/parseQuery';
import t from '../staticData/translation.json';
import { RIGHT_ALIGNED_LANGUAGES } from './languageConstants';
import { MOBILE_TABS, NAVIGATION_ITEMS } from './navigationConstants';

export const TOOLTIP_TYPES = {
    WELCOME: 1,
    LAST_MATCHES_STATISTICS: 2,
    SCORE_PREDICTION: 3,
    FIRST_TEAM_TO_SCORE_PREDICTION: 4,
    ROUNDS: 5,
    EARNED_POINTS: 6,
    TOTAL_EARNED_POINTS: 7,
    TABLE_STATISTIC: 8,
    SMART_PICK: 9,
    CLEAR_ALL: 10,
    X2_PREDICTION: 11,
    BET_OR_FREE: 12,
    EDIT_BUTTON: 13
};

export const TOOLTIP_TARGET_IDS = {
    HEADER: 'header',
    LOGO_TEXT: 'logoText',
    LAST_MATCHES_STATISTICS: 'lastMatchesStatistics',
    SCORE_PREDICTION: 'scorePrediction',
    FIRST_TEAM_TO_SCORE_PREDICTION: 'firstTeamToScorePrediction',
    FINISHED_ROUND: 'finishedRound',
    EARNED_POINTS: 'earnedPoints',
    TOTAL_EARNED_POINTS: 'totalEarnedPoints',
    TABLE_STATISTIC_WEB: 'tableStatisticWeb',
    TABLE_STATISTIC_MOBILE: 'tableStatisticMobile',
    SMART_PICK: 'smartPick',
    CLEAR_ALL: 'clearAll',
    X2_PREDICTION: 'x2Prediction',
    BET_OR_FREE_BUTTON: 'betOrFree',
    BET_OR_FREE_BUTTON_MOBILE: 'betOrFreeMobile',
};

export const SCROLLABLE_WRAPPERS = {
    HOME: 'homeScrollWr',
    ROUNDS: 'roundsScrollWr',
    LEADERBOARD: 'leaderboardScrollWr'
};

export const SCROLLABLE_CHILDREN = {
    GAME_ITEM: 'gameItem'
};

export const TOOLTIP_ARROW_POSITIONS = {
    // top
    TOP_LEFT: 'top-left',
    TOP_CENTER: 'top-center',
    TOP_RIGHT: 'top-right',

    // right
    RIGHT_TOP: 'right-top',
    RIGHT_BOTTOM: 'right-bottom',

    // bottom
    BOTTOM_LEFT: 'bottom-left',
    BOTTOM_CENTER: 'bottom-center',
    BOTTOM_RIGHT: 'bottom-right',
    BOTTOM_RIGHT_CENTER: 'bottom-right-center', // This is a custom case for table tooltip on mobile devices.
    BOTTOM_LEFT_CENTER: 'bottom-left-center', // This is a custom case for table tooltip on mobile devices. (for right aligned languages)

    // left
    LEFT_TOP: 'left-top',
    LEFT_BOTTOM: 'left-bottom'
};

const queryObject = parseQuery(window.location.href);
const lan = queryObject.lan || queryObject.langauge;
const isRightAligned = RIGHT_ALIGNED_LANGUAGES.includes(lan);

export const MAIN_TOOLTIP_GUIDE_STEPS = [
    {
        type: TOOLTIP_TYPES.WELCOME,
        description: t.TOOLTIP_WELCOME_DESC,
        title: t.WELCOME,
        web: {
            targetElemId: TOOLTIP_TARGET_IDS.LOGO_TEXT,
            arrow: isRightAligned ? TOOLTIP_ARROW_POSITIONS.RIGHT_TOP : TOOLTIP_ARROW_POSITIONS.LEFT_TOP
        },
        mobile: {
            targetElemId: TOOLTIP_TARGET_IDS.HEADER,
            arrow: TOOLTIP_ARROW_POSITIONS.TOP_CENTER,
            navigationItem: NAVIGATION_ITEMS.HOME,
            activeTab: MOBILE_TABS.HOME
        }
    },
    {
        type: TOOLTIP_TYPES.LAST_MATCHES_STATISTICS,
        description: t.TOOLTIP_LAST_MATCHES_STATISTICS_DESC,
        title: t.LAST_MATCHES_STATISTICS,
        scrollWr: SCROLLABLE_WRAPPERS.HOME,
        calcScrollFrom: SCROLLABLE_CHILDREN.GAME_ITEM,
        web: {
            targetElemId: TOOLTIP_TARGET_IDS.LAST_MATCHES_STATISTICS,
            arrow: TOOLTIP_ARROW_POSITIONS.TOP_LEFT
        },
        mobile: {
            targetElemId: TOOLTIP_TARGET_IDS.LAST_MATCHES_STATISTICS,
            arrow: TOOLTIP_ARROW_POSITIONS.TOP_LEFT,
            navigationItem: NAVIGATION_ITEMS.HOME,
            activeTab: MOBILE_TABS.HOME
        }
    },
    {
        type: TOOLTIP_TYPES.SCORE_PREDICTION,
        description: t.TOOLTIP_SCORE_PREDICTION_DESC,
        title: t.SCORE_PREDICTION,
        scrollWr: SCROLLABLE_WRAPPERS.HOME,
        calcScrollFrom: SCROLLABLE_CHILDREN.GAME_ITEM,
        web: {
            targetElemId: TOOLTIP_TARGET_IDS.SCORE_PREDICTION,
            arrow: TOOLTIP_ARROW_POSITIONS.TOP_CENTER
        },
        mobile: {
            targetElemId: TOOLTIP_TARGET_IDS.SCORE_PREDICTION,
            arrow: TOOLTIP_ARROW_POSITIONS.TOP_CENTER,
            navigationItem: NAVIGATION_ITEMS.HOME,
            activeTab: MOBILE_TABS.HOME
        }
    },
    {
        type: TOOLTIP_TYPES.FIRST_TEAM_TO_SCORE_PREDICTION,
        description: t.TOOLTIP_FIRST_TEAM_TO_SCORE_PREDICTION_DESC,
        title: t.FIRST_TEAM_TO_SCORE_PREDICTION,
        scrollWr: SCROLLABLE_WRAPPERS.HOME,
        calcScrollFrom: SCROLLABLE_CHILDREN.GAME_ITEM,
        web: {
            targetElemId: TOOLTIP_TARGET_IDS.FIRST_TEAM_TO_SCORE_PREDICTION,
            arrow: TOOLTIP_ARROW_POSITIONS.BOTTOM_RIGHT
        },
        mobile: {
            targetElemId: TOOLTIP_TARGET_IDS.FIRST_TEAM_TO_SCORE_PREDICTION,
            arrow: TOOLTIP_ARROW_POSITIONS.BOTTOM_RIGHT,
            navigationItem: NAVIGATION_ITEMS.HOME,
            activeTab: MOBILE_TABS.HOME
        }
    },
    {
        type: TOOLTIP_TYPES.ROUNDS,
        description: t.TOOLTIP_ROUNDS_DESC,
        title: t.ROUNDS,
        scrollWr: SCROLLABLE_WRAPPERS.ROUNDS,
        web: {
            targetElemId: TOOLTIP_TARGET_IDS.FINISHED_ROUND,
            arrow: TOOLTIP_ARROW_POSITIONS.TOP_CENTER,
            navigationItem: NAVIGATION_ITEMS.ROUNDS,
            activeTab: MOBILE_TABS.ROUNDS,
            changeRoundTab: true
        },
        mobile: {
            targetElemId: TOOLTIP_TARGET_IDS.FINISHED_ROUND,
            arrow: TOOLTIP_ARROW_POSITIONS.TOP_CENTER,
            navigationItem: NAVIGATION_ITEMS.ROUNDS,
            activeTab: MOBILE_TABS.ROUNDS,
            changeRoundTab: true
        }
    },
    {
        type: TOOLTIP_TYPES.TOTAL_EARNED_POINTS,
        description: t.TOOLTIP_TOTAL_EARNED_POINTS_DESC,
        title: t.TOTAL_EARNED_POINTS,
        scrollWr: SCROLLABLE_WRAPPERS.LEADERBOARD,
        web: {
            targetElemId: TOOLTIP_TARGET_IDS.TOTAL_EARNED_POINTS,
            arrow: TOOLTIP_ARROW_POSITIONS.TOP_CENTER,
            navigationItem: NAVIGATION_ITEMS.LEADERBOARD,
            activeTab: MOBILE_TABS.LEADERBOARD,
            changeLeaderBoardTab: true
        },
        mobile: {
            targetElemId: TOOLTIP_TARGET_IDS.TOTAL_EARNED_POINTS,
            arrow: TOOLTIP_ARROW_POSITIONS.TOP_CENTER,
            navigationItem: NAVIGATION_ITEMS.LEADERBOARD,
            activeTab: MOBILE_TABS.LEADERBOARD,
            changeLeaderBoardTab: true
        }
    },
    {
        type: TOOLTIP_TYPES.TABLE_STATISTIC,
        description: t.TOOLTIP_TABLE_STATISTIC_DESC,
        title: t.TABLE_STATISTIC,
        web: {
            targetElemId: TOOLTIP_TARGET_IDS.TABLE_STATISTIC_WEB,
            arrow: isRightAligned ? TOOLTIP_ARROW_POSITIONS.TOP_RIGHT : TOOLTIP_ARROW_POSITIONS.TOP_LEFT,
            changeTableTab: true
        },
        mobile: {
            targetElemId: TOOLTIP_TARGET_IDS.TABLE_STATISTIC_MOBILE,
            arrow: isRightAligned ? TOOLTIP_ARROW_POSITIONS.BOTTOM_LEFT_CENTER : TOOLTIP_ARROW_POSITIONS.BOTTOM_RIGHT_CENTER,
            navigationItem: NAVIGATION_ITEMS.TABLES,
            activeTab: MOBILE_TABS.TABLES,
            changeTableTab: true
        }
    }
];

export const SMART_PICK_AND_CLEAR_ALL = [
    {
        type: TOOLTIP_TYPES.SMART_PICK,
        description: t.TOOLTIP_SMART_PICK_DESC,
        title: t.SMART_PICK,
        web: {
            targetElemId: TOOLTIP_TARGET_IDS.SMART_PICK,
            arrow: isRightAligned ? TOOLTIP_ARROW_POSITIONS.BOTTOM_RIGHT : TOOLTIP_ARROW_POSITIONS.BOTTOM_LEFT
        },
        mobile: {
            targetElemId: TOOLTIP_TARGET_IDS.SMART_PICK,
            arrow: isRightAligned ? TOOLTIP_ARROW_POSITIONS.TOP_RIGHT : TOOLTIP_ARROW_POSITIONS.TOP_LEFT,
            navigationItem: NAVIGATION_ITEMS.HOME,
            activeTab: MOBILE_TABS.HOME
        }
    },
    {
        type: TOOLTIP_TYPES.CLEAR_ALL,
        description: t.TOOLTIP_CLEAR_ALL_DESC,
        title: t.CLEAR_ALL,
        web: {
            targetElemId: TOOLTIP_TARGET_IDS.CLEAR_ALL,
            arrow: isRightAligned ? TOOLTIP_ARROW_POSITIONS.BOTTOM_LEFT : TOOLTIP_ARROW_POSITIONS.BOTTOM_RIGHT
        },
        mobile: {
            targetElemId: TOOLTIP_TARGET_IDS.CLEAR_ALL,
            arrow: isRightAligned ? TOOLTIP_ARROW_POSITIONS.TOP_LEFT : TOOLTIP_ARROW_POSITIONS.TOP_RIGHT,
            navigationItem: NAVIGATION_ITEMS.HOME,
            activeTab: MOBILE_TABS.HOME
        }
    },
    {
        type: TOOLTIP_TYPES.BET_OR_FREE,
        description: t.PARTICIPATION_DESC,
        title: t.PARTICIPATION,
        web: {
            targetElemId: TOOLTIP_TARGET_IDS.BET_OR_FREE_BUTTON,
            arrow: TOOLTIP_ARROW_POSITIONS.BOTTOM_CENTER
        },
        mobile: {
            targetElemId: TOOLTIP_TARGET_IDS.BET_OR_FREE_BUTTON_MOBILE,
            arrow: TOOLTIP_ARROW_POSITIONS.BOTTOM_CENTER,
            navigationItem: NAVIGATION_ITEMS.HOME,
            activeTab: MOBILE_TABS.HOME
        }
    }
];
