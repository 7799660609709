import React, { useRef } from 'react';
import TeamChooseBlockItem from './teamChooseBlockItem';
import TeamChooseBlockButton from './teamChooseBlockButton';
import { connect } from 'react-redux';
import { cntrlAddFTTSPrediction } from '../../../state-management/actions/predictionActions';
import { getRoundId } from '../../../state-management/accessors/lobbyAccessors';
import { EFirstTeamToScore } from '../../../core/constants/enums';
import { getIsAuth } from '../../../state-management/accessors/appAccessors';
import postMessageService from '../../../services/services/PostMessageService';
import { getPartnerSettings } from '../../../state-management/accessors/partnerAccessors';
import useGoogleAnalytics from '../../../core/customHooks/useGoogleAnalytics';
import { GA_CATEGORIES, GA_ACTIONS } from '../../../core/constants/googleAnalyticsConstants';

const TeamChooseBlock = ({ homeTeam,
                           awayTeam,
                           addFtts,
                           usesLogoImages,
                         })=> {

  const { emitEvent } = useGoogleAnalytics();

  // const addFtts = async (firstTeamToScore) => {
  //       emitFTTSPredictionToGA();
  // };

  const emitFTTSPredictionToGA = () => {
    emitEvent({
      category: GA_CATEGORIES.PREDICTION,
      action: GA_ACTIONS.FTTS_PREDICTION
    });
  };

  const homeTeamUrl = usesLogoImages ? homeTeam.imageUrl : homeTeam.jerseyUrl;
  const awayTeamUrl = usesLogoImages ? awayTeam.imageUrl : awayTeam.jerseyUrl;

  return (
    <div className="teams-remove-block-bottom">
      <TeamChooseBlockItem imageUrl={homeTeamUrl}
                           addFtts={() => addFtts(1)}
                           title={homeTeam.shortName && homeTeam.shortName.length ? homeTeam.shortName : homeTeam.name}
      />
      <TeamChooseBlockButton addFtts={() => addFtts(0)}/>
      <TeamChooseBlockItem imageUrl={awayTeamUrl}
                           addFtts={() => addFtts(2)}
                           title={awayTeam.shortName && awayTeam.shortName.length ? awayTeam.shortName : awayTeam.name}
      />
    </div>
  )
};

const mapStateToProps = state => ({
  usesLogoImages: getPartnerSettings(state).usesLogoImages,
});

const mapDispatchToProps = dispatch => ({
});


export default connect(mapStateToProps, mapDispatchToProps)(TeamChooseBlock);
