import React from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';
import withPartnerSettings from '../../../hocs/withPartnerSettingsHoc';
import {PARTNER_SETTINGS_CONSTANTS} from '../../../core/constants/partnerSettingsConstants';
import useGoogleAnalytics from '../../../core/customHooks/useGoogleAnalytics';
import { GA_CATEGORIES, GA_ACTIONS } from '../../../core/constants/googleAnalyticsConstants';
import t from '../../../core/staticData/translation';
import { TOOLTIP_TARGET_IDS } from '../../../core/constants/userTooltipGuide';

const StatisticIcon = ({ setShowBackSpace, isDisabled }) => {

  const translate = useTranslation();
  const { emitEvent } = useGoogleAnalytics();

  const handleStatisticClick = () => {
    if (isDisabled) return;
    setShowBackSpace(true);
    emitStatiticsToGA();
  };

  const emitStatiticsToGA = () => {
    emitEvent({
      category: GA_CATEGORIES.USAGE,
      action: GA_ACTIONS.LAST_MATCHES_STATISTICS
    });
  };

  return (
    <div className="statistic-icon-wr"
          data-tooltip={TOOLTIP_TARGET_IDS.LAST_MATCHES_STATISTICS}
          onClick={handleStatisticClick}
          title={translate(t.STATISTIC)}>
      <span className={`bc-i-statistic statistic-icon ${isDisabled ? 'tooltip-disabled' : ''}`} />
    </div>
  )
};

export default withPartnerSettings(PARTNER_SETTINGS_CONSTANTS.HAS_MATCHES_STATISTIC)(StatisticIcon);
