import createAsyncReducer from '../../core/helpers/createAsyncReducer';
import { PARTNER_TYPES } from '../../core/constants/actionTypes';

export const currentGlobalRoundReducer = createAsyncReducer(PARTNER_TYPES.GET_CURRENT_GLOBAL_ROUND.PREFIX, {id: null});
export const partnerSettingsReducer = createAsyncReducer(PARTNER_TYPES.GET_PARTNER_SETTINGS.PREFIX, {});
export const hasPartnerColors = createAsyncReducer(PARTNER_TYPES.RX_GET_PARTNER_COLORS, false);
export const competitionsReducer = createAsyncReducer(PARTNER_TYPES.GET_COMPETITIONS.PREFIX, {
  competitions: [],
});

