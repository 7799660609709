import React, { useState, useEffect } from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';
import { getGuid } from '../../../core/helpers/guidGenerator';

const defaultDuration = 4000;

const Toaster = () => {
  const  translate = useTranslation();
  const [ notificationList, setNotificationList ] = useState([]);

  const removeItem = notificationData => {
    if (Array.isArray(notificationData)) {
      setNotificationList([]);
    }
    setNotificationList(prev => prev.filter((item) => item.id !== notificationData.id));
  };

  useEffect(() => {
    Toaster.alert = notificationData => {
      if (Array.isArray(notificationData)) {
        notificationData.forEach(item => {
          item.id = getGuid();
        });
        setNotificationList(notificationData)
      } else {
        notificationData = {
          ...notificationData,
          id: getGuid()
        };

        setNotificationList([notificationData]);
      }


      setTimeout(() => {
        removeItem(notificationData);
      }, notificationData.duration || defaultDuration, notificationList);
    };
  }, []);

  return (
    <>
      {
        notificationList.map(notification => {
          const {
            id,
            type,
            custom,
            message
          } = notification;
          return (
            <div className={`notify-item ${type}`} key={id}>
              <div className="notify-i-text-holder">
                <div className="notify-icon-box">
                  <i className={`bc-i-icon-${type}`}/>
                </div>
                {
                  custom ||
                  <p className="n-text-v">{translate(message)}</p>
                }
              </div>
              <div className="closed-n-holder" onClick={e => {
                e.nativeEvent.stopImmediatePropagation();
                removeItem(notification);
              }}>
                <i className="bc-i-close"/>
              </div>
            </div>
          )
        })
      }
    </>
  )
};

Toaster.alert = () => {};

export default Toaster;
