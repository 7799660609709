import PredictorApiHandler from '../pedictorWebApiHandler.js';

const END_POINT = {
  prefix : 'PrizeInfos',
  CurrentDistributions: 'CurrentDistributions',
};

class PrizeInfoApiHandler extends PredictorApiHandler{
  constructor(prefix){
    super(prefix);
  }

  getPrizeInfo(query) {
    return this.get('', query);
  }

  getCurrentDistributions(query) {
    return this.get(END_POINT.CurrentDistributions, query);
  }
}


const prizeInfoApiHandler = new PrizeInfoApiHandler(END_POINT.prefix);
export default prizeInfoApiHandler;
