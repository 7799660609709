import Controller from '../../core/classes/AppController';
import {
  rxGetPrizeInfoDone,
  rxGetPrizeInfoPending
} from '../../state-management/actions/prizeActions';
import {rxGetDistributionDone, rxGetDistributionPending} from '../../state-management/actions/roundActions';

class PrizeInfoController extends Controller {
  constructor(prizeInfoOperations) {
    super();
    this.prizeInfoOperations = prizeInfoOperations;
  }

  getPrizeInfo = async (store, action) => {
    store.dispatch(rxGetPrizeInfoPending());
    const response = await this.prizeInfoOperations.getPrizeInfo(store, action);
    store.dispatch(rxGetPrizeInfoDone(response))
  }

  getCurrentDistributions = async (store, action) => {
    store.dispatch(rxGetDistributionPending());
    const response = await this.prizeInfoOperations.getCurrentDistributions();
    store.dispatch(rxGetDistributionDone(response));
  }
}

export default PrizeInfoController;
