import React from 'react';
import GameInfoTitle from './gameInfoTitle';
import GameInfoItem from './gameInfoItem';

const TableItem = ({ table }) => {
  return (
      <div className="table-body">
        <GameInfoTitle name={table.name}/>
        {table.teams.map(team => <GameInfoItem key={team.teamId} team={team} />)}
      </div>
  );
};

export default TableItem;
