import Operations from '../../../core/classes/AppOperation';
import PartnerModule from '../../../core/moduls/PartnerModul';
import { APP_CONSTANTS } from '../../../core/constants/constants';
import ApiHandler from '../../../core/classes/ApiHandler';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';
import {cntrlAuthScheme, rxSetIsAuth} from '../../../state-management/actions/authActions';
import {rxGetUserInfoDone} from '../../../state-management/actions/usersActions';
import postMessageService from '../../services/PostMessageService';

class AuthorizationOperation extends Operations {
  static timeout = null;
  constructor(authorizationApiHandler) {
    super();
    this.authorizationApiHandler = authorizationApiHandler;
    this.expiresInInsurance = 30;
  }

  async auth(store, action) {
    try{
      const token = PartnerModule.getConfigItem(APP_CONSTANTS.TOKEN);
      if (!token) {
        await postMessageService.listen();
      }

      const response = await this.authorizationApiHandler.auth();

      responseErrorCheck(response);

      if(response && response.hasOwnProperty('result') && response.result.hasOwnProperty('accessToken')) {
        this.handleResponse(store, response.result);
      }
      return response.result.accessToken;

    } catch ( e ) {
      return new Error(e);
    }
  }

  async authWithUsername(store, action) {
    const response = await this.authorizationApiHandler.authWithUsername(action.payload);

    responseErrorCheck(response);
    if(response && response.hasOwnProperty('result') && response.result.hasOwnProperty('accessToken')) {
      this.handleResponse(store, response.result);
    }
    return response.result.accessToken;
  }

  handleResponse = (store, response) => {
    ApiHandler.token = response.accessToken;
    const timer = (response.expiresIn - this.expiresInInsurance) * 1000;
    AuthorizationOperation.timeout = setTimeout(() => this.authRefresh(store).catch(()=>{
      ApiHandler.token = '';
      store.dispatch(rxSetIsAuth(false));
      store.dispatch(rxGetUserInfoDone(null));
    }), timer);
  };

  async authRefresh(store) {
    const response = await this.authorizationApiHandler.authRefresh();
    responseErrorCheck(response);
    if(response && response.hasOwnProperty('result') && response.result.hasOwnProperty('accessToken')) {
      this.handleResponse(store, response.result);
    } else {
      ApiHandler.token = '';
      store.dispatch(rxSetIsAuth(false));
      store.dispatch(rxGetUserInfoDone(null));
      store.dispatch(cntrlAuthScheme(store));
    }
    return response.result;
  }

  logout() {
    ApiHandler.token = '';
    clearTimeout(AuthorizationOperation.timeout);
  }

}

export default AuthorizationOperation;
