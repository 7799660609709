import prizeInfoApiHandler from '../../services/api/prizeInfoApiHandler';
import PrizeInfoOperations from '../../services/operations/prizeInfoOperations';
import PrizeInfoController from '../../controllers/prizeInfo';
import { PRIZE_INFO_TYPES} from '../../core/constants/actionTypes';

const prizeInfoOperations = new PrizeInfoOperations(prizeInfoApiHandler);
const prizeInfoController = new PrizeInfoController(prizeInfoOperations);

const prizeInfoConfig = {
  [PRIZE_INFO_TYPES.GET_PRIZE_INFO.CNTRL] : prizeInfoController.getPrizeInfo,
  [PRIZE_INFO_TYPES.GET_CURRENT_DISTRIBUTIONS.CNTRL]: prizeInfoController.getCurrentDistributions,
};

export default prizeInfoConfig;
