import { PrizeFundTypes } from '../constants/enums';
import t from '../../core/staticData/translation.json';

const PRIZE_FUND_TYPE_INFO = {
    [PrizeFundTypes.GuaranteedAmount]: {
        icon: 'bc-i-icons-01',
        title: t.GUARANTEED
    },
    [PrizeFundTypes.RakeAmount]: {
        icon: 'bc-i-icons-02',
        title: t.INCREASING
    },
    [PrizeFundTypes.IncreasingGuaranteed]: {
        icon: 'bc-i-icons-03',
        title: t.INCREASING_GUARANTEED
    }
};

export default PRIZE_FUND_TYPE_INFO; 
