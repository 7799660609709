import React, { useState, useEffect, useRef } from 'react';
import Game from './game';
import Buttons from './buttons';
import { connect } from 'react-redux';
import {
  getHistory,
  getIsCurrent,
  getLobby,
  getMatches,
  getRoundInfo,
  getRoundStatus, getUserId,
  lobbyIsLoading
} from '../../../state-management/accessors/lobbyAccessors';
import {
  cntrlGetSmartPick,
} from '../../../state-management/actions/predictionActions';
import Loader from '../loader';
import {EGameStatuses, SelectionSubType, SelectionTypes} from '../../../core/constants/enums';
import {
  cntrlCreateLiveMatchesConnection,
  cntrlSetRoundAndUser
} from '../../../state-management/actions/signalRActions';
import useDidUpdateEffect from '../../../core/customHooks/useDidUpdateEffect';
import useGoogleAnalytics from '../../../core/customHooks/useGoogleAnalytics';
import { GA_CATEGORIES, GA_ACTIONS } from '../../../core/constants/googleAnalyticsConstants';
import { cntrlGetGlobalLobby } from '../../../state-management/actions/lobbyActions';
import PartnerModule from '../../../core/moduls/PartnerModul';
import { APP_CONSTANTS } from '../../../core/constants/constants';
import { SCROLLABLE_WRAPPERS } from '../../../core/constants/userTooltipGuide';
import { FieldStateContext} from '../../../contexts';
import LocalStorageService from '../../../services/services/LocalStorageService';
import widgetService from '../../../services/services/WidgetService';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation.json';
import { getUserInfo } from '../../../state-management/accessors/usersAccessors';

const getPredictions = (matches, widgetData = {}) => {
  let predictions = {
    isDoubled: null,
  };

  matches.forEach(match => {
    const { roundMatchId, matchId } = match;
    if (match.prediction?.id) {
      const { prediction: { isDoubled, selectionValues } } = match;
      const scorePrediction = selectionValues?.find(item => item.selectionType === SelectionTypes.CorrectScore);
      const homeScore = scorePrediction?.selectionSubValues.find(item => item.selectionSubType === SelectionSubType.HomeScore);
      const awayScore = scorePrediction?.selectionSubValues.find(item => item.selectionSubType === SelectionSubType.AwayScore);

      const fttsPrediction = selectionValues?.find(item => item.selectionType === SelectionTypes.FirstTeamToScore);
      const prediction = {
        roundMatchId,
        matchId,
        homeTeamScore: homeScore ? homeScore.value: null,
        awayTeamScore: awayScore ? awayScore.value: null,
        ftts: fttsPrediction ? fttsPrediction.selectionSubValues[0].value : null
      };
      if (isDoubled) {
        predictions.isDoubled = roundMatchId;
      }
      predictions[roundMatchId] = prediction;
    } else {
      const { prediction: widgetPrediction, roundMatchId: widgetRoundMatchId } = widgetData;
      if (widgetPrediction && roundMatchId === widgetRoundMatchId) {
        const { homeScore = null, awayScore = null } = widgetPrediction
        const prediction = {
          roundMatchId,
          matchId,
          homeTeamScore: homeScore,
          awayTeamScore: awayScore,
          ftts: null
        };
        predictions[roundMatchId] = prediction;
      }
    }
  });
  return predictions;
};

const SCROLL_INSURANCE = 500;

const Games = ({ matches,
                 count,
                 roundIsGlobal,
                 getGlobalLobby,
                 isLoading,
                 round: { id: roundId, isParticipant },
                 getSmartPick,
                 roundStatus,
                 userId,
                 isActionsDisabled,
                 setRoundAndUser,
                 history,
                 user
               }) => {
  const translate = useTranslation()
  const [ smartPick, setSmartPick ] = useState(false);
  const [ clearAll, setClearAll ] = useState(false);
  const { emitEvent } = useGoogleAnalytics();
  const [ showLoading, setShowLoading ] = useState(true);
  const [ disabledPredictionsClick, setDisabledPredictionsClick ] = useState(false);
  const [ isEditing, setIsEditing ] = useState(false);

  const [ tooltipValidationData, setTooltipValidationData ] = useState(null);

  const upcomingMatchExist = () => {
    if (roundIsGlobal || user.id !== userId) {
      return false;
    }

    return matches.some(match => match.status === EGameStatuses.UPCOMING);
  };

  const fieldIsDisabled = (!isEditing && isParticipant) || !upcomingMatchExist();
  const showButtons = upcomingMatchExist();

  const handlePredictionsClick = (isDisabled = fieldIsDisabled) => {
    if (!isDisabled) return;
    setDisabledPredictionsClick(prev => !prev);
  };

  const [ predictionsForBet, setPredictionsForBet ] = useState(getPredictions(matches));

  useEffect(() => {
    if (roundStatus === EGameStatuses.UPCOMING && !isParticipant) {
      const storagePrediction = getPredictionsFromLocalStorage();
      setPredictionsForBet(storagePrediction || getPredictions(matches, widgetService.data));
    } else {
      setPredictionsForBet(getPredictions(matches));
    }
  }, [matches]);

  useDidUpdateEffect(() => {
    if (roundStatus === EGameStatuses.UPCOMING && !isParticipant) {
      LocalStorageService.set(roundId, predictionsForBet)
    }
  }, [predictionsForBet]);

  useDidUpdateEffect(() => {
    setIsEditing(false);
  }, [roundId, userId]);


  const activeSmartPickButton = useRef(true);

  const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);
  const skip = matches.length;
  const take = 20;

  const getPredictionsFromLocalStorage = () => {
    const storagePrediction = LocalStorageService.get(roundId);
    const { roundMatchId, prediction } = widgetService.data;
    if (storagePrediction && roundMatchId) {
      const match = storagePrediction[roundMatchId];
      const { homeScore = null, awayScore = null } = prediction;
      if (match) {
        match.homeTeamScore = homeScore;
        match.awayTeamScore = awayScore;
      } else {
        storagePrediction[roundMatchId] = {
          homeTeamScore: homeScore,
          awayTeamScore: awayScore
        };
      }
    }
    return storagePrediction;
  };

  const handleScroll = e => {
    if(!roundIsGlobal) {
      return;
    }
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight - SCROLL_INSURANCE && !isLoading && count > skip) {
      setShowLoading(false);
      getGlobalLobby({ partnerId, skip, take, otherUserId: userId})
        .then(() => setShowLoading(true));
    }
  };

  const handleSmartPickButtonClick = () => {
    if (fieldIsDisabled) {
      return;
    }

    if (activeSmartPickButton.current) {
      activeSmartPickButton.current = false;
      emitSmartPickToGA();
      getSmartPick({roundId}).then(res => {
        activeSmartPickButton.current = true;
        const smartPickPredictions = res.predictions;
        if (!smartPickPredictions) {
          return;
        }

        const predictions = {
          isDoubled: null
        };

        smartPickPredictions.forEach(prediction => {
          const {
            matchId,
            roundMatchId,
            homeScore: homeTeamScore,
            awayScore: awayTeamScore,
            ftts,
          } = prediction;
          predictions[prediction.roundMatchId] = {
            matchId,
            roundMatchId,
            homeTeamScore,
            awayTeamScore,
            ftts
          }
        });
        setPredictionsForBet(predictions);
        setSmartPick(prev => !prev);
      });
    }
  };

  const handleClearAllButtonClick = () => {
    if (fieldIsDisabled) {
      return;
    }
    setPredictionsForBet({
      isDoubled: null
    });
    setClearAll(prev => !prev)
  };

  const emitSmartPickToGA = () => {
    emitEvent({
      category: GA_CATEGORIES.USAGE,
      action: GA_ACTIONS.SMART_PICK
    });
  };

  useEffect(() => {
    if (roundId) {
      const payload = {
        roundId,
        userId,
        isGlobal: roundIsGlobal,
      };
      setRoundAndUser(payload);
    }
  }, [roundId, userId, roundIsGlobal]);


  return (
    <div className="center-column">
      {!history.length
        ? <div className={'no-rounds'}>{ translate(t.NO_ROUNDS_AVAILABLE) }</div>
        : isLoading && showLoading
          ? <Loader />
          : <FieldStateContext.Provider value={{
            isEditing,
            setIsEditing,
            fieldIsDisabled
          }}>
            <div className="center-block-layout">
              <div className="center-of-center-block">
                <div data-scroll-wr={SCROLLABLE_WRAPPERS.HOME} className="scroll-wr" onScroll={handleScroll}>
                  <div className={`game-list-wr ${showButtons ? '': 'upcoming'}`}>
                    {
                      matches.map((match, index) => <Game match={match}
                                                          onPredictionsClick={handlePredictionsClick}
                                                          predictionsForBet={predictionsForBet}
                                                          setPredictionsForBet={setPredictionsForBet}
                                                          key={match.roundMatchId}
                                                          smartPick={smartPick}
                                                          clearAll={clearAll}
                                                          index={index}
                                                          zIndex={matches.length - index}
                                                          isParticipant={isParticipant}
                                                          isRotateDisabled={isActionsDisabled}
                                                          tooltipValidationData={tooltipValidationData}
                      />)
                    }
                  </div>
                </div>
              </div>

              {
                showButtons &&
                <Buttons handleSmartPickButtonClick={handleSmartPickButtonClick}
                         handleClearAllButtonClick={handleClearAllButtonClick}
                         disabledPredictionsClick={disabledPredictionsClick}
                         predictionsForBet={predictionsForBet}
                         setSmartPick={setSmartPick}
                         isActionsDisabled={isActionsDisabled}
                         setTooltipValidationData={setTooltipValidationData}
                />
              }
            </div>
          </FieldStateContext.Provider>
      }
    </div>
  );
};

const mapStateToProps = state => ({
  matches: getMatches(state),
  isLoading: lobbyIsLoading(state),
  round: getRoundInfo(state),
  isCurrent: getIsCurrent(state),
  roundStatus: getRoundStatus(state),
  user: getUserInfo(state),
  userId: getUserId(state),
  count: getLobby(state).data.count,
  roundIsGlobal: !!getLobby(state).data.globalRound,
  history: getHistory(state),
});

const mapDispatchToProps = dispatch => ({
  getSmartPick: data => dispatch(cntrlGetSmartPick(data)),
  setRoundAndUser: payload => dispatch(cntrlSetRoundAndUser(payload)),
  createLiveMatchesConnection: payload => dispatch(cntrlCreateLiveMatchesConnection(payload)),
  getGlobalLobby: payload => dispatch(cntrlGetGlobalLobby(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Games);
