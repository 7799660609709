import PredictionsOperation from '../../services/operations/predictions';
import predictionsApiHandler from '../../services/api/predictionsApiHandler';
import PredictionsController from '../../controllers/predictions';
import { PREDICTION_TYPES } from '../../core/constants/actionTypes';

/**operations*/
const predictionsOperation = new PredictionsOperation(predictionsApiHandler);

/**controller*/
const predictionController = new PredictionsController(predictionsOperation);

const predictionsConfig = {
  [PREDICTION_TYPES.GET_SMART_PICK.CNTRL]: predictionController.getSmartPick,
};
export default predictionsConfig;
