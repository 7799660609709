import React, { useEffect, useState, useRef } from 'react';
import LastMatchesHeader from './lastMatchesHeader';
import { connect } from 'react-redux';
import {cntrlGetMatchStat} from '../../../state-management/actions/statisticsActions';
import TeamsInfoTable from './teamsInfoTable';
import { getMatchStat} from '../../../state-management/accessors/statisticsAccessors';
import {EGameStatuses, EMatches} from '../../../core/constants/enums';
import useTranslation from '../../../core/customHooks/useTranslation';
import {compose} from 'redux';
import withPartnerSettings from '../../../hocs/withPartnerSettingsHoc';
import {PARTNER_SETTINGS_CONSTANTS} from '../../../core/constants/partnerSettingsConstants';
import t from '../../../core/staticData/translation';

const STEPS = {
  homeTeamMatches: 'homeTeamMatches',
  h2HMatches: 'h2HMatches',
  awayTeamMatches: 'awayTeamMatches'
};

const LastMatches = ({ setShowBackSpace, showBackSpace, getMatchStat, matchId, matchStat, status }) => {
  const translate = useTranslation();
  const [ step, setStep ] = useState(STEPS.homeTeamMatches);
  const isLoading = useRef(false);

  const matchStatistics = matchStat(matchId);

  useEffect(() => {
    if (isLoading.current) {
      return;
    }
    if (showBackSpace && status === EGameStatuses.UPCOMING && !matchStat(matchId)) {
      isLoading.current = true;
      getMatchStat(matchId)
        .then(() => {
          isLoading.current = false;
        });
    }
  }, [showBackSpace]);

  return (
    <>
      {showBackSpace && <LastMatchesHeader setShowBackSpace={setShowBackSpace} />}
      {showBackSpace && <div className='for-bottom-of-inside-game-list-history-wr'>
        <div className="inside-for-bottom-of-inside-game-list-history-wr">
          <div className="top-inside-for-bottom-of-inside-game-list-history-wr">
            <div className={`body-wr-of-last-game-list step-${EMatches[step]}`}>{/*add class step[1-3]*/}
              <div className="top-of-body-wr-of-last-game-list">
                <div className="table-of-team-name">
                  <div className="table-item-of-team-name"
                       onClick={()=>setStep('homeTeamMatches')}
                       title={`${translate(t.TEAM)} 1`}
                  >
                    {translate(t.TEAM)} 1
                  </div>
                  <div className="table-item-of-team-name" onClick={()=>setStep(STEPS.h2HMatches)}>h2h</div>
                  <div className="table-item-of-team-name"
                       onClick={()=>setStep(STEPS.awayTeamMatches)}
                       title={`${translate(t.TEAM)} 2`}
                  >
                    {translate(t.TEAM)} 2
                  </div>
                  <div className="table-team-underline-block">
                    <span className="team-inside-underline"/>
                  </div>
                </div>
              </div>
              <TeamsInfoTable matches={matchStatistics ? matchStatistics[step]: []}/>
            </div>
          </div>
          <div className="bottom-inside-for-bottom-of-inside-game-list-history-wr"/>
        </div>
      </div>}
    </>
  );
};


const mapStateToProps = state => ({
  matchStat: id => getMatchStat(state)(id),
});

const mapDispatchToProps = dispatch => ({
  getMatchStat: payload => dispatch(cntrlGetMatchStat(payload))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPartnerSettings(PARTNER_SETTINGS_CONSTANTS.HAS_MATCHES_STATISTIC),
)(LastMatches);

