import PredictorTranslationApiHandler from '../predictorTranslationApiHandler';

const END_POINTS = {
  prefix: 'Translations',
  game: 'code/game'
};

class TranslationsApiHandler extends PredictorTranslationApiHandler {
  constructor(prefix) {
    super(prefix);
  }

  getTranslations(query) {
    return this.get(END_POINTS.game, query);
  }
}

const translationsApiHandler = new TranslationsApiHandler(END_POINTS.prefix);
export default translationsApiHandler;
