import { SelectionTypes } from '../constants/enums';
import { TOOLTIP_HINT_TYPES } from '../constants/tooltipHints';

const DEFAULT_PREDICTION_VALIDATION_STATE = {
  isValid: true,
  index: 0, // invalid prediction match index
  reason: null // invalid prediction type
};

const getInvalidPrediction = (predictions, selectionType) => {
  const result = { ...DEFAULT_PREDICTION_VALIDATION_STATE };
  const allPredictionsEmpty = predictions.every(prediction =>
    prediction.predictionSelections.every(selection =>
      selection.selectionSubValues.every(subValue =>
        subValue.value === null
      )
    )
  );

  const invalidPredictionIndex = predictions.findIndex(prediction => {
    return prediction.predictionSelections.some(item => {
      if (selectionType === SelectionTypes.FirstTeamToScore) {
        return prediction.predictionSelections.some(item => item.selectionType !== selectionType && prediction.predictionSelections?.length < 2 && item?.selectionSubValues[0].value && item?.selectionSubValues[1].value);
      }

      return item.selectionType === selectionType &&
        item.selectionSubValues.length &&
        item.selectionSubValues.some(({ value }) => value === undefined)
    });
  });

  if (allPredictionsEmpty) {
    return {
      index: 0,
      isValid: false,
      reason: selectionType
    };
  }

  if (invalidPredictionIndex !== -1) {
    result.isValid = false;
    result.index = invalidPredictionIndex;
    result.reason = selectionType;
  }

  return result;
};

export const checkPredictionsValidation = (predictions, hasX2Mode, matches) => {
  const result = { ...DEFAULT_PREDICTION_VALIDATION_STATE };

  const invalidScorePrediction = getInvalidPrediction(predictions, SelectionTypes.CorrectScore);
  if (!invalidScorePrediction.isValid) return invalidScorePrediction;

  const invalidFirstTeamToScorePredictionIndex = getInvalidPrediction(predictions, SelectionTypes.FirstTeamToScore);
  if (!invalidFirstTeamToScorePredictionIndex.isValid) return invalidFirstTeamToScorePredictionIndex;

  if (hasX2Mode) {
    const isDoubleSelectedOnAllMatches = matches.some(match => match?.prediction?.isDoubled);
    const isDoubleSelectedOnPredictions = predictions.some(prediction => prediction?.isDoubled);
    if (!isDoubleSelectedOnPredictions && !isDoubleSelectedOnAllMatches) {
      result.isValid = false;
      result.reason = TOOLTIP_HINT_TYPES.DOUBLE_PREDICTION;
    }
  }
  return result;
};
