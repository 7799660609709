import React from 'react';
import FooterPredictionScoreBlock from './footerPredictionScoreBlock';
import { connect } from 'react-redux';
import {getUserId} from '../../../state-management/accessors/lobbyAccessors';
import {getUserInfo} from '../../../state-management/accessors/usersAccessors';
import useTranslation from '../../../core/customHooks/useTranslation';
import {getImgUrl} from '../../../core/helpers/imgUrl';
import t from '../../../core/staticData/translation';
import {SelectionTypes} from '../../../core/constants/enums';

const FooterPredictions = ({ prediction, imageUrl, user: { id }, currentUserId, title}) => {
  const translate = useTranslation();
  const isUsersPage = id === currentUserId;
  const ftts = prediction.selectionValues?.find(item => item.selectionType === SelectionTypes.FirstTeamToScore);
  const [ fttsSelection ] = ftts?.selectionSubValues || [];
  const scorePrediction = prediction.selectionValues?.find(item => item.selectionType === SelectionTypes.CorrectScore);

  return(
    <div className="bottom-your-prediction">
      <div className="bottom-your-prediction-item">
        <p className="pred-txt" title={isUsersPage? translate(t.YOUR_PREDICTION) : translate(t.PREDICTION)}>{isUsersPage? translate(t.YOUR_PREDICTION) : translate(t.PREDICTION)}</p>
      </div>
      {!!scorePrediction && <FooterPredictionScoreBlock score={scorePrediction.selectionSubValues}/>}
      <div className="bottom-your-prediction-item">
        <div className="right-icons-table-of-bottom-pred">
          {!!ftts &&
          <div className="right-icon-item-of-bottom-pred">
            {fttsSelection?.value ?
            <div className="bottom-team-icon-block-of-pred " title={title}>
              <img src={getImgUrl(imageUrl)} alt="" />
            </div> :
              <button className="game-btn state-2 inactive" type="button" title={translate(t.NONE)}>
                <span className="bc-i-noun-ban icon-none"/>
              </button>}
          </div>}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: getUserInfo(state),
  currentUserId: getUserId(state),
});

export default connect(mapStateToProps)(FooterPredictions);


