import React from 'react';
import MaintenanceLogo from '../../../images/predictor-maintenance-logo.svg'

const Maintenance = () => {
    return (
        <div className="maintenance-page-wr">
            <div className="maintenance-container">
                <div className="maintenance-logo-block">
                    <img src={MaintenanceLogo} alt=""/>
                </div>
                <div className="maintenance-info-block">
                    <div className="maintenance-info-block-row">
                        <p className="maintenance-info-block-row-txt">
                            Currently Unavailable due to optimization purposes.
                        </p>
                    </div>
                </div>
            </div>
            <div className="powered-block">
                <p className="powered-txt">
                    powered by <span className="bold-powered"><span>bet</span>construct</span>
                </p>
            </div>
        </div>
    );
};

export default Maintenance;
