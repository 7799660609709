import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';
import PartnerModule from '../../../core/moduls/PartnerModul';
import { APP_CONSTANTS } from '../../../core/constants/constants';
import { TRANSLATION_HEADERS } from '../../../core/constants/headers';
import defaultTranslation from '../../../core/staticData/defaultTranslation';

class TranslationOperations extends Operations {
  constructor(translationApiHandler) {
    super();
    this.translationApiHandler = translationApiHandler;
  }


  async getTranslations(store, action) {

    let urlLang = PartnerModule.getConfigItem(APP_CONSTANTS.LANGUAGE);

    const query = {
      // LanguageId: langId,
      // LanguageId: langId, // ? TODO
      code: urlLang,
      ApplicationId: TRANSLATION_HEADERS.ApplicationId
    };
    const response = await this.translationApiHandler.getTranslations(
      query
    );
    if (response.result && Object.keys(response.result).length) {
      return responseErrorCheck(response);
    } else {
      return defaultTranslation;
    }
  }
}

export default TranslationOperations;
