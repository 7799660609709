import React, {useContext} from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation';
import {FieldStateContext} from '../../../contexts';
import { noop } from '../../../core/helpers/noop';

const NoneScoreSelectedBlock = ({ setActive, onDisableClick = noop}) => {
  const { fieldIsDisabled } = useContext(FieldStateContext);
  const translate = useTranslation();

  const handleClick= () => {
    if (fieldIsDisabled) {
      onDisableClick(fieldIsDisabled);
      return;
    }
    setActive('active');
  };

  return (
    <>
      <div className="team-choose-item">
        <p className="team-choose-txt" title={translate(t.FIRST_TEAM_TO_SCORE)}>{translate(t.FIRST_TEAM_TO_SCORE)}</p>
      </div>
      <div className="team-choose-item">
        <button className="game-btn state-2"
                type="button" onClick={handleClick}
                title={translate(t.NONE)}
                tabIndex={-1}>
          <span className="bc-i-noun-ban icon-none"/>
        </button>
      </div>
    </>
  );
};

export default NoneScoreSelectedBlock;
