 import React from 'react';
import TeamSelectedBlock from './teamSelectedBlock';
import defaultImage from '../../../images/defaultImage.png';
 import { getImgUrl } from '../../../core/helpers/imgUrl';

const TeamChooseBlockItem = ({ imageUrl,
                               addFtts,
                               title
}) => {

  return (
    <div className="teams-remove-block-bottom-item">
      <div className="teams-remove-block-bottom-i-img-block" >
        <img src={getImgUrl(imageUrl)} alt="" onClick={addFtts} title={title} onError={e => e.target.src = defaultImage}/>
      </div>
    </div>
  );
};

export default TeamChooseBlockItem;
