import React from 'react';
import { useSelector} from 'react-redux';

const withPartnerSettings = setting => Component => {
  const WithPartnerSettings = React.memo(props => {
    const partnerSettings = useSelector(state => state.partnerSettings.data);
    return (
      partnerSettings[setting] ? <Component {...props} /> : null)
  });
  return WithPartnerSettings;
};

export default withPartnerSettings;


