class ErrorHandler {
  static errorCodesEnum = {
    frontErrors: 400,
    unAuthorized: 401,
    serverError: 500,
    unverified: 106,
    duplicateRequest: 429,
    notAcceptable: 406
  };

  static errorCodesWithErrorMessage = {
    400: 'FRONT_ERROR',
    401: 'TOKEN_EXPIRED',
    500: 'SOMETHING_WENT_WRONG',
    429: 'DUPLICATE',
    106: 'UNVERIFIED',
  };

  static errorConstants = {
    status: 'status',
    message: 'message',
    error: 'error',
  };

  static errorCodes = {
    unverified: 106,
    lowDepositAmount: 167,
    lowDepositCount: 168,
    lowDepositLastDate: 169
  };

  static errorNames = {
    abortError: 'AbortError',
  };


  static getErrorByStatus (errorStatus) {
    if (this.errorCodesWithErrorMessage.hasOwnProperty(errorStatus)){
      return this.errorCodesWithErrorMessage[errorStatus];
    }
    return null//errorStatus
  }
}

export default ErrorHandler;
