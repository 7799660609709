import React, { PureComponent } from 'react';
import calculateBoundingBoxes from '../../../core/helpers/calculateBoundingBoxes';

const boxIsInView = box => {
  return box.top >= 0 &&
    box.bottom <= window.innerHeight + 500
};

class AnimatedList extends PureComponent {
  constructor(props) {
    super(props);
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevProps.data === this.props.data) {
      return {};
    }
    return {
      prevBoundingBox: calculateBoundingBoxes(prevProps.children),
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.data === this.props.data) {
      return;
    }

    const { prevBoundingBox } = snapshot;
    const boundingBox = calculateBoundingBoxes(this.props.children);
    const hasBoundingBox = Object.keys(boundingBox).length;

    if (hasBoundingBox) {
      React.Children.forEach(this.props.children, child => {
        if(!child) {
          return;
        }

        const domNode = child.ref.current;
        const firstBox = prevBoundingBox[child.key];
        const lastBox = boundingBox[child.key];

        if (firstBox && lastBox) {
          if (!boxIsInView(firstBox) && !boxIsInView(lastBox)) {
            return;
          }

          const changedDistance = firstBox.top - lastBox.top;

          if (changedDistance) {
            requestAnimationFrame(() => {
              domNode.style.transform = `translateY(${changedDistance}px)`;
              domNode.style.transition = "transform 0s";
              requestAnimationFrame(() => {
                domNode.style.transform = "";
                domNode.style.transition = `transform ${500}ms`;
              });
            });
          }
        }

        if (!firstBox && lastBox) {
          if(!boxIsInView(lastBox)) {
            return;
          }
          requestAnimationFrame(() => {
            domNode.style.transform = 'scale(0)';
            domNode.style.opacity = '0';
            domNode.style.transition = "transform 0s";
            requestAnimationFrame(() => {
              domNode.style.transform = "";
              domNode.style.opacity = '';
              domNode.style.transition = `transform ${500}ms`;
            });
          });
        }
      })
    }
  }

  render() {
    return this.props.children;
  }
}


export default AnimatedList;
