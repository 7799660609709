import Controller from '../../core/classes/AppController';
import translationService from '../../services/services/TranslationService';

class TranslationController extends Controller {
  constructor(translationOperations) {
    super();
    this.translationOperations = translationOperations;
  }

  getTranslations = async (store, action) => {
      const response = await this.translationOperations.getTranslations(store, action);
      translationService.setState((response))

  };
}

export default TranslationController;
