import infoApiHandler from '../../services/api/infoApiHandler';
import InfoOperations from '../../services/operations/infoOperations';
import InfoController from '../../controllers/infoController';
import {INFO_TYPES} from '../../core/constants/actionTypes';

const infoOperations = new InfoOperations(infoApiHandler);
const infoController = new InfoController(infoOperations);

const infoConfig = {
  [INFO_TYPES.GET_INFO.CNTRL]: infoController.getInfo,
};

export default infoConfig;
