import {ROUND_TYPES} from '../../core/constants/actionTypes';

export const cntrlGetDistribution = payload => ({
  type: ROUND_TYPES.CNTRL_GET_DISTRIBUTION,
  payload,
});

export const rxGetDistributionPending = payload => ({
  type: ROUND_TYPES.RX_GET_DISTRIBUTION_PENDING,
  payload,
});

export const rxGetDistributionDone = payload => ({
  type: ROUND_TYPES.RX_GET_DISTRIBUTION_DONE,
  payload,
});

export const cntrlBet = payload => ({
  type: ROUND_TYPES.CNTRL_BET,
  payload,
});
