import React from 'react';
import defaultImage from '../../../images/defaultImage.png'
import {getImgUrl} from '../../../core/helpers/imgUrl';

const TableTitle = ({name, logoUrl, externalLogoUrl, setActive}) => (
  <div className="table-title" onClick={() => setActive(active => !active)}>
    <div className="left-table-t-image-b">
      <img src={logoUrl ? getImgUrl(logoUrl) : getImgUrl(externalLogoUrl)} onError={e => e.target.src = defaultImage} alt=""/>
    </div>
    <p className="league-name">{name}</p>
    <div className="right-icon-block-of-t-t" >
      <span className="bc-i-small-arrow-down"/>
    </div>
  </div>
);

export default TableTitle;
