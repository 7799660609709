import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';

class LobbyOperations extends Operations {
  constructor(lobbyApiHandler) {
    super();
    this.lobbyApiHandler = lobbyApiHandler;
  }

  async getLobby(store, action) {
    const response = await this.lobbyApiHandler.getLobby(action.payload);
    return responseErrorCheck(response);
  }

  async getGlobalLobby(store, action) {
    const response = await this.lobbyApiHandler.getGlobalLobby(action.payload);
    return responseErrorCheck(response);
  }

  async getLeaderBoard(store, action) {
    const response = await this.lobbyApiHandler.getLeaderBoard(action.payload);
    return responseErrorCheck(response);
  }

  async getHistory (store, action) {
    const response = await this.lobbyApiHandler.getHistory(action.payload);
    return responseErrorCheck(response);
  }
}

export default LobbyOperations;
