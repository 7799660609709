import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { getRoundInfo}  from '../../../state-management/accessors/lobbyAccessors';
import { getCurrencySign } from '../../../core/helpers/getCurrency';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation';
import { cntrlBet } from '../../../state-management/actions/roundActions';
import { FieldStateContext } from '../../../contexts';
import { BET_ACTIONS } from '../../../core/constants/betActions';


const BetButton  = ({
                      round: { entryFee, isParticipant },
                      handleBetButtonClick,
                      showSuccessAnimation,
                      tooltipId,
                      setSmartPick,
                      disabled,
                      hide
}) => {
  const { isEditing, setIsEditing } = useContext(FieldStateContext);



  const translate = useTranslation();
  const buttonText = entryFee ? `${translate(t.BET)} ${entryFee} ${getCurrencySign()}`: translate(t.ACCEPT_BET);

  const betAction = isParticipant ? BET_ACTIONS.SAVE : entryFee? BET_ACTIONS.BET : BET_ACTIONS.FREE;


  const handleEditButtonClick = () => {
    setIsEditing(true);
    setSmartPick(prev => !prev);
  };

  return (
    <div
      id={tooltipId}
      data-tooltip={tooltipId}
      className={`bet-btn-holder ${hide ? 'hide-bet-btn' : ''} ${disabled ? 'bet-btn-disabled' : ''}`}
    >
      {
        isEditing ?
          <button disabled={disabled} className="bet-btn" type="button" onClick={() => handleBetButtonClick(betAction)}>{translate(t.SAVE)}</button>:
          isParticipant ?
            <button disabled={disabled} className={`bet-btn ${showSuccessAnimation ? 'skeleton': ''}`} type="button" onClick={handleEditButtonClick}>{translate(t.EDIT)}</button>:
            <button disabled={disabled} className="bet-btn" onClick={() => handleBetButtonClick(betAction)}>{buttonText}</button>
      }
    </div>
  )
};

const mapStateToProps = state =>({
  round: getRoundInfo(state),
});

const mapDispatchToProps = {
  bet: cntrlBet,
};
export default connect(mapStateToProps, mapDispatchToProps)(BetButton);
