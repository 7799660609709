const abortRegistry =  {};

export const abortFetch = apiName => {
  const abortController = abortRegistry[apiName];
  if (abortController) {
    abortController.abort();
  }
  abortRegistry[apiName] = new AbortController();
  return abortRegistry[apiName].signal;
};
