class TypeChecker {

  static isEmptyObject(obj) {
    return !Object.keys(obj).length
  }

  static isFunction(func) {
    return typeof func === 'function';
  }

  static isNumber(value) {
    return typeof value === 'number';
  }

  static isString(val) {
    return typeof val === 'string';
  }

  static isEmptyString(val){
    return val === '';
  }

  static isArray(val) {
    return Array.isArray(val);
  }

  static isNull(val) {
    return val === null;
  }

  static isUndefined(val) {
    return val === undefined
  }
}

export default TypeChecker;
