import React, { useEffect, useRef, useState } from 'react';
import GuidePopupHeader from './guidePopupHeader';
import GuidePopupFooter from './guidePopupFooter';
import GuidePopupContent from './guidePopupContent';
import { isMobile } from '../../../core/helpers/isMobile';
import { TOOLTIP_ARROW_POSITIONS } from '../../../core/constants/userTooltipGuide';
import useOutsideClick from '../../../core/customHooks/useOutsideClick';

const DEFAULT_COORDINATES = { top: -1000, left: -1000 };

const GuidePopup = ({
    target,
    onNext,
    onPrev,
    onClose,
    isPrevDisabled,
    isNextDisabled,
    onOutsideClick,
    openerButtonId,
    currentStep: { title, description, web, mobile }
}) => {

    const popupRef = useRef(null);
    const [ coordinates, setCoordinates ] = useState(DEFAULT_COORDINATES);
    const arrow = isMobile() ? mobile.arrow : web.arrow;
    const promptMode = !!onOutsideClick;

    useOutsideClick(popupRef, onOutsideClick, openerButtonId);

    useEffect(() => {
        setCoordinates(getPopupCoordinates());
    }, [target]);

    const getPopupCoordinates = () => {
        const popupRect = popupRef.current?.getBoundingClientRect();
        if (!popupRect) {
            return {...DEFAULT_COORDINATES};
        }
        const targetRect = target.getBoundingClientRect();
        const { width, height } = targetRect;
        const { width: popupWidth, height: popupHeight } = popupRect;
        let left = 0;
        let top = 0;

        const arrowDistanceFromAngle = 25;

        switch (arrow) {
            case TOOLTIP_ARROW_POSITIONS.LEFT_TOP:
                left += width;
                top += height / 2 - arrowDistanceFromAngle;
                break;
        
            case TOOLTIP_ARROW_POSITIONS.LEFT_BOTTOM:
                left += width;
                top += height / 2 + arrowDistanceFromAngle - popupHeight;
                break;

            case TOOLTIP_ARROW_POSITIONS.RIGHT_TOP:
                left -= popupWidth;
                top += height / 2 - arrowDistanceFromAngle;
                break;
        
            case TOOLTIP_ARROW_POSITIONS.RIGHT_BOTTOM:
                left -= popupWidth;
                top += height / 2 + arrowDistanceFromAngle - popupHeight;
                break;
        
            case TOOLTIP_ARROW_POSITIONS.TOP_LEFT:
                left += width / 2 - arrowDistanceFromAngle;
                top += height;
                break;
        
            case TOOLTIP_ARROW_POSITIONS.TOP_CENTER:
                left += width / 2 - popupWidth / 2;
                top += height;
                break;
        
            case TOOLTIP_ARROW_POSITIONS.TOP_RIGHT:
                left += width / 2 + arrowDistanceFromAngle - popupWidth;
                top += height;
                break;
        
            case TOOLTIP_ARROW_POSITIONS.BOTTOM_LEFT:
                left += width / 2 - arrowDistanceFromAngle;
                top -= popupHeight;
                break;
        
            case TOOLTIP_ARROW_POSITIONS.BOTTOM_CENTER:
                left += width / 2 - popupWidth / 2;
                top -= popupHeight;
                break;

            case TOOLTIP_ARROW_POSITIONS.BOTTOM_RIGHT:
                left += width / 2 + arrowDistanceFromAngle - popupWidth;
                top -= popupHeight;
                break;

            case TOOLTIP_ARROW_POSITIONS.BOTTOM_RIGHT_CENTER:
                left += width / 2 - popupWidth * 0.65;
                top -= popupHeight;
                break;

            case TOOLTIP_ARROW_POSITIONS.BOTTOM_LEFT_CENTER:
                left += width / 2 - popupWidth * 0.35;
                top -= popupHeight;
                break;
        
            default:
                break;
        }

        return { left, top };
    };

    return (
        <div
            ref={popupRef}
            style={coordinates}
            className={`tooltip-wr ${arrow || ''}`}
        >
            <div className="where-from" />
            <div className="tooltip-inner">
                <GuidePopupHeader title={title} onClose={onClose} />
                <div className="tooltip-inner-bottom">
                    <GuidePopupContent description={description} />
                    {
                        !promptMode ? 
                        <GuidePopupFooter
                            onNext={onNext}
                            onPrev={onPrev}
                            isPrevDisabled={isPrevDisabled}
                            isNextDisabled={isNextDisabled}
                        /> : null
                    }
                </div>
            </div>
        </div>
    );
};

export default GuidePopup;