export function parseQuery(queryString) {
  queryString = queryString.split('?')[1] ? queryString.split('?')[1] : '?';
  const query = {};
  const pairs = queryString[0] === '?' ? [] : queryString.split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query
}



export const qetQueryByUrl = () => {
  const query = window.location.href.split('?')[1];
  return query ? `?${query}` : ''
};
