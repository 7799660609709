import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';
import PartnerModule from '../../../core/moduls/PartnerModul';
import {APP_CONSTANTS} from '../../../core/constants/constants';


class PrizeInfoOperations extends Operations{
  constructor(prizeInfoApiHandler){
    super();
    this.prizeInfoApiHandler = prizeInfoApiHandler;
  }

  async getPrizeInfo (store, action) {
    const response = await this.prizeInfoApiHandler.getPrizeInfo(action.payload);
    return responseErrorCheck(response)
  }

  async getCurrentDistributions () {
    const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);
    const response = await this.prizeInfoApiHandler.getCurrentDistributions({ partnerId});
    responseErrorCheck(response);
    const { globalDistribution } = response.result;

    return  {
      ...globalDistribution,
      rows: globalDistribution.rows? globalDistribution.rows.map(item => {
        return {
          ...item,
          places: item.fromPlace === item.toPlace ? item.fromPlace : `${item.fromPlace}-${item.toPlace}`,
        }
      }): [],
      prizeFund: response.result.globalPrizeFund,
    };
  }
}

export default PrizeInfoOperations;
