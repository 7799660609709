import React from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation';

const LeaderBoardTitle = ({ status }) => {
  const translate = useTranslation();
  return (
    <div className="info-row title">
      <div className="info-cell title" title={status ? translate(t.RANK) : null}>{status ? translate(t.RANK) : '#'}</div>
      <div className="info-cell title" title={translate(t.USERS)}>{translate(t.USERS)}</div>
      <div className="info-cell title" title={translate(t.POINTS)}>{translate(t.POINTS)}</div>
      <div className="info-cell title" title={translate(t.WINNINGS)}>{translate(t.WINNINGS)}</div>
    </div>
  );
};

export default LeaderBoardTitle;
