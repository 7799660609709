import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';



class SocialMediaOperations extends Operations{
  constructor(socialMediaApiHandler){
    super();
    this.socialMediaApiHandler = socialMediaApiHandler;
  }

  async getSharedToken (store, action) {
    const response = await this.socialMediaApiHandler.getSharedToken(action.payload);
    return responseErrorCheck(response);
  }
}

export default SocialMediaOperations;
