import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';

class RoundOperations extends Operations{
  constructor(roundApiHandler){
    super();
    this.roundApiHandler = roundApiHandler;
  }

  async getDistribution (store, action) {
    const response = await this.roundApiHandler.getDistribution(action.payload);
    responseErrorCheck(response);
    return {
      ...response.result,
      rows: response.result.rows ? response.result.rows.map(item => {
        return {
          ...item,
          places: item.fromPlace === item.toPlace ? item.fromPlace : `${item.fromPlace} - ${item.toPlace}`,
        }
      }) : [],
    }
  }

  async bet (predictions) {
    const response = await this.roundApiHandler.bet(predictions);
    return { result: responseErrorCheck(response), responseStatus: response.responseStatus };
  }
}

export default RoundOperations;
