import React, { useState, useEffect, useContext, useRef } from 'react';
import {getStartAndEndDate} from '../../../core/helpers/dateHelpers';
import { connect } from 'react-redux';
import {cntrlGetLobby} from '../../../state-management/actions/lobbyActions';
import PartnerModule from '../../../core/moduls/PartnerModul';
import {getIsCurrent, getLobby, getRoundId, getUserId} from '../../../state-management/accessors/lobbyAccessors';
import { ERoundStatuses} from '../../../core/constants/enums';
import { useHistory, useLocation } from 'react-router';
import {combineQuery} from '../../../core/helpers/combineQuery';
import {parseQuery} from '../../../core/helpers/parseQuery';
import { APP_CONSTANTS } from '../../../core/constants/constants';
import {getUserInfo} from '../../../state-management/accessors/usersAccessors';
import {NavigationContext} from '../../../contexts';
import {LEFT_COLUMN_ACTIVE_TABS, MOBILE_TABS, NAVIGATION_ITEMS} from '../../../core/constants/navigationConstants';
import ShareBlock from './shareBlock';
import {cntrlGetSharedToken} from '../../../state-management/actions/signalRActions/socialMediaActions';
import {SOCIAL_SHARE_URL} from '../../../core/constants/urls';
import {SHARE_CONSTANTS} from '../../../core/constants/shareConstants';
import {getPartnerSettings} from '../../../state-management/accessors/partnerAccessors';
import { TOOLTIP_TARGET_IDS } from '../../../core/constants/userTooltipGuide';
import ScreenShot from '../../containers/screenshot';
import useTranslation from '../../../core/customHooks/useTranslation';
import { SCREENSHOT_SIZES } from '../../containers/screenshot/constants';

const getFacebookUrl = token => `https://www.facebook.com/v5.0/dialog/share?action_properties=${JSON.stringify({
  object:`${SOCIAL_SHARE_URL}/${token}`,
})}&action_type=og.shares&app_id=777211169442783&display=popup&hashtag=%23predictor&href=https%3A%2F%2Fpredictor-api-stage.rpd.cloud%2FsocialMedia%2Fshared%2F${token}&e2e={}&redirect_uri=https://facebook.com&locale=en_US&quote=Make%20your%20own%20predictions&sdk=joey&version=v5.0`;

function popupWindow(url, title, win, w, h) {
  const y = win.top.outerHeight / 2 + win.top.screenY - ( h / 2);
  const x = win.top.outerWidth / 2 + win.top.screenX - ( w / 2);
  return win.open(url, title, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
}


const HistoryItem = ({ name,
                       startDate,
                       endDate,
                       getLobby,
                       id,
                       currentRoundId,
                       status,
                       isDisabled,
                       user: { id: userId },
                       selectedUserId,
                       getSharedToken,
                       userPredictionCount,
                       roundIsGlobal,
                       hasSocialSharing }) => {
  const history = useHistory();
  const location = useLocation();
  const {
    leftColumnActiveTab,
    setActiveTab,
    setActiveNavItem
  } = useContext(NavigationContext);
  const [ activeShareBlock, setActiveShareBlock ] = useState(false);
  const activeButton = useRef(true);

  const translate = useTranslation();

  const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);
  const selected = (id === currentRoundId) && !roundIsGlobal;
  const isUsersPage = userId === selectedUserId;
  const roundIsFinished = status === ERoundStatuses.FINISHED;
  const roundIsBlocked = status === ERoundStatuses.BLOCKED;
  const showShareButton = hasSocialSharing && isUsersPage && roundIsFinished && userId && userPredictionCount > 1;

  useEffect(() => {
    if(!selected){
      setActiveShareBlock(false);
    }
  }, [selected]);

  useEffect(() => {
    leftColumnActiveTab === LEFT_COLUMN_ACTIVE_TABS.TABLES && setActiveShareBlock(false);
  }, [leftColumnActiveTab]);

  const handleClick = () => {
    if (isDisabled) return;
    if(!roundIsBlocked) {
      getLobby({ partnerId, roundId: id });
      history.push(combineQuery({
        ...parseQuery(location.search),
        [APP_CONSTANTS.ROUNDID]: id,
        [APP_CONSTANTS.USERID]: null,
        [APP_CONSTANTS.GLOBAL_ROUND]: null
        }
        ));
    }

    setActiveTab(MOBILE_TABS.HOME);
    setActiveNavItem(NAVIGATION_ITEMS.HOME);
  };

  const handleShareButtonClick = (e) => {
    if(selected){
      e.stopPropagation();
      setActiveShareBlock(active => !active);
    }
  };

  const handleFacebookButtonClick =async  e => {
    e.stopPropagation();
    if (!activeButton.current) {
      return ;
    }
    activeButton.current = false;
    // const y = window.outerHeight / 2 + window.screenY - ( 800 / 2);
    // const x = window.outerWidth / 2 + window.screenX - ( 500 / 2);

    let w = window.open('', '_blank', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${800}, height=${500}`);
    const image = await ScreenShot.handleScreenShot();
    // const FB = window.FB;
    const formData = new FormData();
    formData.append('roundId', id);
    formData.append('image', image);
    formData.append('CropRectangle ', JSON.stringify(SCREENSHOT_SIZES));
    const res = await getSharedToken(formData);
      if(typeof res === 'object') {
        activeButton.current = true;
        w.close();
        return;
      }
    w.location.href = getFacebookUrl(res);
    activeButton.current = true;
      // FB.ui({
      //   // display: SHARE_CONSTANTS.DISPLAY,
      //   method: SHARE_CONSTANTS.SHARE,
      //   action_type: SHARE_CONSTANTS.ACTION_TYPE,
      //   action_properties: JSON.stringify({
      //     object:`${SOCIAL_SHARE_URL}/${res}`,
      //   }),
      //   href: getFacebookUrl(res),  //window.location.href
      //   hashtag: SHARE_CONSTANTS.HASHTAG,
      //   quote: SHARE_CONSTANTS.QUOTE,
      // }, function (response) {
      //   activeButton.current = true;
      // });
  };

  const handleTwitterButtonClick = async e => {
    e.stopPropagation();
    if (!activeButton.current) {
      return ;
    }
    activeButton.current = false;
    const y = window.outerHeight / 2 + window.screenY - ( 800 / 2);
    const x = window.outerWidth / 2 + window.screenX - ( 500 / 2);

    let w = window.open('', '_blank', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${800}, height=${500}, top=${y}, left=${x}`);
    const image = await ScreenShot.handleScreenShot();
    const formData = new FormData();
    formData.append('roundId', id);
    formData.append('image', image);
    getSharedToken(formData).then(res => {
      if(typeof res === 'object') {
        activeButton.current = true;
        w.close();
        return;
      }
      w.location.href = `https://twitter.com/intent/tweet?text=${SHARE_CONSTANTS.QUOTE}&hashtags=${SHARE_CONSTANTS.TWITTER_HASHTAG}&url=${SOCIAL_SHARE_URL}/${res}`;
      // popupWindow(`https://twitter.com/intent/tweet?text=${SHARE_CONSTANTS.QUOTE}&hashtags=${SHARE_CONSTANTS.TWITTER_HASHTAG}&url=${SOCIAL_SHARE_URL}/${res}`, '', window, 600, 300 );
      activeButton.current = true;
    })


  };

  return (
    <div className="history-item-wr" data-tooltip={roundIsFinished ? TOOLTIP_TARGET_IDS.FINISHED_ROUND : ''}>
      <div className={`history-item ${isDisabled ? 'tooltip-disabled' : ''} ${selected? 'selected' : ''} ${activeShareBlock ? 'active' : '' } ${roundIsBlocked? 'inactive': ''} have-status`}
          onClick={handleClick}
          title={name}> {/*remove class active*/}

            <p className="status-txt">{translate(ERoundStatuses[status])}</p>
        <p className="history-game-info"  >{name} </p>
        {/*{showShareButton && <ShareBlock activeShareBlock={activeShareBlock}
                                        handleShareButtonClick={handleShareButtonClick}
                                        selected={selected}
                                        handleFacebookButtonClick={handleFacebookButtonClick}
                                        handleTwitterButtonClick={handleTwitterButtonClick}
        />}*/}
        {roundIsBlocked &&
        <div className="history-icon-block" >
          <span className="bc-i-lock " />
        </div>}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  currentRoundId: getRoundId(state),
  isCurrent: getIsCurrent(state),
  user: getUserInfo(state),
  selectedUserId: getUserId(state),
  roundIsGlobal: !!getLobby(state).data.globalRound,
  hasSocialSharing: getPartnerSettings(state).hasSocialSharing,
});

const mapDispatchToProps = dispatch => ({
  getLobby: payload => dispatch(cntrlGetLobby(payload)),
  getSharedToken: payload =>dispatch(cntrlGetSharedToken(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryItem);
