export function setGlobalColors (colors) {
  const root = document.documentElement;
  root.style.setProperty('--global-color', colors.GlobalColor);
  root.style.setProperty('--hero-color', colors.HeroColor);
  root.style.setProperty('--brand-color-1', colors.BrandColor1);
  root.style.setProperty('--brand-color-2', colors.BrandColor2);
  root.style.setProperty('--text-color', colors.TextColor);
  root.style.setProperty('--status-color-1', colors.StatusColor1);
  root.style.setProperty('--status-color-2', colors.StatusColor2);
  root.style.setProperty('--status-color-3', colors.StatusColor3);
  root.style.setProperty('--skin-color-black', colors.SkinColorBlack);
  root.style.setProperty('--skin-color-white', colors.SkinColorWhite);
  // root.style.setProperty('--skin-color-1', colors.SkinColor1);
  root.style.setProperty('--skin-color-2', colors.SkinColor2);
  root.style.setProperty('--skin-color-3', colors.SkinColor3);
  root.style.setProperty('--skin-color-4', colors.SkinColor4);
  // root.style.setProperty('--skin-color-5', colors.SkinColor5);
  root.style.setProperty('--skin-color-6', colors.SkinColor6);
  // root.style.setProperty('--skin-color-7', colors.SkinColor7);
  root.style.setProperty('--skin-color-8', colors.SkinColor8);
  root.style.setProperty('--skin-color-9', colors.SkinColor9);
  root.style.setProperty('--skin-color-10', colors.SkinColor10);
  root.style.setProperty('--skin-color-11', colors.SkinColor11);
  root.style.setProperty('--skin-color-12', colors.SkinColor12);
  root.style.setProperty('--skin-color-13', colors.SkinColor13);
  root.style.setProperty('--skin-color-14', colors.SkinColor14);
  root.style.setProperty('--skin-color-15', colors.SkinColor15);
  root.style.setProperty('--skin-color-16', colors.SkinColor16);
  root.style.setProperty('--skin-color-17', colors.SkinColor17);
  root.style.setProperty('--skin-color-18', colors.SkinColor18);
  root.style.setProperty('--skin-color-19', colors.SkinColor19);
  root.style.setProperty('--skin-color-20', colors.SkinColor20);
  root.style.setProperty('--skin-color-21', colors.SkinColor21);
  root.style.setProperty('--skin-color-22', colors.SkinColor22);

  root.style.setProperty('--line-color', colors.LineColor);
  root.style.setProperty('--scroll-color', colors.ScrollBarColor);
  root.style.setProperty('--ticket-bg-color', colors.TicketBackgroundColor);
  root.style.setProperty('--ticket-header-color', colors.TicketHeaderColor);
  root.style.setProperty('--info-row-color', colors.InformationRowColor);
  root.style.setProperty('--rd-header-color', colors.RoundHeaderColor);
  root.style.setProperty('--rd-body-color', colors.RoundsBodyColor);
  root.style.setProperty('--rd-name-color', colors.RoundNameColor);
  root.style.setProperty('--rd-tb-bg-color', colors.RoundsTablesBackgroundColor);
  root.style.setProperty('--loader-color', colors.LoadingElementColor);

  root.style.setProperty('--filter', colors.filter);
}
