import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';

class StatisticsOperations extends Operations{
  constructor(statisticsApiHandler){
    super();
    this.statisticsApiHandler = statisticsApiHandler;
  }

  async getCompetitionsTables (store, action){
    const response = await this.statisticsApiHandler.getCompetitionsTables(action.payload);
    return responseErrorCheck(response)
  }

  async getMatchStat (store, action) {
    const response = await this.statisticsApiHandler.getMatchStat(action.payload);
    return responseErrorCheck(response);
  }

  async getCompetitionTable (store, action) {
    const response = await this.statisticsApiHandler.getCompetitionTable(action.payload);
    return responseErrorCheck(response);
  }
}

export default StatisticsOperations;
