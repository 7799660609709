import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingPage from '../../pages/loadingPage';
import { getIsAuth } from '../../../state-management/accessors/appAccessors';
import {cntrlAppInit} from '../../../state-management/actions/appActions';

const Splash = props => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    props.init().then(() => {
      setTimeout(() => setLoading(false), 0);
    });
  }, []);


  return isLoading ? <LoadingPage/> : <>{props.children}</>
};


Splash.propTypes = {
  isAuth: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuth: getIsAuth(state)
});

const mapDispatchToProps = dispatch => ({
  init: () => dispatch(cntrlAppInit())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Splash));
