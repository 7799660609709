import React, {memo} from 'react';
import octopus from '../../../images/predictor-logo.png';
import fild from '../../../images/loader-fild.svg';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation';

const LoadingPage = memo(() => {
  const translate = useTranslation();
  
  return (
    <div className="loading-p-wrapper">
      <div className="center-load-v">
        <div className="load-p-content">
          <div className="stars-b">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                 xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 789.9 166.7"
                 xmlSpace="preserve" className="">
              <g>
	<path className="st0 wWTrqeji_0 qYZzCUPC_0 dNRdaTXM_0" d="M788.8,165.9L1.1,166.2L141.3,0.5L648.8,0.5Z"></path>
                <path className="st0 wWTrqeji_1 qYZzCUPC_1 dNRdaTXM_1" d="M77.6,76L64.6,76L82.7,55L95.4,54.9Z"></path>
                <path className="st0 wWTrqeji_2 qYZzCUPC_2 dNRdaTXM_2"
                      d="M101.6,95.4L60.9,95.5L105.1,43.3L139.8,43.3Z"></path>
                <path className="st0 wWTrqeji_3 qYZzCUPC_3 dNRdaTXM_3"
                      d="M169.5,133L29.2,132.8L128.5,15.7L230.3,15.9Z"></path>
                <path transform="matrix(0.992 -0.1262 0.1262 0.992 -6.9322 21.3689)"
                      className="st0 wWTrqeji_4 qYZzCUPC_4 dNRdaTXM_4"
                      d="M162.7,65.4A2.5,0.8 0,1,1 167.7,65.4A2.5,0.8 0,1,1 162.7,65.4"></path>
                <path className="st0 wWTrqeji_5 qYZzCUPC_5 dNRdaTXM_5" d="M138.6,3.7c0,0,8.9,0.9,12-3.3"></path>
                <path className="st0 wWTrqeji_6 qYZzCUPC_6 dNRdaTXM_6" d="M7.7,158.2c0,0,14.3-0.9,10.1,7.9"></path>
                <path className="st0 wWTrqeji_7 qYZzCUPC_7 dNRdaTXM_7"
                      d="M215.9,45.5c0,0,22.8,6.7,14,24c0,0-4.4,9.6-36.6,17.4"></path>
                <path className="st0 wWTrqeji_8 qYZzCUPC_8 dNRdaTXM_8"
                      d="M329.5,67.2A65.7,26.2 0,1,1 460.9,67.2A65.7,26.2 0,1,1 329.5,67.2"></path>
                <path className="st0 wWTrqeji_9 qYZzCUPC_9 dNRdaTXM_9"
                      d="M392.70000000000005,65.3A2.4,0.7 0,1,1 397.5,65.3A2.4,0.7 0,1,1 392.70000000000005,65.3"></path>
                <path className="st0 wWTrqeji_10 qYZzCUPC_10 dNRdaTXM_10" d="M395.2,0.5L395.2,165.6"></path>
                <path className="st0 wWTrqeji_11 qYZzCUPC_11 dNRdaTXM_11"
                      d="M712.3,76L725.3,76L707.2,55L694.5,54.9Z"></path>
                <path className="st0 wWTrqeji_12 qYZzCUPC_12 dNRdaTXM_12"
                      d="M688.3,95.4L729,95.5L684.7,43.3L650,43.3Z"></path>
                <path className="st0 wWTrqeji_13 qYZzCUPC_13 dNRdaTXM_13"
                      d="M620.4,133L760.6,132.8L661.4,15.7L559.6,15.9Z"></path>
                <path transform="matrix(0.1262 -0.992 0.992 0.1262 480.9439 676.8021)"
                      className="st0 wWTrqeji_14 qYZzCUPC_14 dNRdaTXM_14"
                      d="M623.8000000000001,65.4A0.8,2.5 0,1,1 625.4,65.4A0.8,2.5 0,1,1 623.8000000000001,65.4"></path>
                <path className="st0 wWTrqeji_15 qYZzCUPC_15 dNRdaTXM_15" d="M651.3,3.7c0,0-8.9,0.9-12-3.3"></path>
                <path className="st0 wWTrqeji_16 qYZzCUPC_16 dNRdaTXM_16"
                      d="M574,45.5c0,0-22.8,6.7-14,24c0,0,4.4,9.6,36.6,17.4"></path>
</g></svg>
          
          </div>
          <div className="loader-line-w">
            <div className="line-v-box">
              <div className="loader-line"/>
            </div>
            <div className="powered-by">
              <p>{t.POWERED_BY} <span>{t.BET}</span>{t.CONSTRUCT}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
});

LoadingPage.defaultProps = {};

LoadingPage.propTypes = {};

export default LoadingPage;
