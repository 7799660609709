import PredictorApiHandler from '../pedictorWebApiHandler.js';

const END_POINT = {
  prefix : 'Informations',
};

class InfoApiHandler extends PredictorApiHandler{
  constructor(prefix){
    super(prefix);
  }

  getInfo(query) {
    return this.get('', query);
  }
}


const infoApiHandler = new InfoApiHandler(END_POINT.prefix);
export default infoApiHandler;
