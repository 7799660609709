import React from 'react';
import {getMatchDate} from '../../../core/helpers/dateHelpers';

const TeamsInfoRow = ({match:{ homeTeamName, awayTeamName, homeScore, awayScore, date, matchResult}}) => {
  const isActive = num => matchResult === num || matchResult === 0;
  return (
    <div className="teams-info-row">
      <div className="inside-teams-info-row" title={homeTeamName}>
        <p className={`inside-teams-info-row-txt ${isActive(1)? 'active' : ''}`}>{homeTeamName}</p>
      </div>
      <div className="inside-teams-info-row center">
        <p className="inside-teams-info-row-txt active">{homeScore}:{awayScore}</p>
        <p className="inside-teams-info-row-date-txt">{getMatchDate(date)}</p>
      </div>
      <div className="inside-teams-info-row" title={awayTeamName}>
        <p className={`inside-teams-info-row-txt ${isActive(2)? 'active' : ''}`}>{awayTeamName}</p>
      </div>
    </div>
  );
};

export default TeamsInfoRow;
