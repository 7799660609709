import React from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';
import {LEADERBOARD_TABS} from '../../../core/constants/navigationConstants';
import t from '../../../core/staticData/translation';

const leaderBoardTabs = [
  {
    id: LEADERBOARD_TABS.PRIZE,
    name: t.INFORMATION,
  },
  {
    id: LEADERBOARD_TABS.LEADERBOARD,
    name: t.LEADERBOARD,
  }
];

const LeaderBoardTabs = ({ setActiveLeaderBoardTab } )=> {
  const translate = useTranslation();
  return (
    <div className="leaderboard-title">
      <div className="inside-leaderboard-title">
        {leaderBoardTabs.map(tab => (
          <div key={tab.id}
               className="tab-item-of-l-title"
               onClick={() => setActiveLeaderBoardTab(tab.id)}
               title={translate(tab.name)}
          >
            {translate(tab.name)}
          </div>
        ) )}
        <div className="underline-block-of-title">
          <span className="inside-underline-b-of-title" />
        </div>
      </div>
    </div>
  );
}

export default LeaderBoardTabs;
