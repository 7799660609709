 export const MOBILE_TABS = {
  HOME: 'step-1',
  ROUNDS: '',
  TABLES: '',
  LEADERBOARD: 'step-2'
};

export const NAVIGATION_ITEMS = {
  HOME: 0,
  ROUNDS: 1,
  TABLES: 2,
  LEADERBOARD: 3
};

export const LEFT_COLUMN_ACTIVE_TABS = {
  ROUNDS: 0,
  TABLES: 1
};

export const LEADERBOARD_TABS = {
  PRIZE: 0,
  LEADERBOARD: 1
};

