import React from 'react';
import FooterPredictions from './footerPredictions';
import useTranslation from '../../../core/customHooks/useTranslation';
import {getUserId} from '../../../state-management/accessors/lobbyAccessors';
import defaultImage from '../../../images/defaultImage.png';
import { connect } from 'react-redux';
import t from '../../../core/staticData/translation';
import { getImgUrl } from '../../../core/helpers/imgUrl';

const GameFooter = ({ prediction, imageUrl, title, lobbyUserId, firstScoredTeamUrl, fttsTitle, ftts  }) => {

  const translate = useTranslation();
  const isActive = !!prediction?.selectionValues;
  return (
    <div className="bottom-bottom-inside-game-list">
      <div className="inside-bottom-bottom-inside-game-list">
        {isActive ? <FooterPredictions prediction={prediction}
                                       imageUrl={imageUrl}
                                       title={title} /> :
          !lobbyUserId ?
            <div className="bottom-your-prediction">
              <div className="no-prediction">
                <p className="pred-txt" title={translate(t.FIRST_SCORED)}>{translate(t.FIRST_SCORED)}</p>
              </div>
              <div className="bottom-your-prediction-item">
                <div className="right-icons-table-of-bottom-pred">
                  <div className="right-icon-item-of-bottom-pred">
                    {ftts ?
                      <div className="bottom-team-icon-block-of-pred " title={fttsTitle}>
                        <img src={getImgUrl(firstScoredTeamUrl)} alt="" onError={e => e.target.src = defaultImage}/>
                      </div> :
                      <button className="game-btn state-2 inactive" type="button" title={translate(t.NONE)}>
                        <span className="bc-i-noun-ban icon-none"/>
                      </button>}
                  </div>
                </div>
              </div>
            </div>:
          <div className="bottom-your-prediction">
            <div className="no-prediction">
              <p className="pred-txt" title={translate(t.NO_PREDICTIONS)}>{translate(t.NO_PREDICTIONS)}</p>
            </div>
          </div>}
      </div>
      <span className="bottom-line"/>
    </div>
  )
};

const mapStateToProps = state => ({
  lobbyUserId: getUserId(state),
});

export default connect(mapStateToProps)(GameFooter);
