import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';
import {setGlobalColors} from '../../../core/helpers/setGlobalColors';
import {cntrlGetCurrentGlobalRound} from '../../../state-management/actions/partnerActions';
import PartnerModule from '../../../core/moduls/PartnerModul';
import {APP_CONSTANTS} from '../../../core/constants/constants';


class PartnerOperations extends Operations {
  constructor(partnerApiHandler) {
    super();
    this.partnerApiHandler = partnerApiHandler
  }

  async getCurrentGlobalRound(store, action) {
    const response = await this.partnerApiHandler.getCurrentGlobalRound(action.payload);
    return responseErrorCheck(response);
  }

  async getPartnerColors(store, action) {
    const response = await this.partnerApiHandler.getPartnerColors(action.payload);
    responseErrorCheck(response);
    const colors = response.result;
    let dataColorsModel = {};
    colors.forEach(({name, value}) => {
      dataColorsModel[name] = value
    });
    setGlobalColors(dataColorsModel);
  }


  async getPartnerSettings(store, action) {
    const response = await this.partnerApiHandler.getPartnerSettings(action.payload);
    const result = responseErrorCheck(response);
    const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);
    const hasGlobalRound = result.hasGlobalRound;
    if (hasGlobalRound) {
      await store.dispatch(cntrlGetCurrentGlobalRound(partnerId));
    }
    return result;
  }

  async getCompetitions(store, action) {
    const response = await this.partnerApiHandler.getCompetitions(action.payload);
    return responseErrorCheck(response);
  }

}

export default PartnerOperations;
