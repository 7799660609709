import Controller from '../../core/classes/AppController';
import {
  cntrlGetUserInfo,
  rxGetUserInfoDone
} from '../../state-management/actions/usersActions';
import {
  cntrlGetCompetitions,
  cntrlGetCurrentGlobalRound,
  cntrlGetPartnerSettings
} from '../../state-management/actions/partnerActions';
import PartnerModule from '../../core/moduls/PartnerModul';
import {
  cntrlGetGlobalLobby,
  cntrlGetHistory,
  cntrlGetLobby
} from '../../state-management/actions/lobbyActions';
import {
  cntrlCreateLeaderboardConnection, cntrlCreateLiveMatchesConnection,
  cntrlCreatePredictionsConnection, cntrlCreateRoundsConnection
} from '../../state-management/actions/signalRActions';
import {
  cntrlAuthScheme,
  rxSetIsAuth
} from '../../state-management/actions/authActions';
import ApiHandler from '../../core/classes/ApiHandler';
import { cntrlGetTranslations } from '../../state-management/actions/translationActions';
import postMessageService from '../../services/services/PostMessageService';
import { cntrlGetCurrencies } from '../../state-management/actions/currencyActions';
import {APP_CONSTANTS, ENVIRONMENT_TYPE, OPEN_TYPE} from '../../core/constants/constants';
import widgetService from '../../services/services/WidgetService';

class AppController extends Controller {
  constructor(authorizationOperation) {
    super();
    this.authorizationOperation = authorizationOperation
  }

  init = async (store, action) => {
    const openType = PartnerModule.getConfigItem(APP_CONSTANTS.OPEN_TYPE);
    const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);
    let userId = PartnerModule.getConfigItem(APP_CONSTANTS.USERID);
    if (userId < 0) {
      userId = null;
    }
    const widgetData = widgetService.data;
    const roundId = widgetData?.roundId || PartnerModule.getConfigItem(APP_CONSTANTS.ROUNDID);
    const globalRound = PartnerModule.getConfigItem(APP_CONSTANTS.GLOBAL_ROUND);
    if (openType === OPEN_TYPE.REAL && (process.env.REACT_APP_ENV === ENVIRONMENT_TYPE.PRODUCTION || postMessageService.isInIframe())) {
      await store.dispatch(cntrlAuthScheme());
    }

    await Promise.all([
                      store.dispatch(cntrlGetCurrencies()),
                      store.dispatch(cntrlGetPartnerSettings(partnerId)),
                      store.dispatch(cntrlGetTranslations()),
                      globalRound && store.dispatch(cntrlGetGlobalLobby({partnerId, otherUserId: userId, skip: 0, take: 30})),
                      !globalRound && store.dispatch(cntrlGetLobby({ partnerId, roundId, userId })),
                      store.dispatch(cntrlGetHistory({partnerId})),
                      store.dispatch(cntrlCreatePredictionsConnection()),
                      store.dispatch(cntrlCreateLeaderboardConnection()),
                      store.dispatch(cntrlCreateLiveMatchesConnection()),
                      store.dispatch(cntrlCreateRoundsConnection()),
                      store.dispatch(cntrlGetCompetitions(partnerId)),
                      ApiHandler.token && store.dispatch(cntrlGetUserInfo())],
      );
  };

  authScheme = async (store, action) => {
    const auth = await this.authorizationOperation.auth(store, action);
    if (auth instanceof Error) {
      return new Error(auth.message)
    }

    if (auth && !(auth instanceof Error)) {
      store.dispatch(rxSetIsAuth(true));
    }
  };

  authWithUsername = async (store, action) => {
    const auth = await this.authorizationOperation.authWithUsername(store, action);
    if (auth instanceof Error) {
      return new Error(auth.message)
    }

    if (auth && !(auth instanceof Error)) {
      store.dispatch(rxSetIsAuth(true));
      const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);
      store.dispatch(cntrlGetHistory({ partnerId }))
    }
  };

  logout = (store, action) => {
    this.authorizationOperation.logout();
    store.dispatch(rxSetIsAuth(false));
    store.dispatch(rxGetUserInfoDone(null));
  }

}

export default AppController;
