import React from 'react';
import NoneScoreSelectedBlock from './noneScoreSelectedBlock';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation';

const TeamChooseBlockButton = ({ addFtts }) => {
  const translate = useTranslation();
  return (
    <div className="teams-remove-block-bottom-item">
      <button className="game-btn" type="button" onClick={addFtts} title={translate(t.NONE)} tabIndex={-1}>
        <span className="bc-i-noun-ban icon-none" />
      </button>
    </div>
  )
};

export default TeamChooseBlockButton;
