import PredictorWebApiHandler from '../pedictorWebApiHandler';

const END_POINTS = {
  prefix: 'Currencies',
};

class CurrenciesApiHandler extends PredictorWebApiHandler {
  getCurrencies(payload) {
    return this.get('', payload)
  }
}

const currenciesApiHandler = new CurrenciesApiHandler(END_POINTS.prefix);
export default currenciesApiHandler;
