import React, { useContext } from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation';
import { FieldStateContext } from '../../../contexts';
import { TOOLTIP_TARGET_IDS } from '../../../core/constants/userTooltipGuide';
import { ERoundStatuses } from '../../../core/constants/enums';
import { useSelector } from 'react-redux';

const OpenSelectTeamBlock = ({ setActive }) => {
  const { fieldIsDisabled } = useContext(FieldStateContext);
  const translate = useTranslation();

  return (
    <>
      <div className="teams-choose-block-bottom-item">
        <p className="teams-choose-block-bottom-item-txt"
           title={ translate(t.FIRST_TEAM_TO_SCORE) }>{ translate(t.FIRST_TEAM_TO_SCORE) }</p>
      </div>
      <div className="teams-choose-block-bottom-item">
        <button className="game-btn"
                type="button"
                onClick={ () => setActive('active') }
                disabled={ fieldIsDisabled }
                tabIndex={ -1 }
        >
          <span className="bc-i-plus icon-plus"/>
        </button>
      </div>
    </>
  );
};

export default OpenSelectTeamBlock;
