import React, { forwardRef } from 'react';

const ScoreInput = forwardRef(({ name, value, handleChange, readOnly}, ref) => (
  <div className="score-write-bl">
    <input className="score-write-item"
           type="tel"
           pattern="[0-9]*"
           formNoValidate
           inputMode="numeric"
           name={name}
           onChange={handleChange}
           value={value}
           autoComplete="off"
           readOnly={readOnly}
           ref={ref}
    />
  </div>
));

export default ScoreInput;
