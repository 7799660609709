import translationsApiHandler from '../../services/api/translationApiHandler';
import TranslationOperations from '../../services/operations/translationOperations';
import TranslationController from '../../controllers/translationController';
import {TRANSLATION_TYPES} from '../../core/constants/actionTypes';

const translationOperations = new TranslationOperations(translationsApiHandler);
const translationController = new TranslationController(translationOperations);

const translationConfig = {
  [TRANSLATION_TYPES.CNTRL_GET_TRANSLATIONS]: translationController.getTranslations,
};

export default translationConfig;
