import {SIGNAL_R_TYPES} from '../../../core/constants/actionTypes';

export const cntrlCreatePredictionsConnection = payload => ({
  type: SIGNAL_R_TYPES.CREATE_PREDICTIONS_CONNECTION,
  payload,
});

export const cntrlCreateLiveMatchesConnection = payload => ({
  type: SIGNAL_R_TYPES.CREATE_LIVE_MATCHES_CONNECTION,
  payload,
});

export const cntrlCreateLeaderboardConnection = payload => ({
  type: SIGNAL_R_TYPES.CREATE_LEADERBOARD_CONNECTION,
  payload,
});

export const cntrlSetRoundAndUser = payload => ({
  type: SIGNAL_R_TYPES.SET_ROUND_AND_USER,
  payload
});

export const cntrlSetLeaderBoardPosition = payload => ({
  type: SIGNAL_R_TYPES.CNTRL_SET_LEADERBOARD_POSITION,
  payload,
});

export const cntrlClosePredictionsConnection = payload => ({
  type: SIGNAL_R_TYPES.CNTRL_CLOSE_PREDICTIONS_CONNECTION,
  payload,
});

export const cntrlCloseLeaderBoardConnection = payload => ({
  type: SIGNAL_R_TYPES.CNTRL_CLOSE_LEADERBOARD_CONNECTION,
  payload,
});

export const cntrlCreateRoundsConnection = payload => ({
  type: SIGNAL_R_TYPES.CNTRL_CREATE_ROUNDS_CONNECTION,
  payload,
});

export const cntrlSetPartnerId = payload => ({
  type: SIGNAL_R_TYPES.CNTRL_SET_PARTNER_ID,
  payload,
});



