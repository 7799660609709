import React from 'react';

const GameInfoItem = ({ team }) => {
  const {position, name, shortName,  matchPlayed, win, draw, lose, goalsFor, goalsAgainst, points} = team;
  return (
    <div className="game-info item">
      <div className="game-info-cell" title={name}>
        <span className="number-itm">{position}</span>
        {shortName ? shortName : name}
      </div>
      <div className="game-info-cell">{matchPlayed}</div>
      <div className="game-info-cell">
        <div className="small-game-table">
          <div className="small-game-item">
            <span className="number-itm">{win}</span>
          </div>
          <div className="small-game-item">
            <span className="number-itm">{draw}</span>
          </div>
          <div className="small-game-item">
            <span className="number-itm">{lose}</span>
          </div>
        </div>
      </div>
      <div className="game-info-cell">{goalsFor}:{goalsAgainst}</div>
      <div className="game-info-cell">{points}</div>
    </div>
  );
};

GameInfoItem.defaultProps = {
  team: {}
};



export default GameInfoItem;
