import {EMoneyTypes} from './enums';
import t from '../staticData/translation';

export const APP_CONSTANTS = {
  TOKEN: 'token',
  ACCESS: 'access',
  EXTERNAL_GAME_ID: 'gameid',
  PARTNER_ID: 'partnerid',
  IN_IFRAME: 'inIframe',
  LANGUAGE: 'language',
  LAN: 'lan',
  CURRENCY: 'currency',
  USERID: 'userid',
  ROUNDID: 'roundid',
  GLOBAL_ROUND: 'globalround',
  OPEN_TYPE: 'opentype',
  MAINTENANCE_MODE: 'maintenanceMode'
};

export const APP_DEFAULT_VALUES = {
  LANGUAGE_ID : "5cb5a007066b7b62b8e1371b",
  LANGUAGE : 'en',
  PARTNER_ID: 4,
  EXTERNAL_GAME_ID: 1,
  CURRENCY: 'USD'
};

export const SIGNAL_R_CONSTANTS = {
  CONNECTED: 'Connected',
  DISCONNECTED: 'Disconnected'
};

export const ENVIRONMENT_TYPE = {
  PRODUCTION: 'production',
  DEVELOPMENT: 'development'
};

export const OPEN_TYPE = {
  REAL: 'real',
  FUN: 'fun'
};

export const INCLUDE_CREDENTIALS = 'same-origin';

export const PRIZE_TYPES = {
  [EMoneyTypes.real]: '',
  [EMoneyTypes.freeBet]: t.FREEBET,
  [EMoneyTypes.freeSpin]: t.FREESPIN,
};
