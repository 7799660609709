import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Header from '../componentsNew/header';
import '../../styles/main.scss'
import LeftColumn from '../componentsNew/leftColumn';
import Games from '../componentsNew/games';
import RightColumn from '../componentsNew/rightColumn';
import MobileNavigation from '../componentsNew/mobileNavigation';
import HelpSection from '../componentsNew/helpSection';
import { getUserInfo } from '../../state-management/accessors/usersAccessors';
import { getIsAuth } from '../../state-management/accessors/appAccessors';
import { NavigationContext } from '../../contexts';
import { LEADERBOARD_TABS, LEFT_COLUMN_ACTIVE_TABS, MOBILE_TABS, NAVIGATION_ITEMS } from '../../core/constants/navigationConstants';
import { APP_CONSTANTS } from '../../core/constants/constants';
import { MODAL_CONSTANTS } from '../../core/constants/modalConstants';
import useGoogleAnalytics from '../../core/customHooks/useGoogleAnalytics';
import { initHotjar } from '../../core/scripts/hotjar';
import { ENVIRONMENT_TYPE } from '../../core/constants/constants';
import useDidUpdateEffect from '../../core/customHooks/useDidUpdateEffect';
import { verificationErrorObserver } from '../../services/services/ErrorHandler';
import { getPartnerSettings } from '../../state-management/accessors/partnerAccessors';
import { rxSetIsUnderMaintenance } from '../../state-management/actions/maintenanceActions';
import PartnerModule from '../../core/moduls/PartnerModul';
import { getIsUnderMaintenance } from '../../state-management/accessors/maintenanceAccessors';
import Maintenance from '../pages/maintenance';
import Snowfall from 'react-snowfall/lib';
import ShareBlock from '../componentsNew/shareBlock';
import ScreenShot from '../containers/screenshot';
import UserTooltipGuide from '../componentsNew/userTooltipGuide';
import { LS_KEYS } from '../../core/constants/localStorageKeys';
import localStorageService from '../../services/services/LocalStorageService';
import { getRoundStatus } from '../../state-management/accessors/lobbyAccessors';

const App = ({ auth, userInfo, settings, setUnderMaintenance, underMaintenance, roundStatus }) => {

  const [ activeTab, setActiveTab ] = useState(MOBILE_TABS.HOME);
  const [ activeNavItem, setActiveNavItem ] = useState(NAVIGATION_ITEMS.HOME);
  const [ leftColumnActiveTab, setLeftColumnActiveTab ] = useState(LEFT_COLUMN_ACTIVE_TABS.ROUNDS);
  const [ activeLeaderBoardTab, setActiveLeaderBoardTab ] = useState(
    roundStatus ? LEADERBOARD_TABS.LEADERBOARD : LEADERBOARD_TABS.PRIZE
  );
  const [ modalState, setModalState ] = useState();
  const getIsGuideDisplayed = () => (
    auth ? (userInfo.id ? userInfo.isToolTipDisplayed : true) : localStorageService.get(LS_KEYS.IS_TOOLTIP_DISPLAYED)
  );
  const [ isGuideDisplayed, setIsGuideDisplayed ] = useState(() => getIsGuideDisplayed());
  const { initGoogleAnalytics } = useGoogleAnalytics(true);

  const [ showSnowfallForUnauthorizedUser, setShowSnowfallForUnauthorizedUser ] = useState(true);
  const [ showSnowfallForAuthorizedUser, setShowSnowFallForAuthorizedUser] = useState(!!userInfo.chistmasMoodEnabled);

  const partnerHasChristmasMood = settings.hasChristmasMood;

  const showSnowFall = partnerHasChristmasMood && (userInfo.id ? showSnowfallForAuthorizedUser: showSnowfallForUnauthorizedUser);

  useDidUpdateEffect(() => {
    setIsGuideDisplayed(() => getIsGuideDisplayed());
    setShowSnowFallForAuthorizedUser(!!userInfo.chistmasMoodEnabled)
  },[ userInfo.id]);

  const handleFullScreenChange = (e) => {
    if(e.keyCode === 122) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    initGoogleAnalytics();
    if (process.env.NODE_ENV === ENVIRONMENT_TYPE.PRODUCTION) {
      initHotjar();
    }
    const errorSubscribeId = verificationErrorObserver.subscribe(setModalState);
    document.addEventListener('keydown', handleFullScreenChange);
    return () => {
      document.removeEventListener('keydown', handleFullScreenChange);
      verificationErrorObserver.unsubscribe(errorSubscribeId);
    }
  }, []);

  useEffect(() => {
    if(userInfo.id && !userInfo.hasNickname) {
      setModalState(MODAL_CONSTANTS.USER_PROFILE);
    }
  }, [userInfo.id]);

  useEffect(() => {
    const isMaintenanceMode = PartnerModule.getConfigItem(APP_CONSTANTS.MAINTENANCE_MODE) !== 'false';
    if (isMaintenanceMode && settings.underMaintenance) {
      setUnderMaintenance(true);
    }
  }, [settings.underMaintenance]);

    return (
        <>
        {
            underMaintenance ? <Maintenance /> :
                <div className="main-wrapper" id="mainWrapper">
                    {
                        showSnowFall &&
                        <Snowfall style={{zIndex: 1000}} snowflakeCount={70} />
                    }
                  <Header
                      setModalState={setModalState}
                      showSnowfallForUnauthorizedUser={showSnowfallForUnauthorizedUser}
                      setShowSnowfallForUnauthorizedUser={setShowSnowfallForUnauthorizedUser}
                      showSnowfallForAuthorizedUser={showSnowfallForAuthorizedUser}
                      setShowSnowFallForAuthorizedUser={setShowSnowFallForAuthorizedUser}

                  />
                  <NavigationContext.Provider
                      value={{
                          activeTab,
                          setActiveTab,
                          activeNavItem,
                          setActiveNavItem,
                          leftColumnActiveTab,
                          activeLeaderBoardTab,
                          setLeftColumnActiveTab,
                          setActiveLeaderBoardTab
                      }}>
                    {
                      !isGuideDisplayed &&
                      <UserTooltipGuide
                        onComplete={() => setIsGuideDisplayed(true)}
                        preventHotKeys={modalState}
                      />
                    }
                    <div className="content">
                      <div className= {`content-inside-wrapper ${activeTab}`} >{/*left-menu [default],*/}
                          {/*for center-menu [add class step-1]*/}
                          {/*for right-menu [add class step-2]*/}
                        <LeftColumn
                          isDisabled={!isGuideDisplayed}
                        />
                        <Games
                          isActionsDisabled={!isGuideDisplayed}
                        />
                        <RightColumn
                          isDisabled={!isGuideDisplayed}
                        />
                      </div>
                    </div>
                    <div className="footer" >
                      <div id="bet-button-container" />
                      <MobileNavigation />
                    </div>

                  </NavigationContext.Provider>
                  <HelpSection
                      modalState={modalState}
                      setModalState={setModalState}
                  />
                  <ScreenShot node={<ShareBlock/>} />
                </div>
        }
        </>
    );
};


const mapStateToProps = state => ({
  roundStatus: getRoundStatus(state),
  userInfo:getUserInfo(state),
  auth: getIsAuth(state),
  settings: getPartnerSettings(state),
  underMaintenance: getIsUnderMaintenance(state)
});

const mapDispatchToProps = dispatch => ({
  setUnderMaintenance: value => dispatch(rxSetIsUnderMaintenance(value))
});

App.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);

