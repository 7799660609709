import React from 'react';
import avatar1 from '../../../images/avatar1.png';
import avatar2 from '../../../images/avatar2.png';
import avatar3 from '../../../images/avatar3.png';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation';

const avatarsArr = [avatar2, avatar3, avatar1];

const NicknameSection = ({ setModalState,
                           user: {nickname, hasNickname, avatar},
})=> {
  const translate = useTranslation();
  return (
    <>
      <div className="user-icon-b" onClick={setModalState}>
        <div className="user-image-block" title={translate(t.EDIT_PROFILE)}>
          <img src={avatarsArr[avatar || 0]} alt="" />
        </div>
      </div>
      <div className="head-txt-b">
        <p className="head-txt-itm" title={hasNickname ? nickname : ''}>{hasNickname ? nickname : ''}</p>
        {/*<p className="head-txt-itm" title={`${points} ${translate(t.PTS)}`}>{points} {translate(t.PTS)}</p>*/}
      </div>
    </>
  );
};

export default NicknameSection;

