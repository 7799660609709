import React, {useContext} from 'react';
import defaultImage from '../../../images/defaultImage.png';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation';
import { getImgUrl } from '../../../core/helpers/imgUrl';
import {FieldStateContext} from '../../../contexts';
import { noop } from '../../../core/helpers/noop';

const TeamSelectedBlock = ({ imageUrl, setActive, title, onDisableClick = noop }) => {
  const { fieldIsDisabled } = useContext(FieldStateContext);
  const translate = useTranslation();

  const handleClick= () => {
    if (fieldIsDisabled) {
      onDisableClick(fieldIsDisabled);
      return;
    }
    setActive('active');
  };

  return (
    <>
      <div className="team-choose-bottom-item-img">
        <p className="team-choose-bottom-txt" title={translate(t.FIRST_TEAM_TO_SCORE)}>{translate(t.FIRST_TEAM_TO_SCORE)}</p>
      </div>
      <div className="team-choose-bottom-item-img">
        <div className="bottom-right-img-b">
          <img src={getImgUrl(imageUrl)} alt="" onClick={handleClick} title={title} onError={e => e.target.src = defaultImage}/>
        </div>
      </div>
    </>
  );
};

export default TeamSelectedBlock;
