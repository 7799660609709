import PredictorApiHandler from '../pedictorWebApiHandler.js';

const END_POINT = {
  prefix : 'Round',
  distribution: 'distribution',
  bet: 'bet'
};

class RoundApiHandler extends PredictorApiHandler{
  constructor(prefix){
    super(prefix);
  }

  getDistribution({id, partnerId}) {
    return this.get(`${id}/${END_POINT.distribution}`, { partnerId });
  }

  bet(payload) {
    return this.post(END_POINT.bet, payload);
  }

}


const roundApiHandler = new RoundApiHandler(END_POINT.prefix);
export default roundApiHandler;
