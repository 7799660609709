import Controller from '../../core/classes/AppController';
import {
  rxGetCompetitionsDone,
  rxGetCompetitionsPending,
  rxGetCurrentGlobalRoundDone,
  rxGetCurrentGlobalRoundPending,
  rxGetPartnerColorsDone,
  rxGetPartnerColorsPending,
  rxGetPartnerSettingsDone,
  rxGetPartnerSettingsPending
} from '../../state-management/actions/partnerActions';

class PartnerController extends Controller{
  constructor(partnerOperations) {
    super();
    this.partnerOperations = partnerOperations;
  }

  getCurrentGlobalRound = async (store, action) => {
    store.dispatch(rxGetCurrentGlobalRoundPending());
    const response = await this.partnerOperations.getCurrentGlobalRound(store, action);
    store.dispatch(rxGetCurrentGlobalRoundDone(response));
  };

  getPartnerColors = async (store, action) => {
    store.dispatch(rxGetPartnerColorsPending())
    await this.partnerOperations.getPartnerColors(store, action);
    store.dispatch(rxGetPartnerColorsDone(true));
  };

  getPartnerSettings = async (store, action) => {
    store.dispatch(rxGetPartnerSettingsPending());
    const response = await this.partnerOperations.getPartnerSettings(store, action);
    store.dispatch(rxGetPartnerSettingsDone(response));
  };

  getCompetitions = async (store, action) => {
    store.dispatch(rxGetCompetitionsPending());
    const response = await this.partnerOperations.getCompetitions(store, action);
    store.dispatch(rxGetCompetitionsDone(response));
  }


}

export default PartnerController;
