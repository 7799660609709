import React, { useState, useRef } from 'react';
import { cntrlGetUserInfo, cntrlPostUserInfo } from '../../../state-management/actions/usersActions';
import { connect } from 'react-redux';
import useTranslation from '../../../core/customHooks/useTranslation';
import {cntrlAuthWithUsername} from '../../../state-management/actions/authActions';
import PartnerModule from '../../../core/moduls/PartnerModul';
import {cntrlGetLobby} from '../../../state-management/actions/lobbyActions';
import {
  cntrlCloseLeaderBoardConnection,
  cntrlClosePredictionsConnection,
} from '../../../state-management/actions/signalRActions';
import visibilityOff from '../../../images/visibility_off-24px.svg';
import visibilityOn from '../../../images/visibility-24px.svg';
import {APP_CONSTANTS} from '../../../core/constants/constants';
import {combineQuery} from '../../../core/helpers/combineQuery';
import {parseQuery} from '../../../core/helpers/parseQuery';
import {useHistory, useLocation} from 'react-router';
import t from '../../../core/staticData/translation';

const SignInPopup =({ login,
                      setModalState,
                      getUserInfo,
                      getLobby,
                      closePredictionsConnection,
                      closeLeaderBoardConnection,
                    }) => {
  const translate = useTranslation();
  const ref = useRef(null);
  const history = useHistory();
  const location = useLocation();

  const [ values, setValues ] = useState({
    UserName: 'PKVB2',
    Password: 'PKVB2',
  });

  const  [ passwordType, setPasswordType ] = useState('password');

  const handleChange = e => setValues({...values, [e.target.name] : e.target.value});

  const goToHomePage = () => {
    const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);
    getLobby({partnerId});
    history.push(combineQuery({...parseQuery(location.search),
      [APP_CONSTANTS.ROUNDID]: null,
      [APP_CONSTANTS.USERID]: null,
      [APP_CONSTANTS.GLOBAL_ROUND]: null},
      ));
  };


  const handleSubmitButtonClick = (e) => {
    e.preventDefault();
    if(values.UserName && values.Password) {
      login(values).then(res =>{ if(!res){
        goToHomePage();
        getUserInfo();
        setModalState('');
        closePredictionsConnection();
        closeLeaderBoardConnection();
      }})
    }
  };

  return (
    <div className="wr-user-popup-layout"  style={{maxHeight: 380}} id='userPopup' ref={ref}>
      <div className="user-popup-layout">
        <div className="top-of-user-popup-layout">
          <div className="popup-profile-header">
            <p className="popup-profile-name" title={translate(t.SIGN_IN)}>{translate(t.SIGN_IN)}</p>
            <div className="popup-profile-header-icon"
                 onClick={()=>setModalState('')}
            >
              <span className="bc-i-close"/>
            </div>
          </div>
        </div>
        <div className="bottom-of-user-popup-layout">
          <div className="scroll-wr">
            <div className="popup-profile-content">
              <form className="popup-profile-nichname-d" onSubmit={handleSubmitButtonClick}>
                <div className="popup-profile-nichname-input">
                  <input type="text"
                         className="input-nichname"
                         spellCheck="false"
                         placeholder={translate(t.LOGIN)}
                         title={translate(t.LOGIN)}
                         name='UserName'
                         value={values.UserName}
                         onChange={handleChange}
                  />
                  <div className="icon-block-inp">
                    <span className="bc-i-lock"/>
                  </div>
                </div>
                <div className="popup-profile-nichname-d">
                  <div className="popup-profile-nichname-input">
                    <input type={passwordType}
                           className="input-nichname"
                           spellCheck="false"
                           placeholder={translate(t.PASSWORD)}
                           title={translate(t.PASSWORD)}
                           name='Password'
                           value={values.Password}
                           onChange={handleChange}
                    />
                    {values.Password && <div className="icon-block-inp">
                      {passwordType === 'password' ?
                        <img
                          src={visibilityOn}
                          alt=""
                          onClick={()=>setPasswordType('text')}
                        /> :
                        <img
                          src={visibilityOff}
                          alt=""
                          onClick={()=>setPasswordType('password')}
                        />
                      }
                    </div>}
                  </div>
                </div>
                <div className="popup-profile-button-d">
                  <button
                    className='popup-profile-button'
                    title={translate(t.SIGN_IN)}
                  >
                    {translate(t.SIGN_IN)}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
  postUserInfo: payload => dispatch(cntrlPostUserInfo(payload)),
  login: payload => dispatch(cntrlAuthWithUsername(payload)),
  getUserInfo: () => dispatch(cntrlGetUserInfo()),
  getLobby: (payload) => dispatch(cntrlGetLobby(payload)),
  closePredictionsConnection: () => dispatch(cntrlClosePredictionsConnection()),
  closeLeaderBoardConnection: () => dispatch(cntrlCloseLeaderBoardConnection()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInPopup);
