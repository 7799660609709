import { GA_TRACKING_ID } from '../constants/googleAnalyticsConstants';
import useDidUpdateEffect from './useDidUpdateEffect';
import ReactGA from 'react-ga';

/**
 * @name useGoogleAnalytics
 * @param {boolean} trackPages true only for initialization
 * @returns `emitEvent` and `initGoogleAnalytics` methods
 */
const useGoogleAnalytics = (trackPages = false) => {

    useDidUpdateEffect(() => {
        if (trackPages) {
            _trackPageView();
        }
    }, [window.location.href]);
    
    const initGoogleAnalytics = () => {
        ReactGA.initialize(GA_TRACKING_ID);
        _trackPageView();
    };

    const emitEvent = (event) => {
        ReactGA.event(event);
    };

    const _trackPageView = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    };

    return {
        emitEvent,
        initGoogleAnalytics
    };

};

export default useGoogleAnalytics;
