import Controller from '../../core/classes/AppController';
import {rxGetUserInfoDone, rxGetUserInfoPending, rxSetNickNameError} from '../../state-management/actions/usersActions';

class UsersController extends Controller {
  constructor(userOperations) {
    super();
    this.userOperations = userOperations;
  }

  getUserInfo = async (store, action) => {
    store.dispatch(rxGetUserInfoPending());
    const response = await this.userOperations.getUserInfo(store, action);
    store.dispatch(rxGetUserInfoDone(response));
  };

  postUserInfo = async (store, action) => {
    await this.userOperations.postUserInfo(store, action);
  };

  changeChristmasMood = async(store, action) => {
    await this.userOperations.changeChristmasMood(store, action);
  };

  checkUsername = async (store, action) => {
    return  await this.userOperations.checkUsername(store, action);
  };

  generateNickname = async(store, action) => {
    return await this.userOperations.generateNickname();
  }

}

export default UsersController;
