import Controller from '../../core/classes/AppController';

class PredictionsController extends Controller {
  constructor(predictionsOperation) {
    super();
    this.predictionsOperation = predictionsOperation;
  }

  getSmartPick =  async(store, action) => {
     return await this.predictionsOperation.getSmartPick(store, action);
  };
}

export default PredictionsController;
