import ApiHandler from '../../core/classes/ApiHandler';
import { TRANSLATION_HEADERS } from '../../core/constants/headers';
import { TRANSLATIONS_API } from '../../core/constants/urls';

class PredictorTranslationApiHandler extends ApiHandler {
  constructor(prefix) {
    super(TRANSLATIONS_API, prefix, '', TRANSLATION_HEADERS)
  }

  _checkResponse = (response) => {
    return response;
  };

  _createQuery(queryFields) {
    if (!queryFields) {
      return ''
    }

    if (typeof queryFields === 'string' || typeof queryFields === 'number') {
      return `${queryFields}`;
    }

    if (Array.isArray(queryFields)) {
      let query = '?';
      queryFields.forEach((queryField, index) => {
        if (index) {
          query += `&${queryField.key}=${queryField.value}`
        } else {
          query += `${queryField.key}=${queryField.value}`
        }
      });
      return query;
    }

    if (Object.keys(queryFields).length) {
      let searchQuery = '';
      for (let key in queryFields) {
        searchQuery = `${searchQuery}&${key}=${queryFields[key]}`
      }
      return `?${searchQuery.substring(1)}`;
    }
    return;
  }

  get(endPoint = '', queryFields = '') {
    return super.get(`${this.domain}/${this.prefix}/${endPoint ? endPoint + '/' : ''}${this._createQuery(queryFields)}`).then(this._checkResponse.bind(this));
  }

  post(endPoint, formData = {}, queryFields = '') {
    return super.post(`${this.domain}/${this.prefix}/${endPoint ? endPoint + '/' : ''}${this._createQuery(queryFields)}`, formData).then(this._checkResponse.bind(this));
  }


  put(endPoint, formData, queryFields = '',) {
    return super.put(`${this.domain}/${this.prefix}/${endPoint}${this._createQuery(queryFields)}`, formData).then(this._checkResponse.bind(this));
  }

  delete(endPoint, queryFields = '') {
    return super.delete(`${this.domain}/${this.prefix}/${endPoint}${this._createQuery(queryFields)}`).then(this._checkResponse.bind(this));
  }
}

export default PredictorTranslationApiHandler;
