import React from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation';
import { SelectionTypes } from '../../../core/constants/enums';

const getDetails = predictionResultDetails => {
  const details = {};
  predictionResultDetails.forEach(item => {
    details[SelectionTypes[item.selectionType]] = item.point;
  });
  return details;
};

const PointDetails = ({ setShowBackSpace, predictionResult}) => {

  const details = predictionResult ? getDetails(predictionResult.predictionResultDetails): null;

  const translate = useTranslation();
  return (
    <>
      <div className="for-top-of-inside-game-list-history-wr">
        <div className="top-of-last-list">
          <div className="lef-icon-bl-of-top-last-l" onClick={()=>setShowBackSpace(false)}>
            <span className="bc-i-flip top-game-i-icon"/>
          </div>
          <div className="for-text-of-top-of-last-list">
            <p className="text-of-top-of-last-list" title={translate(t.POINT_DETAILS)}>{translate(t.POINT_DETAILS)}</p>
          </div>
        </div>
      </div>
      <div className="for-bottom-of-inside-game-list-history-wr">
        <div className="inside-for-bottom-of-inside-game-list-history-wr">
          <div className="top-inside-for-bottom-of-inside-game-list-history-wr">
            <div className="body-wr-of-last-game-list">{/*add class step[1-3]*/}
              <div className="top-of-body-wr-of-last-game-list"/>
              <div className="bottom-bottom-inside-game-list">
                <div className="bottom-bottom-inside-game-list-container">
                  <div >
                    <div className="point-rows-wr">
                      <div className="point-row">
                        <div className="point-item" title={translate(t.CORRECT_SCORE)}>{translate(t.CORRECT_SCORE)}</div>
                        <div className="point-item" title={`${predictionResult? details.CorrectScore : 0} ${translate(t.PTS)}`}>{predictionResult? details.CorrectScore : 0} {translate(t.PTS)}</div>
                      </div>
                      <div className="point-row">
                        <div className="point-item" title={translate(t.OUTCOME)}>{translate(t.OUTCOME)}</div>
                        <div className="point-item" title={`${predictionResult? details.MatchResult: 0} ${translate(t.PTS)}`}>{predictionResult? details.MatchResult: 0} {translate(t.PTS)}</div>
                      </div>
                      <div className="point-row">
                        <div className="point-item" title={translate(t.GOAL_DIFFERENCE)}>{translate(t.GOAL_DIFFERENCE)}</div>
                        <div className="point-item" title={`${predictionResult? details.AsianHandicap : 0} ${translate(t.PTS)}`}>{predictionResult? details.AsianHandicap : 0} {translate(t.PTS)}</div>
                      </div>
                      <div className="point-row">
                        <div className="point-item" title={translate(t.NUMBER_OF_GOALS)}>{translate(t.NUMBER_OF_GOALS)}</div>
                        <div className="point-item" title={`${predictionResult? details.TotalGoals: 0} ${translate(t.PTS)}`}>{predictionResult? details.TotalGoals: 0} {translate(t.PTS)}</div>
                      </div>
                      <div className="point-row">
                        <div className="point-item" title={translate(t.FIRST_TEAM_TO_SCORE)}>{translate(t.FIRST_TEAM_TO_SCORE)}</div>
                        <div className="point-item" title={`${predictionResult? details.FirstTeamToScore: 0} ${translate(t.PTS)}`}>{predictionResult? details.FirstTeamToScore: 0} {translate(t.PTS)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-inside-for-bottom-of-inside-game-list-history-wr">
            <div className="total-point-bottom-wr">
              <div className="total-point-bottom-block">
                <div className="total-point-bottom-item" title={translate(t.TOTAL_POINTS)}>{translate(t.TOTAL_POINTS)}</div>
                <div className="total-point-bottom-item" title={`${predictionResult? predictionResult.sumPoint: 0} ${translate(t.PTS)}`}>{predictionResult? predictionResult.sumPoint: 0} {translate(t.PTS)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default PointDetails;
