import AuthorizationOperation from '../../services/operations/authOperation';
import AppController from '../../controllers/app';
import authApiHandler from '../../services/api/authApiHandler';
import {APP_ACTION_TYPES, AUTH_ACTION_TYPES} from '../../core/constants/actionTypes';

/*** Operations*/
const authOperation = new AuthorizationOperation(authApiHandler);

/*** Controller*/
const appController = new AppController(authOperation);

const appConfig = {
  [APP_ACTION_TYPES.APP_INIT]: appController.init,
  [AUTH_ACTION_TYPES.CNTRL_AUTH_SCHEME]: appController.authScheme,
  [AUTH_ACTION_TYPES.CNTRL_AUTH_WITH_USERNAME]: appController.authWithUsername,
  [AUTH_ACTION_TYPES.CNTRL_LOG_OUT]: appController.logout,
};

export default appConfig
