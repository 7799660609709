import React from 'react';
import { toHTML } from '../../../core/helpers/richText';

const InfoBlock = ({ title, description }) => {
  return (
    <div className="d-about-game">
      <bdo>  <p className="s-about" dangerouslySetInnerHTML={toHTML(title)} /> </bdo>
      <bdo>  <p className="p-about"  dangerouslySetInnerHTML={toHTML(description)} /> </bdo>

    </div>
  )
};

export default InfoBlock;
