import React from 'react';
import TableItem from './tableItem';
import { connect } from 'react-redux';
import {getCompetitionsTables} from '../../../state-management/accessors/statisticsAccessors';
import TableTitle from './tableTitle';
import useGoogleAnalytics from '../../../core/customHooks/useGoogleAnalytics';
import { GA_CATEGORIES, GA_ACTIONS } from '../../../core/constants/googleAnalyticsConstants';
import {compose} from 'redux';
import withPartnerSettings from '../../../hocs/withPartnerSettingsHoc';
import {PARTNER_SETTINGS_CONSTANTS} from '../../../core/constants/partnerSettingsConstants';
import PartnerModule from '../../../core/moduls/PartnerModul';
import {APP_CONSTANTS} from '../../../core/constants/constants';
import {
  cntrlGetCompetitionTable,
  cntrlGetCompetitionsTables
} from '../../../state-management/actions/statisticsActions';
import {getCompetitions} from '../../../state-management/accessors/partnerAccessors';

const Tables = ({ competitionsTables: {data: competitionsTables}, competitions, getCompetitionTable }) => {

  const [active, setActive ] = React.useState(null);
  const { emitEvent } = useGoogleAnalytics();

  const setActiveTable = id => {
    if(id === active) {
      setActive(null);
    } else {
      setActive(id);
      !competitionsTables[id] && getCompetitionTable(id);
      emitTableStatisticsToGA();
    }
  };

  const emitTableStatisticsToGA = () => {
    emitEvent({
      category: GA_CATEGORIES.USAGE,
      action: GA_ACTIONS.TABLE_STATISTICS
    });
  };

  return (
    <div className="table-container">
      <div className="scroll-wr">
        <div className="tables-wr">
          {
            competitions.map(competition => {
              const competitionTable = competitionsTables[competition.id];
              return (
                <div key={competition.id}  className={`table-item ${active === competition.id ? 'active' : ''}`}>
                  <TableTitle
                    name={competition.name}
                    logoUrl={competition.logoUrl}
                    externalLogoUrl={competition.statisticLogoUrl}
                    setActive={() => setActiveTable(competition.id)}
                  />
                  {
                    !!competitionTable &&
                    competitionTable.groups.length === 1 &&
                    <TableItem table={{...competitionTable.groups[0], name: undefined}} />
                  }

                  {
                    !!competitionTable &&
                    competitionTable.groups.length > 1 &&
                      competitionTable.groups.map((group, i) => <TableItem table={group} key={`${group.name}${i}`}/>)
                  }

                </div>
              )
            })
          }

        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  competitionsTables: getCompetitionsTables(state),
  competitions: getCompetitions(state),
});

const mapDispatchToProps = dispatch => ({
  getCompetitionTable: (id) => dispatch(cntrlGetCompetitionTable(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPartnerSettings(PARTNER_SETTINGS_CONSTANTS.HAS_LEAGUE_STATISTIC),
)(Tables);

