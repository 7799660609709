import React, { useRef } from 'react';
import Prizes from './prizes';
import UserPopup from './userPopup';
import PopupHeader from './popupHeader';
import Information from './information';
import SignInPopup from './signInPopup';
import {MODAL_CONSTANTS} from '../../../core/constants/modalConstants';
import VerificationPopup from './verifyPopup';

const HelpSection = ({ modalState, setModalState }) => {
  const showPrizesSection = modalState === MODAL_CONSTANTS.PRIZES;
  const showInformationSection = modalState === MODAL_CONSTANTS.INFORMATION;
  const showUserProfile = modalState === MODAL_CONSTANTS.USER_PROFILE;
  const showSignInPopup = modalState === MODAL_CONSTANTS.SIGN_IN_POPUP;
  const showVerifyPopup = modalState === MODAL_CONSTANTS.VERIFY_POPUP;
  const ref = useRef(null);

  return (
    <div className={`popup-wrapper ${modalState && 'active'}`}>{/*add class active*/}
      <div className="inside-popup-wrapper">
        <div className="for-inside-popup-wrapper" ref={ref} >
          {(showPrizesSection || showInformationSection) &&
          <div className="wr-popup-layout">
            <div className="popup-layout">
              <PopupHeader title={modalState} setModalState={setModalState} />
              <div className="bottom-block-of-popup">
                <div className="scroll-wr">
                  {showPrizesSection && <Prizes />}
                  {showInformationSection && <Information />}
                </div>
              </div>
            </div>
          </div>}
          {showUserProfile && <UserPopup setModalState={setModalState} />}
          {showSignInPopup && <SignInPopup setModalState={setModalState}/>}
          {showVerifyPopup && <VerificationPopup setModalState={setModalState}/>}
        </div>
      </div>
    </div>
  );
};
export default HelpSection;
