import PredictorWebApiHandler from '../pedictorWebApiHandler';

const END_POINTS = {
  prefix: 'Partner',
  settings: 'settings',
  currentGlobalRound: 'currentGlobalRound',
  palette: 'palette',
  competitions: 'competitions',
};

class PartnerApiHandler extends PredictorWebApiHandler{

  getCurrentGlobalRound(id) {
    return this.get(`${id}/${END_POINTS.currentGlobalRound}`)
  }

  getPartnerColors(id) {
    return this.get(`${id}/${END_POINTS.palette}`);
  }

  getPartnerSettings(partnerId) {
    return this.get(`${partnerId}/${END_POINTS.settings}`);
  }

  getCompetitions(partnerId){
    return this.get(`${partnerId}/${END_POINTS.competitions}`)
  }
}

const partnerApiHandler = new PartnerApiHandler(END_POINTS.prefix);
export default partnerApiHandler;
