import Controller from '../../core/classes/AppController';
import {rxGetInfoDone, rxGetInfoPending} from '../../state-management/actions/infoActions';


class InfoController extends Controller {
  constructor(infoOperations) {
    super();
    this.infoOperations = infoOperations;
  }

  getInfo = async (store, action)=>{
    store.dispatch(rxGetInfoPending());
    const response = await this.infoOperations.getInfo(store, action);
    store.dispatch(rxGetInfoDone(response))
  }
}

export default InfoController;
