import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';

class PredictionsOperation extends Operations {
  constructor(predictionsApiHandler) {
    super();
    this.predictionsApiHandler = predictionsApiHandler;
  }

  async getSmartPick(store, action) {
    const response = await this.predictionsApiHandler.getSmartPick(action.payload);
    return responseErrorCheck(response);
  }
}

export default PredictionsOperation;
