import Controller from '../../core/classes/AppController';
import {rxGetCurrenciesDone, rxGetCurrenciesPending} from '../../state-management/actions/currencyActions';

class CurrenciesController extends Controller {
  constructor(currenciesOperations) {
    super();
    this.currenciesOperations = currenciesOperations;
  }

  getCurrencies = async(store, action) => {
    store.dispatch(rxGetCurrenciesPending());
    const response = await this.currenciesOperations.getCurrencies(store, action);
    store.dispatch(rxGetCurrenciesDone(response));
  }
}

export default CurrenciesController;

