import { STATISTICS_TYPES } from '../../core/constants/actionTypes';

export const cntrlGetCompetitionTable = payload => {
  return {
    type: STATISTICS_TYPES.CNTRL_GET_COMPETITION_TABLE,
    payload,
  }
};

export const cntrlGetCompetitionsTables = payload => {
  return {
    type: STATISTICS_TYPES.GET_COMPETITIONS_TABLES.CNTRL,
    payload,
  }
};
export const rxGetCompetitionsTablesDone = payload => {
  return {
    type: STATISTICS_TYPES.GET_COMPETITIONS_TABLES.DONE,
    payload,
  }
};
export const rxGetCompetitionsTablesPending = payload => {
  return {
    type: STATISTICS_TYPES.GET_COMPETITIONS_TABLES.PENDING,
    payload,
  }
};

export const cntrlGetMatchStat = payload => ({
  type: STATISTICS_TYPES.GET_MATCH_STAT.CNTRL,
  payload
});

export const rxGetMatchStatPending = payload => ({
  type: STATISTICS_TYPES.GET_MATCH_STAT.PENDING,
  payload
});

export const rxGetMatchStatDone = payload => ({
  type: STATISTICS_TYPES.GET_MATCH_STAT.DONE,
  payload
});

