import PredictorWebApiHandler from '../pedictorWebApiHandler';
import PartnerModule from '../../../core/moduls/PartnerModul';
import { APP_CONSTANTS } from '../../../core/constants/constants';

const END_POINT = {
  prefix: 'Token',
  refresh: 'refresh',
};

class AuthApiHandler extends PredictorWebApiHandler {
  constructor(prefix) {
    super(prefix)
  }

  auth() {
    const casinoToken = PartnerModule.getConfigItem(APP_CONSTANTS.TOKEN);
    const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);
    return this.post('', {
      authScheme: 0,
      passwordHash: casinoToken, 
      partnerId: partnerId
    });
  }

  authWithUsername(payload){
    const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);

    return this.post('', {
      authScheme: 0,
      PartnerId: partnerId,
      UserName: payload.UserName,
      Password: payload.Password,
    })
  }


  authRefresh() {
    return this.post(END_POINT.refresh,'')
  }

}

const authApiHandler = new AuthApiHandler(END_POINT.prefix);
export default authApiHandler;
