import {CURRENCY_TYPES} from '../../core/constants/actionTypes';

export const cntrlGetCurrencies = payload => ({
  type: CURRENCY_TYPES.CNTRL_GET_CURRENCIES,
  payload,
});

export const rxGetCurrenciesPending = payload => ({
  type: CURRENCY_TYPES.RX_GET_CURRENCIES_PENDING,
  payload,
});

export const rxGetCurrenciesDone = payload => ({
  type: CURRENCY_TYPES.RX_GET_CURRENCIES_DONE,
  payload,
});
