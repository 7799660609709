import {INFO_TYPES} from '../../core/constants/actionTypes';

export const cntrlGetInfo = payload => ({
  type: INFO_TYPES.GET_INFO.CNTRL,
  payload,
});

export const rxGetInfoPending = payload => ({
  type: INFO_TYPES.GET_INFO.PENDING,
  payload,
});

export const rxGetInfoDone = payload => ({
  type: INFO_TYPES.GET_INFO.DONE,
  payload,
});
