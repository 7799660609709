import ErrorHandler from '../../core/helpers/ErrorHandler';
import { verificationErrorObserver } from '../../services/services/ErrorHandler';
import { MODAL_CONSTANTS } from '../../core/constants/modalConstants';
import { PREDICTION_TYPES } from '../../core/constants/actionTypes';
import Toaster from '../../view/componentsNew/notification';
import { NOTIFICATION_TYPES } from '../../core/constants/notificationTypes';

const handleException = (response, actionType) => {
  if (response instanceof Error) {
    if (response.hasOwnProperty(ErrorHandler.errorConstants.message)) {
      if(response[ErrorHandler.errorConstants.message] !== ErrorHandler.errorCodesWithErrorMessage[ErrorHandler.errorCodesEnum.unverified] &&
        response[ErrorHandler.errorConstants.message] !== ErrorHandler.errorCodesWithErrorMessage[ErrorHandler.errorCodesEnum.duplicateRequest] &&
        response.name !== ErrorHandler.errorNames.abortError
      ) {
        Toaster.alert({
          type: NOTIFICATION_TYPES.ERROR,
          message: response.message
        })
      } else if (response[ErrorHandler.errorConstants.message] === ErrorHandler.errorCodesWithErrorMessage[ErrorHandler.errorCodesEnum.unverified] &&
                 actionType !== PREDICTION_TYPES.CNTRL_REMOVE_PREDICTION &&
                 actionType !== PREDICTION_TYPES.CNTRL_REMOVE_ALL_PREDICTIONS) {
        verificationErrorObserver.setState(MODAL_CONSTANTS.VERIFY_POPUP);
      }
      return {
        error: response.message,
      };
    }
  }
  return response;
};

const handleAtomicExceptions = err => {
  console.error(err);
};

/**
 * This middleware declares if action for controller or for reducer
 * @param configs
 */
const controllerMiddleware = configs => state => next => action => {
  if (configs[action.type] && typeof configs[action.type] === 'function') {
    try {
      let result = configs[action.type](state, action);
      if (result instanceof Promise) {
        result = result.catch(error => handleException(error, action.type));
      }
      return result;
    } catch ( err ) {
      handleAtomicExceptions(err);
    }
  } else {
    return next(action);
  }
};

export default controllerMiddleware;
