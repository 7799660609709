import React from 'react';
import {toHTML} from '../../../core/helpers/richText';

const PrizeInfoBlock = ({ title, description }) => (
  <div className="d-about-game ab-g">
    <p className="s-about" dangerouslySetInnerHTML={toHTML(title)} />
    <p className="p-pl-about p-pl-about-f" dangerouslySetInnerHTML={toHTML(description)} />
  </div>
);

export default PrizeInfoBlock;
