import moment from 'moment';

export const getStartAndEndDate = (startDate, endDate) => {
  const localStartDate = moment.utc(startDate).toDate();
  const localEndDate = moment.utc(endDate).toDate();
  return `${startDate? moment(localStartDate).format('DD.MM'): '...'} - ${endDate? moment(localEndDate).format('DD.MM'): '...'}`;
};

export const getStartAndEndDateWithNames = (startDate, endDate) => `${moment(startDate).format('DD MMM')}-${moment(endDate).format('DD MMM')}`;

export const getDate = date => {
  const localDate = moment.utc(date).toDate();
  return [moment(localDate).format('DD.MM.'), moment(localDate).format('HH:mm')];
};

export const getHourAndMinute = date => {
  const localDate = moment.utc(date).toDate();
  return moment(localDate).format('HH:mm');
};

export const getMatchDate = date => {
  const localDate = moment.utc(date).toDate();
  return moment(localDate).format('DD.MM.YY');
};

export const getFullDate = date => {
  const localDate = moment.utc(date).toDate();
  return moment(localDate).format('DD.MM.YY HH:mm');
};

