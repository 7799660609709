import React from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation';

const LastMatchesHeader = ({ setShowBackSpace }) => {
  const translate = useTranslation();
  return (
    <div className='for-top-of-inside-game-list-history-wr'>
      <div className="top-of-last-list">
        <div className="lef-icon-bl-of-top-last-l" onClick={() => setShowBackSpace(false)}>
          <span className="bc-i-small-arrow-left back-icon-in-history"/>
        </div>
        <div className="for-text-of-top-of-last-list">
          <p className="text-of-top-of-last-list" title={translate(t.LAST_MATCHES)}>{translate(t.LAST_MATCHES)}</p>
        </div>
      </div>
    </div>
  );
};

export default LastMatchesHeader;
