import { AUTH_ACTION_TYPES } from '../../core/constants/actionTypes';

export const rxSetIsAuth = payload => ({
  type: AUTH_ACTION_TYPES.RX_SET_IS_AUTH_BOOL,
  payload
});


export const cntrlAuthScheme = payload => ({
  type: AUTH_ACTION_TYPES.CNTRL_AUTH_SCHEME,
  payload
});

export const cntrlAuthWithUsername = payload => ({
  type: AUTH_ACTION_TYPES.CNTRL_AUTH_WITH_USERNAME,
  payload
});

export const cntrlLogOut = payload => ({
  type: AUTH_ACTION_TYPES.CNTRL_LOG_OUT,
  payload,
});
