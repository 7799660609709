import SignalRController from '../../controllers/signalRController';
import signalROperations from '../../services/operations/signalROperations';
import {SIGNAL_R_TYPES} from '../../core/constants/actionTypes';


const signalRController = new SignalRController(signalROperations);

const signalRConfig = {
  [SIGNAL_R_TYPES.CREATE_PREDICTIONS_CONNECTION]: signalRController.createPredictionsConnection,
  [SIGNAL_R_TYPES.CREATE_LIVE_MATCHES_CONNECTION]: signalRController.createLiveMatchesConnection,
  [SIGNAL_R_TYPES.CREATE_LEADERBOARD_CONNECTION] : signalRController.createLeaderBoardConnection,
  [SIGNAL_R_TYPES.SET_ROUND_AND_USER]: signalRController.setRoundAndUserforLiveMatchesConnection,
  [SIGNAL_R_TYPES.CNTRL_SET_LEADERBOARD_POSITION]: signalRController.setLeaderBoardPosition,
  [SIGNAL_R_TYPES.CNTRL_CLOSE_PREDICTIONS_CONNECTION]: signalRController.closePredictionsConnection,
  [SIGNAL_R_TYPES.CNTRL_CLOSE_LEADERBOARD_CONNECTION]: signalRController.closeLeaderBoardConnection,
  [SIGNAL_R_TYPES.CNTRL_CREATE_ROUNDS_CONNECTION]: signalRController.createRoundsConnection,
  [SIGNAL_R_TYPES.CNTRL_SET_PARTNER_ID]: signalRController.setPartnerId
};

export default signalRConfig;
