import React from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';

const PopupHeader = ({ title, setModalState }) => {
  const translate = useTranslation();
  return (
    <div className="top-block-of-popup">
      <div className="popup-title">
        <div className="d-help-close" onClick={()=>setModalState('')}><span className="bc-i-close" /></div>
        <p className="p-help" title={translate(title)}>{translate(title)}</p>
      </div>
    </div>
  );
};

export default PopupHeader;
