import API_METHODS from '../constants/apiMethods';
import { INCLUDE_CREDENTIALS } from '../constants/constants';
import HEADERS from '../constants/headers';
import { BEARER } from '../constants/util';
import JSONHelper from '../helpers/JSONhelper';
import ErrorHandler from '../helpers/ErrorHandler';

/**
 * This is an abstract class API Handler
 * this class handles API calls
 * this class is a wrapper for all api methods
 */
class ApiHandler {

  // -- Static properties -- //

  static token = '';

  // -- Constructor -- //

  constructor(domain, prefix, token = '', headers = {}) {
    this._domain = domain;
    this._prefix = prefix;
    this._token = token;
    this.headers= headers;
  }

  // -- Logic -- //

  /**
   * @name get
   * @desc global get request
   * @param url
   * @param options
   * @returns {Promise<Response>}
   */
  get(url = '', signal) {
    return fetch(url, {
      method: API_METHODS.GET,
      credentials: INCLUDE_CREDENTIALS,
      headers: this._createHeaders(),
      signal
    }).then(res => {
      return this._errorAndSuccessFilter(res)
    });
  }

  /**
   * @name post
   * @desc global post request
   * @param url
   * @param body
   * @returns {Promise<Response>}
   */
  post(url = '', body, signal, isFormData) {
    return fetch(url, {
      method: API_METHODS.POST,
      credentials: INCLUDE_CREDENTIALS,
      headers: this._createHeaders(isFormData),
      body: isFormData ? body :  JSONHelper.stringify(body),
      signal
    }).then(res => this._errorAndSuccessFilter(res));
  }

  /**
   * @name put
   * @desc global put request
   * @param url
   * @param body
   * @returns {Promise<any>}
   */
  put(url = '', body, signal) {
    return fetch(url, {
      method: API_METHODS.PUT,
      credentials: INCLUDE_CREDENTIALS,
      headers: this._createHeaders(),
      body: JSONHelper.stringify(body),
      signal
    }).then(res => this._errorAndSuccessFilter(res));
  }

  /**
   * @name delete
   * @desc global delete request
   * @param url
   * @returns {Promise<any>}
   */
  delete(url = '', signal) {
    return fetch(url, {
      method: API_METHODS.DELETE,
      credentials: INCLUDE_CREDENTIALS,
      headers: this._createHeaders(),
      signal
    }).then(res => this._errorAndSuccessFilter(res));
  }

  /**
   * @name _createHeaders
   * @desc factory for creating headers
   * @return { * }
   * @private
   */
  _createHeaders = isFormData => {
    let headers = {
      [HEADERS.Accept]: HEADERS.ApplicationJson,
      [HEADERS.TimeZoneKey]: HEADERS.TimeZone,
      ...this.headers,
    };
    if (ApiHandler.token) {
      headers = {
        [HEADERS.Authorization]: `${BEARER} ${ApiHandler.token}`,
        ...headers
      };
    }
    if (!isFormData) {
      headers = {
        [HEADERS.ContentType]: HEADERS.ApplicationJson,
        ...headers
      };
    }

    return headers;
  };


  _errorAndSuccessFilter(res) {
    if(res.ok || res.status >= ErrorHandler.errorCodesEnum.frontErrors && res.status < ErrorHandler.errorCodesEnum.serverError && res.status !== ErrorHandler.errorCodesEnum.unAuthorized) {
      return res.json();
    } else {
      return {
        responseStatus: res.status,
      }
    }
  }

  // -- Getters / Setters -- //


  get domain() {
    return this._domain;
  }

  set domain(value) {
    this._domain = value;
  }

  get preifx() {
    return this._preifx;
  }

  set preifx(value) {
    this._preifx = value;
  }

  get token() {
    return this._token;
  }

  set token(value) {
    this._token = value;
  }

  get prefix() {
    return this._prefix;
  }

  set prefix(value) {
    this._prefix = value;
  }

}


export default ApiHandler;
