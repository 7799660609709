import React from 'react';

const ForChange = ({ iconType }) => (
  <div className="for-change">
    {iconType &&
    <div className="center-circle">
      <span className={iconType}></span>
    </div>}
  </div>
);

export default ForChange;
