import { parseQuery } from '../helpers/parseQuery';
import { APP_CONSTANTS, APP_DEFAULT_VALUES } from '../constants/constants';

const PartnerModule = (() => {

  const partner = {};
  /**
   * @param field : string
   * @returns {*}
   */
  const getConfigItem = field => {
    try {
      switch ( field.toLowerCase() ) {
        case APP_CONSTANTS.EXTERNAL_GAME_ID:
          if (partner.config.hasOwnProperty(APP_CONSTANTS.EXTERNAL_GAME_ID)) break;
          return APP_DEFAULT_VALUES.EXTERNAL_GAME_ID;
        case APP_CONSTANTS.PARTNER_ID:
          if (partner.config.hasOwnProperty(APP_CONSTANTS.PARTNER_ID)) break;
          return APP_DEFAULT_VALUES.PARTNER_ID;
        case APP_CONSTANTS.CURRENCY:
          if (partner.config.hasOwnProperty(APP_CONSTANTS.CURRENCY)) break;
          return null;
        case APP_CONSTANTS.LANGUAGE:
          if (partner.config.hasOwnProperty(APP_CONSTANTS.LANGUAGE) || partner.config.hasOwnProperty(APP_CONSTANTS.LAN)) {
           return  partner.config[APP_CONSTANTS.LANGUAGE] ? partner.config[APP_CONSTANTS.LANGUAGE] :
             partner.config[APP_CONSTANTS.LAN] ? partner.config[APP_CONSTANTS.LAN] : ""
          }
          return APP_DEFAULT_VALUES.LANGUAGE;
        case APP_CONSTANTS.USERID:
          if (partner.config.hasOwnProperty(APP_CONSTANTS.USERID)) break;
          return null;
        case APP_CONSTANTS.ROUNDID:
          if (partner.config.hasOwnProperty(APP_CONSTANTS.ROUNDID)) break;
          return null;
        default:
          break;
      }
      return partner.config[field.toLowerCase()];
    } catch ( e ) {
      return -1
    }
  };

  /**
   *
   * @param field : string
   * @param value : any
   */
  function setItem(field, value) {
    partner.config[field] = value;
  }

  function initPartnerConfigFromLocation(queryString = window.location.href) {
    const config = parseQuery(queryString);
    return Object.keys(config).length ? config : { partnerid: 4 }
    // return queryString ? parseQuery(queryString) : { PARTNERID: 4 };
  }

  return {
    init: function () {
      const config = initPartnerConfigFromLocation();
      const partnerConfig = {};
      Object.keys(config).forEach(key => partnerConfig[key.toLowerCase()] = config[key]);
      partner.config = partnerConfig;
    },
    getConfigItem: function (field) {
      return getConfigItem(field)
    },
    getConfig: function () {
      return partner.config;
    },
    setItem,
  }
})();

export default PartnerModule
