import PredictorWebApiHandler from '../pedictorWebApiHandler';

const END_POINTS = {
  prefix: 'Predictions',
  smartPick: 'smartpick',
};

class PredictionsApiHandler extends PredictorWebApiHandler {
  constructor(prefix) {
    super(prefix);
  }

  getSmartPick(formData) {
    return this.post(END_POINTS.smartPick, formData)
  }
}

const predictionsApiHandler = new PredictionsApiHandler(END_POINTS.prefix);
export default predictionsApiHandler;
