import React, { useContext } from 'react';
import { NavigationContext } from '../../../contexts';
import { LEFT_COLUMN_ACTIVE_TABS, MOBILE_TABS, NAVIGATION_ITEMS } from '../../../core/constants/navigationConstants';
import { getPartnerSettings } from '../../../state-management/accessors/partnerAccessors';
import { connect } from 'react-redux';
import { TOOLTIP_TARGET_IDS } from '../../../core/constants/userTooltipGuide';

const mobileTabsWithTables = [
    {
      className: "bc-i-home",
      activeTab: MOBILE_TABS.HOME,
      navigationItem: NAVIGATION_ITEMS.HOME,
    },
    {
      className: "bc-i-hourglass-end",
      activeTab: MOBILE_TABS.ROUNDS,
      navigationItem: NAVIGATION_ITEMS.ROUNDS,
    },
    {
      className: "bc-i-icon1 table-statistics",
      activeTab: MOBILE_TABS.TABLES,
      navigationItem: NAVIGATION_ITEMS.TABLES,
      dataTooltip: TOOLTIP_TARGET_IDS.TABLE_STATISTIC_MOBILE
    },
    {
      className: "bc-i-prizes",
      activeTab: MOBILE_TABS.LEADERBOARD,
      navigationItem: NAVIGATION_ITEMS.LEADERBOARD,
    },
  ];

const mobileTabsWithoutTables = [
  {
    className: "bc-i-home",
    activeTab: MOBILE_TABS.HOME,
    navigationItem: NAVIGATION_ITEMS.HOME,
  },
  {
    className: "bc-i-hourglass-end",
    activeTab: MOBILE_TABS.ROUNDS,
    navigationItem: NAVIGATION_ITEMS.ROUNDS,
  },
  {
    className: "bc-i-prizes",
    activeTab: MOBILE_TABS.LEADERBOARD,
    navigationItem: NAVIGATION_ITEMS.LEADERBOARD,
  }
];


const MobileNavigation = ({ hasLeagueStatistic }) => {

  const {
    setActiveTab,
    setLeftColumnActiveTab,
    activeNavItem,
    setActiveNavItem
  } = useContext(NavigationContext);

  const mobileTabs = hasLeagueStatistic? mobileTabsWithTables: mobileTabsWithoutTables;

  const handleClick = (activeNavItem, activeTab) => {
    setActiveNavItem(activeNavItem);
    setActiveTab(activeTab);
    activeNavItem === NAVIGATION_ITEMS.ROUNDS && setLeftColumnActiveTab(LEFT_COLUMN_ACTIVE_TABS.ROUNDS);
    activeNavItem === NAVIGATION_ITEMS.TABLES && setLeftColumnActiveTab(LEFT_COLUMN_ACTIVE_TABS.TABLES);
  };

  return (
    <div className="mobile-nav">
      {mobileTabs.map((item, index) => {
        return (
          <div className={`nav-item ${index===activeNavItem ? 'active': ''}`}
               key={item.className}
               onClick={()=>handleClick(item.navigationItem, item.activeTab)}>
            <span className={item.className} data-tooltip={item.dataTooltip} />
          </div>)})}
    </div>
  );
};

const mapStateToProps = state => ({
  hasLeagueStatistic: getPartnerSettings(state).hasLeagueStatistic,
});

export default connect(mapStateToProps)(MobileNavigation);
