import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';

class CurrenciesOperations extends Operations {
  constructor(currenciesApiHandler) {
    super();
    this.currenciesApiHandler = currenciesApiHandler;
  }

  async getCurrencies(store, action) {
    const response = await this.currenciesApiHandler.getCurrencies(action.payload);
    return responseErrorCheck(response);
  }


}

export default CurrenciesOperations;
