import React, { useEffect, useContext, memo, createRef } from 'react';
import LeaderBoardItem from './leaderBoardItem';
import LeaderBoardTitle from './leaderBoardTitle';
import { connect } from 'react-redux';
import {
  getLobby,
  getRoundId, getRoundStatus,
  getUserId,
  getWeeklyLeaderBoard
} from '../../../state-management/accessors/lobbyAccessors';
import {
  cntrlGetGlobalLobby,
  cntrlGetLobby,
  cntrlGetWeeklyLeaderBoard
} from '../../../state-management/actions/lobbyActions';
import PartnerModule from '../../../core/moduls/PartnerModul';
import { getUserInfo } from '../../../state-management/accessors/usersAccessors';
import {
  cntrlSetLeaderBoardPosition,
} from '../../../state-management/actions/signalRActions';
import { getCurrencySign } from '../../../core/helpers/getCurrency';
import { APP_CONSTANTS } from '../../../core/constants/constants';
import { useHistory, useLocation } from 'react-router';
import { NavigationContext } from '../../../contexts';
import useGoogleAnalytics from '../../../core/customHooks/useGoogleAnalytics';
import { GA_CATEGORIES, GA_ACTIONS } from '../../../core/constants/googleAnalyticsConstants';
import useDidUpdateEffect from '../../../core/customHooks/useDidUpdateEffect';
import AnimatedList from '../../containers/animatedList';
import { getPartnerSettings } from '../../../state-management/accessors/partnerAccessors';
import { SCROLLABLE_WRAPPERS } from '../../../core/constants/userTooltipGuide';
import {LEADERBOARD_TABS} from '../../../core/constants/navigationConstants';
import {ERoundStatuses} from '../../../core/constants/enums';
const SCROLL_INSURANCE = 500;

const RoundLeaderBoard = ({
  getWeeklyLeaderBoard,
  weeklyLeaderBoard: {data: {users: weeklyLeaderBoard, count, user}, isLoading},
  roundId,
  activeUser,
  selectedUserId,
  status,
  getLobby,
  getGlobalLobby,
  setLeaderBoardPosition,
  roundIsGlobal,
  globalRoundId,
  displayUserID,
  isDisabled
}) => {

  const history = useHistory();
  const location = useLocation();
  const { setActiveTab, setActiveNavItem, activeLeaderBoardTab, setActiveLeaderBoardTab } = useContext(NavigationContext);
  const { emitEvent } = useGoogleAnalytics();

  const currencySign = getCurrencySign();
  const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);
  const skip = weeklyLeaderBoard.length;
  const currentRoundId = roundIsGlobal ? globalRoundId: roundId;

  useEffect(() => {
    if (roundIsGlobal) {
      getWeeklyLeaderBoard({globalRoundId, partnerId, skip: 0, take: 50});
    } else if (roundId){
      getWeeklyLeaderBoard({roundId, partnerId, skip: 0, take: 50});
    }
  }, [roundId, activeUser.id, roundIsGlobal]);

  useDidUpdateEffect(() => {
      const length  = weeklyLeaderBoard.length;
      const leaderBoardLength = length < 50 ? length + 1 : length;
      setLeaderBoardPosition({
        roundId: currentRoundId,
        isGlobal: roundIsGlobal,
        leaderBoardLength
      });
      // if (status === ERoundStatuses.UPCOMING && activeLeaderBoardTab !== LEADERBOARD_TABS.LEADERBOARD) {
      //   setActiveLeaderBoardTab(LEADERBOARD_TABS.LEADERBOARD);
      // }
  }, [weeklyLeaderBoard]);

  const handleScroll = e => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight - SCROLL_INSURANCE && !isLoading && count > skip) {
      if (roundIsGlobal) {
        getWeeklyLeaderBoard({globalRoundId, partnerId, skip, take: 50});
      } else {
        getWeeklyLeaderBoard({roundId, partnerId, skip, take: 50});
      }
    }
  };

  const emitLeaderboardOtherUserUsageToGA = () => {
    emitEvent({
      category: GA_CATEGORIES.USAGE,
      action: GA_ACTIONS.LEADERBOARD_OTHER_USER
    });
  };

  return (
    <div className="global-container" key={roundId + activeUser.id}>
      <div className="inside-global-c-wr">
        <div className="top-of-inside-global-c-wr">
          <LeaderBoardTitle status={status}/>
        </div>
        <div className="bottom-of-inside-global-c-wr">
          <div data-scroll-wr={SCROLLABLE_WRAPPERS.LEADERBOARD} className="scroll-wr" onScroll={handleScroll}>
            <div className="global-wrapper">
              <AnimatedList data={weeklyLeaderBoard}>
                {weeklyLeaderBoard.map((user, index) => {
                  const active = activeUser.id !==null && activeUser.id ===  user.userId;
                  return (
                    <LeaderBoardItem
                      ref={createRef()}
                      key={user.userId}
                      userId={user.userId}
                      nickname={user.username}
                      activeUserNickname={activeUser.nickname}
                      point={user.points}
                      prize={user.hasOwnProperty('prizeAmount')? user.prizeAmount : user.prize}
                      rank={user.rank}
                      index={index}
                      setActiveTab={setActiveTab}
                      setActiveNavItem={setActiveNavItem}
                      roundId={roundId}
                      selectedUserId={selectedUserId}
                      status={status}
                      getLobby={getLobby}
                      getGlobalLobby={getGlobalLobby}
                      history={history}
                      location={location}
                      active={active}
                      currencySign={currencySign}
                      roundIsGlobal={roundIsGlobal}
                      isDisabled={isDisabled}
                      displayID={displayUserID}
                      emitLeaderboardOtherUserUsageToGA={emitLeaderboardOtherUserUsageToGA}
                    />
                  )
                })}
                {!!user && user.rank > weeklyLeaderBoard.length &&
                <LeaderBoardItem
                  ref={createRef()}
                  key={user.userId}
                  userId={user.userId}
                  nickname={user.username}
                  point={user.points}
                  prize={user.hasOwnProperty('prizeAmount?')? user.prizeAmount : user.prize}
                  rank={user.rank}
                  active={true}
                  setActiveTab={setActiveTab}
                  setActiveNavItem={setActiveNavItem}
                  roundId={roundId}
                  selectedUserId={selectedUserId}
                  status={status}
                  getLobby={getLobby}
                  getGlobalLobby={getGlobalLobby}
                  history={history}
                  location={location}
                  activeUserNickname={activeUser.nickname}
                  currencySign={currencySign}
                  roundIsGlobal={roundIsGlobal}
                  displayID={displayUserID}
                />}
              </AnimatedList>
            </div>
          </div>
        </div>
      </div>
    </div>
)};

const mapStateToProps = state => ({
  weeklyLeaderBoard: getWeeklyLeaderBoard(state),
  roundId: getRoundId(state),
  selectedUserId: getUserId(state),
  status: getRoundStatus(state),
  activeUser: getUserInfo(state),
  roundIsGlobal: !!getLobby(state).data.globalRound,
  displayUserID: getPartnerSettings(state).displayId,
  globalRoundId: state.globalRound.data.id
});

const mapDispatchToProps = dispatch => ({
  getWeeklyLeaderBoard: payload => dispatch(cntrlGetWeeklyLeaderBoard(payload)),
  getLobby: payload => dispatch(cntrlGetLobby(payload)),
  getGlobalLobby: payload => dispatch(cntrlGetGlobalLobby(payload)),
  setLeaderBoardPosition: payload => dispatch(cntrlSetLeaderBoardPosition(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(RoundLeaderBoard));

