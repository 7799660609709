import React from 'react';
import {SelectionSubType} from '../../../core/constants/enums';

const FooterPredictionScoreBlock = ({ score })=> {
  const homeTeamScore = score.find(item => item.selectionSubType === SelectionSubType.HomeScore).value;
  const awayTeamScore = score.find(item => item.selectionSubType === SelectionSubType.AwayScore).value;

  return (
    <div className="bottom-your-prediction-item center">
      <div className="score-info-wr">
        <span className="score-info-spn">{homeTeamScore}</span>
        <span className="score-info-spn">-</span>
        <span className="score-info-spn">{awayTeamScore}</span>
      </div>
    </div>
  );
};

export default FooterPredictionScoreBlock;
