import React from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';
import withPartnerSettings from '../../../hocs/withPartnerSettingsHoc';
import {PARTNER_SETTINGS_CONSTANTS} from '../../../core/constants/partnerSettingsConstants';
import t from '../../../core/staticData/translation';
import ScreenShot from '../../containers/screenshot';

const ShareBlock = ({ activeShareBlock,
                      handleShareButtonClick,
                      selected,
                      twitterHref,
                      handleFacebookButtonClick,
                      handleTwitterButtonClick
}) => {

  const translate = useTranslation();
  return(
    <>
      <div className="history-icon-block" onClick={handleShareButtonClick} >
        {!activeShareBlock  && <span className={`bc-i-share-alt share-icon ${selected? 'clickable': ''}`}
                                     title={translate(t.SHARE)}/>}
        {activeShareBlock && <span className="bc-i-close close-icon clickable"></span>}
      </div>
      <div className="share-block">
        <a className="game-btn"
           title='Twitter'
           onClick={handleTwitterButtonClick}>
          <span className="bc-i-twitter twitter-icon"/>
        </a>
        <button className="game-btn" type="button" onClick={
          // e.stopPropagation();
          // ScreenShot.handleScreenShot()
          handleFacebookButtonClick
        } title='Facebook'>
          <span className="bc-i-facebook facebook-icon"/>
        </button>
      </div>
    </>
  )
};

export default withPartnerSettings(PARTNER_SETTINGS_CONSTANTS.HAS_SOCIAL_SHARING)(ShareBlock);
