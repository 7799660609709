import Controller from '../../core/classes/AppController';

import {
  rxGetCompetitionsTablesDone,
  rxGetCompetitionsTablesPending, rxGetMatchStatDone, rxGetMatchStatPending
} from '../../state-management/actions/statisticsActions';

class StatisticsController extends Controller {
  constructor(statisticsOperations) {
    super();
    this.statisticsOperations = statisticsOperations;
  }

  getCompetitionsTables = async (store, action) => {
    store.dispatch(rxGetCompetitionsTablesPending());
    const response = await this.statisticsOperations.getCompetitionsTables(store, action);
    store.dispatch(rxGetCompetitionsTablesDone(response));
  };

  getMatchStat = async (store, action) => {
    store.dispatch(rxGetMatchStatPending());
    const response = await this.statisticsOperations.getMatchStat(store, action);
    store.dispatch(rxGetMatchStatDone({...store.getState().matchStats.data, [action.payload]: response}))
  };

  getCompetitionTable = async (store, action) => {
    store.dispatch(rxGetCompetitionsTablesPending());
    const response = await this.statisticsOperations.getCompetitionTable(store, action);
    const competitionTables = store.getState().competitionsTables.data;
    store.dispatch(rxGetCompetitionsTablesDone({ ...competitionTables, [response.id]: response }))
  }
}

export default StatisticsController;
