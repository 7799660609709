import React from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation';

const GameInfoTitle = ({ name = t.TEAM }) => {
    const translate = useTranslation();
    return (
      <div className="game-info title">
          <div className="game-info-cell" title={translate(name)}>{translate(name)}</div>
          <div className="game-info-cell" title={translate(t.MATCHES_PLAYED)}>{translate(t.MP)}</div>
          <div className="game-info-cell">
              <div className="small-game-table">
                  <div className="small-game-item" title={translate(t.WINS)}>{translate(t.W)}</div>
                  <div className="small-game-item" title={translate(t.DRAWS)}>{translate(t.D)}</div>
                  <div className="small-game-item" title={translate(t.LOSSES)}>{translate(t.L)}</div>
              </div>
          </div>
          <div className="game-info-cell" title={translate(t.GOALS)}>{translate(t.G)}</div>
          <div className="game-info-cell" title={translate(t.POINTS)}>{translate(t.PTS)}</div>
      </div>
    );
};

export default GameInfoTitle;
