import { PARTNER_TYPES } from '../../core/constants/actionTypes';

export const cntrlGetCurrentGlobalRound = payload => ({
  type: PARTNER_TYPES.GET_CURRENT_GLOBAL_ROUND.CNTRL,
  payload
});

export const rxGetCurrentGlobalRoundPending = payload => ({
  type: PARTNER_TYPES.GET_CURRENT_GLOBAL_ROUND.PENDING,
  payload
});

export const rxGetCurrentGlobalRoundDone = payload => ({
  type: PARTNER_TYPES.GET_CURRENT_GLOBAL_ROUND.DONE,
  payload,
});

export const cntrlGetPartnerColors = payload => ({
  type: PARTNER_TYPES.CNTRL_GET_PARTNER_COLORS,
  payload,
});

export const rxGetPartnerColorsPending = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_COLORS_PENDING,
  payload,
});

export const rxGetPartnerColorsDone = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_COLORS_DONE,
  payload,
});


export const cntrlGetPartnerSettings = payload => ({
  type: PARTNER_TYPES.GET_PARTNER_SETTINGS.CNTRL,
  payload,
});

export const rxGetPartnerSettingsPending = payload => ({
  type: PARTNER_TYPES.GET_PARTNER_SETTINGS.PENDING,
  payload,
});

export const rxGetPartnerSettingsDone = payload => ({
  type: PARTNER_TYPES.GET_PARTNER_SETTINGS.DONE,
  payload,
});

export const cntrlGetCompetitions = payload => ({
  type: PARTNER_TYPES.GET_COMPETITIONS.CNTRL,
  payload,
});

export const rxGetCompetitionsPending = payload => ({
  type: PARTNER_TYPES.GET_COMPETITIONS.PENDING,
  payload,
});

export const rxGetCompetitionsDone = payload => ({
  type: PARTNER_TYPES.GET_COMPETITIONS.DONE,
  payload,
});
