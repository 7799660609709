import React from 'react';
import { connect, useSelector } from 'react-redux';
import withPartnerSettings from '../../../hocs/withPartnerSettingsHoc';
import { compose } from 'redux';
import { PARTNER_SETTINGS_CONSTANTS } from '../../../core/constants/partnerSettingsConstants';
import {
  getMatches,
  getRoundId,
  getRoundStatus
} from '../../../state-management/accessors/lobbyAccessors';
import { TOOLTIP_TARGET_IDS } from '../../../core/constants/userTooltipGuide';
import { EGameStatuses } from '../../../core/constants/enums';

const DoubleScoreButton = ({ roundMatchId,
                             isDoubled,
                             predictionIsDoubled,
                             isActive,
                             isUpcoming,
                             isCanceledOrIsPostponed,
                             fieldIsDisabled,
                             handleDoubleButtonClick,
                             isDoubledChecked
}) => {
  const isSelected = predictionIsDoubled === roundMatchId;
  const showDoublePredictionButton = fieldIsDisabled  ? isSelected : isActive;

  return (
    <>
      {
        isUpcoming && showDoublePredictionButton && !isDoubledChecked &&
        <div className="double-score-btn-wr" data-tooltip={TOOLTIP_TARGET_IDS.X2_PREDICTION}>
          <button className={`double-score-btn game-btn ${isSelected ? 'state-1' : ''}`}
                  type="button"
                  onClick={handleDoubleButtonClick}
                  tabIndex={-1}
          >
            <span className="normal-spn-of-btn">2</span>
          </button>
        </div>
      }
      {
        !isUpcoming && !isCanceledOrIsPostponed && isDoubled &&
        <button className='game-btn state-1' type="button" >
          <span className="normal-spn-of-btn">2</span>
        </button>
      }
    </>
  );
};

const mapStateToProps = state => ({
  roundStatus: getRoundStatus(state),
  roundId: getRoundId(state),
  isDoubledChecked: getMatches(state)?.findIndex(({ prediction, status }) => status !== EGameStatuses.UPCOMING && prediction?.isDoubled) !== -1
});

const mapDispatchToProps = dispatch => ({
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPartnerSettings(PARTNER_SETTINGS_CONSTANTS.HAS_X2_MODE),
)(DoubleScoreButton);



