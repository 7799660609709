import Operations from '../../../core/classes/AppOperation';
import { SIGNAL_R_URL } from '../../../core/constants/urls';
import * as signalR from '@microsoft/signalr';
import ApiHandler from '../../../core/classes/ApiHandler'
import {
  rxGetHistoryDone,
  rxGetLobbyDone,
  rxGetWeeklyLeaderBoardDone
} from '../../../state-management/actions/lobbyActions';
import { ERoundCancelMethods, ERoundStatuses } from '../../../core/constants/enums';
import { cntrlGetDistribution } from '../../../state-management/actions/roundActions';
import PartnerModule from '../../../core/moduls/PartnerModul';
import { APP_CONSTANTS } from '../../../core/constants/constants';

const getDateByMs = date => new Date(date).getTime();

class SignalROperations extends Operations {

  createPredictionsConnection (){
    return new signalR.HubConnectionBuilder()
      .withUrl(`${SIGNAL_R_URL}/predictions?access_token=${ApiHandler.token}`)
      .withAutomaticReconnect()
      .build();
  }

  createLiveMatchesConnection (){
    return new signalR.HubConnectionBuilder()
      .withUrl(`${SIGNAL_R_URL}/livematches`,  )
      // .withAutomaticReconnect()
      .build();
  }

  createLeaderBoardConnection (){
    return new signalR.HubConnectionBuilder()
      .withUrl(`${SIGNAL_R_URL}/leaderboard?access_token=${ApiHandler.token}`)
      // .withAutomaticReconnect()
      .build();
  }

  createRoundsConnection () {
    return new signalR.HubConnectionBuilder()
      .withUrl(`${SIGNAL_R_URL}/rounds`)
      // .withAutomaticReconnect()
      .build();
  }

  updateRounds (store, data) {
    const rounds = JSON.parse(JSON.stringify(store.getState().history.data));
    const currentRound = JSON.parse(JSON.stringify(store.getState().lobby.data.round));
    const [ roundId, roundStatus, roundCancelMethod ] = data;

    if(currentRound.id === roundId) {
      currentRound.status = roundStatus;
      store.dispatch(rxGetLobbyDone({...store.getState().lobby.data, round: currentRound}));
    }

    if (roundStatus === ERoundStatuses.CANCELED && roundCancelMethod === ERoundCancelMethods.Manual) {
      store.dispatch(rxGetHistoryDone(rounds.filter(round => round.id !== roundId)));
    } else {
      const changedRound = rounds.find(round => round.id === roundId);
      if (changedRound) {
        changedRound.status = roundStatus;
        store.dispatch(rxGetHistoryDone(rounds));
      }
    }
  }

  addRound (store, data) {
    const rounds = JSON.parse(JSON.stringify(store.getState().history.data));
    rounds.push(data);
    rounds.sort((a, b) => {
      const aStartDate = getDateByMs(a.startDate);
      const bStartDate = getDateByMs(b.startDate);
      const aCreationDate = getDateByMs(a.creationDate);
      const bCreationDate = getDateByMs(b.creationDate);
      if (aStartDate !== bStartDate) {
        return bStartDate - aStartDate;
      }
      return bCreationDate - aCreationDate;
    });

    store.dispatch(rxGetHistoryDone(rounds));
  }

  updatePrizeFund (store, data) {
    const { roundId: id } = data;
    const currentRoundId = store.getState().lobby.data.round.id;

    if (id === currentRoundId) {
      store.dispatch(cntrlGetDistribution({id, partnerId: PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID)}));
    }
  }

  updateRoundEntries (store, data) {
    const [ roundId, entries ] = data;
    const currentRound = JSON.parse(JSON.stringify(store.getState().lobby.data.round));
    if (roundId === currentRound.id) {
      currentRound.entries = entries;
      store.dispatch(rxGetLobbyDone({...store.getState().lobby.data, round: currentRound}));
    }
  }


  updatePredictions (store, data) {
    const { roundId, predictions } = data;
    const currentRoundId = store.getState().lobby.data.round.id;

    if (roundId !== currentRoundId) {
      return;
    }

    const matches = JSON.parse(JSON.stringify(store.getState().lobby.data.matches));
    const round = JSON.parse(JSON.stringify(store.getState().lobby.data.round));

    matches.forEach(match => {
      const { roundMatchId } = match;
      const newPrediction = predictions.find(item => item.roundMatchId === roundMatchId);
      if (newPrediction) {
        const { id, isDoubled, predictionSelections } = newPrediction;
        match.prediction.id = id;
        match.prediction.isDoubled = isDoubled;
        match.prediction.selectionValues = predictionSelections
      }
    });

    round.isParticipant = true;

    store.dispatch(rxGetLobbyDone({...store.getState().lobby.data, matches, round}));
  }

  updateLiveMatch (store, data) {
    const matches = JSON.parse(JSON.stringify(store.getState().lobby.data.matches));
    const updatedMatches  = matches.filter(match => match.matchId === data.matchId);
    if(updatedMatches.length) {
      updatedMatches.forEach(updatedMatch => {
        updatedMatch.status = data.status;
        updatedMatch.matchResult = {
          homeTeamScore: data.homeScore,
          awayTeamScore: data.awayScore,
          ftts: data.ftts,
          currentMinute: data.currentMinute,
        };
      });
      store.dispatch(rxGetLobbyDone({...store.getState().lobby.data, matches}));
    }
  }

  updatePredictionResult (store, data) {
    const matches = JSON.parse(JSON.stringify(store.getState().lobby.data.matches));
    const { predictionId, wonPoint, sumPoint, predictionResultDetails, status } = data;
    const updatedMatch = matches.find(match => match.predictionId === predictionId);
    if (updatedMatch) {
      updatedMatch.predictionResult = {
        wonPoint,
        sumPoint,
        predictionResultDetails
        };
      updatedMatch.prediction.status = status;
    }
    store.dispatch(rxGetLobbyDone({...store.getState().lobby.data, matches}));
  }

  updateLeaderBoard (store, data) {
    const { users, signedUser } = data;
    store.dispatch(rxGetWeeklyLeaderBoardDone({
      ...store.getState().weeklyLeaderBoard.data,
      users: [...users],
      user: signedUser,
    }));
  }
}

const signalROperations = new SignalROperations();
export default signalROperations;
