import React from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';

const GuidePopupContent = ({ description }) => {

    const translate = useTranslation();
    const descriptionTr = translate(description);

    return (
        <div className="top-tooltip-inner-bottom">
            <p className="top-tooltip-inner-bottom-txt" title={descriptionTr}>
                { descriptionTr }
            </p>
        </div>
    );
};

export default GuidePopupContent;