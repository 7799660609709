class LocalStorageService {

    get(key) {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (error) {
            console.error(error);
        }
    }

    set(key, value) {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error(error);
        }
    }

    remove(key) {
        localStorage.removeItem(key);
    }

}

export default new LocalStorageService();
