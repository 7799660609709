import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';
import {rxGetUserInfoDone} from '../../../state-management/actions/usersActions';

class UsersOperations extends Operations {
  constructor(usersApiHandler) {
    super();
    this.usersApiHandler = usersApiHandler;
  }

  async getUserInfo() {
    const response = await this.usersApiHandler.getUserInfo();
    return responseErrorCheck(response);
  }

  async postUserInfo(store, action) {
    const { dontUpdate, ...data } = action.payload;
    const response = await this.usersApiHandler.postUserInfo(data);
    const result = responseErrorCheck(response);
    if (!dontUpdate) {
      const user = {...store.getState().userInfo.data};
      const { avatar } = action.payload;
      const nickname = action.payload.nickname? action.payload.nickname : user.nickname;
      store.dispatch(rxGetUserInfoDone({...user, nickname, avatar, hasNickname: true }));
    }
    return result;
  }

  async changeChristmasMood(store, action) {
    const response = await this.usersApiHandler.postUserInfo(action.payload);
    return responseErrorCheck(response);
  }

  async checkUsername(store, action){
    const response = await this.usersApiHandler.checkUsername(action.payload);
    return responseErrorCheck(response);
  }

  async generateNickname() {
    const response = await this.usersApiHandler.generateNickname();
    return responseErrorCheck(response);
  }

}
export default UsersOperations;
