import React from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation.json';

const GuidePopupFooter = ({
    onPrev,
    onNext,
    isPrevDisabled,
    isNextDisabled
}) => {

    const translate = useTranslation();

    return (
        <div className="bottom-tooltip-inner-bottom">
            <div className="bottom-tooltip-inner-bottom-inner">
                <div
                    onClick={() => !isPrevDisabled && onPrev()}
                    title={translate(t.PREVIOUS)}
                    className={`next-prev-icon-block ${isPrevDisabled ? 'disabled' : ''}`}
                >
                    <span className="bc-i-small-arrow-left"/>
                </div>
                <div
                    onClick={() => !isNextDisabled && onNext()}
                    title={translate(t.NEXT)}
                    className={`next-prev-icon-block ${isNextDisabled ? 'disabled' : ''}`}
                >
                    <span className="bc-i-small-arrow-right"/>
                </div>
            </div>
        </div>
    );
};

export default GuidePopupFooter;