import React, { useContext } from 'react';
import History from './history';
import Tables from './tables';
import useTranslation from '../../../core/customHooks/useTranslation';
import { NavigationContext } from '../../../contexts';
import { LEFT_COLUMN_ACTIVE_TABS } from '../../../core/constants/navigationConstants';
import { getPartnerSettings } from '../../../state-management/accessors/partnerAccessors';
import { connect } from 'react-redux';
import t from '../../../core/staticData/translation';
import { TOOLTIP_TARGET_IDS } from '../../../core/constants/userTooltipGuide';

const LeftColumn = ({ hasLeagueStatistic, isDisabled }) => {
  const translate = useTranslation();

  const {
    leftColumnActiveTab,
    setLeftColumnActiveTab,
  } = useContext(NavigationContext);

  return (
    <div className="left-column">
      <div className="left-container-wr">
        <div className="inside-left-container">
          <div className={`left-container-layout ${leftColumnActiveTab ? 'next' : ''}`}>{/*remove class next*/}
            <div className="top-of-left-cont">
              <div className="inside-top-of-left-cont-wr">
                <div className="tab-block-of-top-left">
                  {hasLeagueStatistic ?
                    <>
                      <div className="tab-item-of-top-left-wr">
                        <div className={`tab-item-of-top-left ${leftColumnActiveTab ? '' : 'active-tab'}`}
                              onClick={() => setLeftColumnActiveTab(LEFT_COLUMN_ACTIVE_TABS.ROUNDS)}
                              title={translate(t.ROUNDS)}
                        >
                          {translate(t.ROUNDS)}
                        </div>
                      </div>
                     <div
                      className="tab-item-of-top-left-wr"
                      data-tooltip={TOOLTIP_TARGET_IDS.TABLE_STATISTIC_WEB}
                      >
                      <div className={`tab-item-of-top-left ${leftColumnActiveTab ? 'active-tab' : ''}`}
                            onClick={() => setLeftColumnActiveTab(LEFT_COLUMN_ACTIVE_TABS.TABLES)}
                            title={translate(t.TABLES)}
                      >
                        {translate(t.TABLES)}
                      </div>
                     </div>
                    </> :
                    <div className="tab-item-of-top-left" title={translate(t.ROUNDS)}>{translate(t.ROUNDS)}</div> }
                  <div className="underline-block">
                    {hasLeagueStatistic && <span className="inside-underline"/>}
                  </div>
                </div>
              </div>
            </div>
            <div className="body-of-left-cont">
              <div className="body-wr-left-cont">
                <History isDisabled={isDisabled} />
                <Tables/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  hasLeagueStatistic: getPartnerSettings(state).hasLeagueStatistic,
});


export default connect(mapStateToProps)(LeftColumn);
