import React from 'react';
import { useSelector } from 'react-redux';
import { getImgUrl } from '../../../core/helpers/imgUrl';

const TeamLogo = ({ imageUrl, jerseyUrl, title }) => {
  const usesLogoImages = useSelector(state => state.partnerSettings.data.usesLogoImages);
  const url = usesLogoImages ? imageUrl : jerseyUrl;

  return (
    <div className="score-table-item">
      <div className="team-logo-block">
        <img src={getImgUrl(url)} alt="" title={title} />
      </div>
    </div>
  );
};

TeamLogo.defaultProps = {
  name: ''
};


export default TeamLogo;
