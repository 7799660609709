import { RelativeOrAbsolute } from '../constants/enums';
import { UrlSource } from '../constants/enums';
import { DOMAIN, STATISTICS_API } from '../constants/urls';
import defaultImage from '../../images/defaultImage.png';

export const getImgUrl = (imgUrlData) => {
  if(imgUrlData) {
    if(imgUrlData.uriKind === RelativeOrAbsolute.Absolute) {
      return imgUrlData.url
    }

    if(imgUrlData.uriKind === RelativeOrAbsolute.Relative) {
      if(imgUrlData.urlSource === UrlSource.Predictor) {
        return `${DOMAIN}/${imgUrlData.url}`
      }

      if(imgUrlData.urlSource === UrlSource.Statistics) {
        return `${STATISTICS_API}/${imgUrlData.url}`
      }
    }
  }
  return defaultImage
};
