const currencies = {
  '¤': { sign: '¤', fraction: 0, pre: false },
  AMD: { sign: '֏', fraction: 0, pre: false },
  AED: { sign: 'د.إ', fraction: 2, pre: false },
  AOA: { sign: 'Kz', fraction: 2, pre: false },
  ARS: { sign: '$', fraction: 2, pre: false },
  AUD: { sign: 'A$', fraction: 2, pre: false },
  BAM: { sign: 'KM', fraction: 2, pre: false },
  BGN: { sign: 'лв', fraction: 2, pre: false },
  BIT: { sign: '฿', fraction: 2, pre: false },
  BOB: { sign: '$b', fraction: 2, pre: false },
  BTC: { sign: 'Ƀ', fraction: 2, pre: false },
  CDF: { sign: 'Fr', fraction: 2, pre: false },
  CFA: { sign: 'Fr', fraction: 2, pre: false },
  CPI: { sign: 'CPI', fraction: 2, pre: false },
  CRC: { sign: '₡', fraction: 2, pre: false },
  DOP: { sign: 'RD$', fraction: 2, pre: false },
  GRN: { sign: '₴', fraction: 2, pre: false },
  HTG: { sign: 'G', fraction: 2, pre: false },
  IQD: { sign: 'د.ع', fraction: 2, pre: false },
  JOD: { sign: 'JD', fraction: 2, pre: false },
  KES: { sign: 'Ksh', fraction: 2, pre: false },
  KGS: { sign: 'лв', fraction: 2, pre: false },
  LTC: { sign: 'Ł', fraction: 2, pre: false },
  LTL: { sign: 'Lt', fraction: 2, pre: false },
  LVL: { sign: 'Ls', fraction: 2, pre: false },
  MBT: { sign: 'mBTC', fraction: 2, pre: false },
  MDL: { sign: 'leu', fraction: 2, pre: false },
  MGA: { sign: 'Ar', fraction: 2, pre: false },
  MKD: { sign: 'ден', fraction: 2, pre: false },
  MUR: { sign: '₨', fraction: 2, pre: false },
  MZN: { sign: 'MT', fraction: 2, pre: false },
  RSD: { sign: 'Дин.', fraction: 2, pre: false },
  SAO: { sign: '₫', fraction: 2, pre: false },
  SAR: { sign: 'ريال', fraction: 2, pre: false },
  SEK: { sign: 'kr', fraction: 2, pre: false },
  SGD: { sign: 'S$', fraction: 2, pre: false },
  SLL: { sign: 'SLL', fraction: 2, pre: false },
  SSP: { sign: 'جنيه سوداني ', fraction: 2, pre: false },
  THB: { sign: '฿', fraction: 2, pre: false },
  TJS: { sign: 'TJS', fraction: 2, pre: false },
  TOM: { sign: 'تومان', fraction: 2, pre: false },
  TTD: { sign: '$', fraction: 2, pre: false },
  TWD: { sign: 'NT$', fraction: 2, pre: false },
  TZS: { sign: 'TSh', fraction: 2, pre: false },
  UGX: { sign: 'USh', fraction: 2, pre: false },
  UYU: { sign: '$U', fraction: 2, pre: false },
  UZS: { sign: 'лв', fraction: 2, pre: false },
  VC: { sign: 'VC', fraction: 2, pre: false },
  VEB: { sign: 'Bs.', fraction: 2, pre: false },
  VEF: { sign: 'Bs.F.', fraction: 2, pre: false },
  VND: { sign: '₫', fraction: 2, pre: false },
  XAF: { sign: 'FCFA', fraction: 2, pre: false },
  ZAR: { sign: 'R', fraction: 2, pre: false },
  ZMW: { sign: 'ZK', fraction: 2, pre: false },
  AZN: { sign: '₼', fraction: 2, pre: false },
  BYR: { sign: 'Br', fraction: 2, pre: false },
  CNY: { sign: '元 / ¥', fraction: 2, pre: false },
  EUR: { sign: '€', fraction: 2, pre: true },
  GBP: { sign: '£', fraction: 2, pre: true },
  GHS: { sign: '₵', fraction: 2, pre: false },
  IRR: { sign: '﷼', fraction: 2, pre: false },
  IRT: { sign: ' IRT', fraction: 2, pre: false },
  KZT: { sign: '₸', fraction: 2, pre: false },
  NGN: { sign: '₦', fraction: 2, pre: false },
  RUB: { sign: '₽', fraction: 2, pre: false },
  TRY: { sign: '₺', fraction: 2, pre: true },
  UAH: { sign: '₴', fraction: 2, pre: true },
  GEL: { sign: 'ლ', fraction: 2, pre: false },
  USD: { sign: '$', fraction: 2, pre: true },
  PYG: { sign: '₲', fraction: 2, pre: true },
  PEN: { sign: 'PEN', fraction: 2, pre: true },
  BRL: { sign: 'R$', fraction: 2, pre: false },
  INR: { sign: '₹', fraction: 2, pre: true },
  BSD: { sign: 'B$', fraction: 2, pre: false },
  CAD: { sign: '$', fraction: 2, pre: false },
  CHF: { sign: 'SFr.', fraction: 2, pre: false },
  CLP: { sign: '$', fraction: 2, pre: false },
  COP: { sign: '$', fraction: 2, pre: false },
  CZK: { sign: 'Kč', fraction: 2, pre: false },
  DKK: { sign: 'kr', fraction: 2, pre: false },
  GTQ: { sign: 'Q', fraction: 2, pre: false },
  HKD: { sign: '$', fraction: 2, pre: false },
  HNL: { sign: 'L', fraction: 2, pre: false },
  HRK: { sign: 'kn', fraction: 2, pre: false },
  HUF: { sign: 'Ft', fraction: 2, pre: false },
  IDR: { sign: 'Rp', fraction: 2, pre: false },
  ILS: { sign: '₪', fraction: 2, pre: false },
  JPY: { sign: '¥', fraction: 2, pre: false },
  KRW: { sign: '₩', fraction: 2, pre: false },
  LKR: { sign: '₨', fraction: 2, pre: false },
  MXN: { sign: '$', fraction: 2, pre: false },
  MYR: { sign: 'RM', fraction: 2, pre: false },
  NOK: { sign: 'kr', fraction: 2, pre: false },
  NZD: { sign: '$', fraction: 2, pre: false },
  PAB: { sign: 'B/.', fraction: 2, pre: false },
  PHP: { sign: '₱', fraction: 2, pre: false },
  PLN: { sign: 'zł', fraction: 2, pre: false },
  RON: { sign: 'lei', fraction: 2, pre: false },
  RWF: { sign: 'Fr', fraction: 2, pre: false },
  PRB: { sign: 'p', fraction: 2, pre: false },
};

export default currencies;
