import createAsyncReducer from '../../core/helpers/createAsyncReducer';
import { USERS_TYPES } from '../../core/constants/actionTypes';
import createReducer from '../../core/helpers/createReducer';

export const userInfoReducer = createAsyncReducer(USERS_TYPES.GET_USER_INFO.PREFIX, null);
export const checkNicknameReducer = createReducer(false, {
  [USERS_TYPES.RX_SET_NICKNAME_ERROR_BOOL]: (state, action) => {
    return action.payload
  },
});
