import React from 'react';
import HistoryItem from './historyItem';
import { connect } from 'react-redux';
import { getHistory } from '../../../state-management/accessors/lobbyAccessors';
import HistoryItemGlobal from './historyItemGlobal';
import { getGlobalRound } from '../../../state-management/accessors/partnerAccessors';
import { SCROLLABLE_WRAPPERS } from '../../../core/constants/userTooltipGuide';

const History = ({ history, globalRound : { id }, isDisabled }) => {
  return (
    <div className="history-container">
      <div className="scroll-wr" data-scroll-wr={SCROLLABLE_WRAPPERS.ROUNDS}>
        <div className="histories-wr">
          {
            id && <HistoryItemGlobal/>
          }
          {
            history.map(historyItem => <HistoryItem key={historyItem.id} {...historyItem} isDisabled={isDisabled} />)
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  history: getHistory(state),
  globalRound: getGlobalRound(state),
});


export default connect(mapStateToProps)(History);
