import ErrorHandler from './ErrorHandler';
import store from '../../state-management/store';
import { rxSetIsUnderMaintenance } from '../../state-management/actions/maintenanceActions';

const responseErrorCheck = response => {

  if (response.errorCode === ErrorHandler.errorCodes.lowDepositAmount ||
      response.errorCode === ErrorHandler.errorCodes.lowDepositCount ||
      response.errorCode === ErrorHandler.errorCodes.lowDepositLastDate) {
      return response;
  }

  if (response.responseStatus > ErrorHandler.errorCodesEnum.serverError && response.responseStatus < 600) {
    store.dispatch(rxSetIsUnderMaintenance(true));
    return response;
  }

  if(response.errorMessage) {
    if(response.errorCode === ErrorHandler.errorCodes.unverified) {
      throw new Error(ErrorHandler.errorCodesWithErrorMessage[ErrorHandler.errorCodesEnum.unverified]);
    }
    throw new Error(response.errorMessage);
  }

  if(response.responseStatus === ErrorHandler.errorCodesEnum.unAuthorized) {
    throw new Error(ErrorHandler.errorCodesWithErrorMessage[ErrorHandler.errorCodesEnum.unAuthorized]);
  }

  if(response.responseStatus === ErrorHandler.errorCodesEnum.duplicateRequest) {
    throw new Error(ErrorHandler.errorCodesWithErrorMessage[ErrorHandler.errorCodesEnum.duplicateRequest]);
  }

  if (response.responseStatus >= ErrorHandler.errorCodesEnum.frontErrors &&
      response.responseStatus < ErrorHandler.errorCodesEnum.serverError &&
      response.responseStatus !== ErrorHandler.errorCodesEnum.notAcceptable
  ) {
    throw new Error(ErrorHandler.errorCodesWithErrorMessage[ErrorHandler.errorCodesEnum.serverError]);
  }

  if(response.responseStatus >= ErrorHandler.errorCodesEnum.serverError) {
    throw new Error(ErrorHandler.errorCodesWithErrorMessage[ErrorHandler.errorCodesEnum.serverError]);
  }

  if (response.hasOwnProperty('result')) {
    return response.result;
  }

  return response;

};


export default responseErrorCheck;
