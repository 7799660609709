import React, { forwardRef } from 'react';
import ShareListItem from '../shareListItem';
import {useSelector} from 'react-redux';

const matchHasPrediction = match => {
  return match.prediction && match.prediction.id
};

const ShareBlock = forwardRef((props, ref) => {
  const matches = useSelector(state => state.lobby.data.matches);
  return (
    <div className="share-wrapper" ref={ref}>
      <div className="inside-share-wrapper">
        {
          matches.filter(matchHasPrediction).slice(0, 10).map(match => {
            return <ShareListItem match={match} key={match.matchId} />
          })
        }
      </div>
    </div>
  )
});

export default ShareBlock;
