import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { cntrlGetInfo } from '../../../state-management/actions/infoActions';
import { getInfo } from '../../../state-management/accessors/infoAccessors';
import InfoBlock from './infoBlock';
import CalculationTable from './calculationTable';
import Loader from '../loader';
import PartnerModule from '../../../core/moduls/PartnerModul';
import { APP_CONSTANTS } from '../../../core/constants/constants';

const Information = ({ getInfo, information:{isLoading, data: { informations }} }) => {
  useEffect(() => {
    !informations.length && getInfo();
  }, []);

  return (
    <div className="popup-bottom-wr">
      {
        isLoading? <Loader/> :
          informations.map(information => {
            return <InfoBlock title={information.title}
                              description={information.description}
                              key={information.priority}/>
          })}
    </div>
  );
};

const mapStateToProps = state => ({
  information: getInfo(state),
});

const mapDispatchToProps = dispatch => ({
  getInfo: () => dispatch(cntrlGetInfo({orderExpression: 'priority', isEnable: true, languageCode: PartnerModule.getConfigItem(APP_CONSTANTS.LANGUAGE),
  partnerId: PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID)}))
});

export default connect(mapStateToProps, mapDispatchToProps)(Information);
