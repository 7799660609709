import React from 'react';

export const toHTML = str => ({ __html: str });
const RichText = ({content}) => {
  return (
    <div
      dangerouslySetInnerHTML={toHTML(content)}
    />
  );
};

export default RichText;
