import React, { useRef, useState, useEffect } from 'react'
import * as htmlToImage from 'html-to-image';
import { SCREENSHOT_SIZES } from './constants';

export const ScreenShot = ({ children, onEnd, node, onScreenShot }) => {
  const ref = useRef();

  useEffect(() => {
    ScreenShot.handleScreenShot = async () => {
      if (ref.current) {
        const blob = await htmlToImage.toBlob(ref.current, {
          width: SCREENSHOT_SIZES.width,
          height: SCREENSHOT_SIZES.height,
          style: {
            maxWidth: SCREENSHOT_SIZES.width
          }
        });
        // const fileURL = URL.createObjectURL(blob);
        // window.open(fileURL, '', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${800}, height=${600}, top=${300}, left=${600}`);
       return blob;
      }
    }
  }, []);

  return (
    <>
      <section style={{
        position: 'absolute',
        pointerEvents: 'none',
        overflow: 'hidden',
        opacity: 0,
        maxHeight: 0,
        width: 0,
      }}>
        {React.cloneElement(node, {ref})}
      </section>
    </>
  )
};

export default ScreenShot;
