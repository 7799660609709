import React from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';

const BetRestrictionMessage = ({
    text,
    values
}) => {

    const translate = useTranslation();

    return (
        <p className="n-text-v">
            { translate(text, values) }
        </p>
    );
};
            
export default BetRestrictionMessage;
