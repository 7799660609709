import { parseQuery } from '../../core/helpers/parseQuery';

class WidgetService {

    data = {};

    constructor() {
        this.data = this.getWidgetData();
    }

    getWidgetData() {
        try {
            const queryObject = parseQuery(window.location.href);
            const browserUrlQueryObject = parseQuery(queryObject.browserUrl);
            const result = JSON.parse(browserUrlQueryObject.matchPrediction);
            return result;
        } catch (error) {
            return {};
        }
    }

}

const widgetService = new WidgetService();

export default widgetService;
