import PredictorWebApiHandler from '../pedictorWebApiHandler';

const END_POINTS = {
  prefix: 'Users',
  info: 'info',
  checkUsename: 'checkUsername',
  generateNickname: 'generateNickname',
};

class UsersApiHandler extends PredictorWebApiHandler {
  constructor(prefix){
    super(prefix);
  }

  getUserInfo() {
    return this.get(END_POINTS.info)
  }

  postUserInfo(payload) {
    return this.put(END_POINTS.info, payload);
  }

  checkUsername(query){
    return this.get(END_POINTS.checkUsename, query);
  }

  generateNickname() {
    return this.post(END_POINTS.generateNickname);
  }
}

const usersApiHandler = new UsersApiHandler(END_POINTS.prefix);
export default usersApiHandler;
