import PartnerModule from "../../core/moduls/PartnerModul";
import {APP_CONSTANTS, ENVIRONMENT_TYPE} from '../../core/constants/constants';

class PostMessageService {

  openLoginPopup(obj = {
    action: 'openSlider',
    tab: 'login'
  }, target = '*') {
    // if (this.isInIframe()) {
      window.top.postMessage(obj, target);
    // } else {
      // this.loginInBet();
    // }
  }

  loginInBet () {
    window.location.href = `${window.location.protocol}//${window.location.host.replace(/^([a-zA-Z]+)\./, '')}#?sign-in&action=login`;
  }

  openDeposit(obj = {
    action: 'openSlider',
    tab: 'deposit'
  }, target = '*') {
    window.top.postMessage(obj, target);
  }

  openHome(obj = {
    action: 'openSlider',
    tab: 'home'
  }, target = '*') {
    window.top.postMessage(obj, target);
  }

  verifyAccount(obj={
    action: 'openSlider',
    tab: 'settings',
    page: 'verifyaccount'
  }, target='*') {
    window.top.postMessage(obj, target);
  }


  async listen() {
    return await new Promise(resolve => {
      const eventHandler = event => {
        if (event.source === window.parent) {
            try {
                const data = JSON.parse(event.data);
                const { token } = data;
                if (token) {
                  PartnerModule.setItem(APP_CONSTANTS.TOKEN, token);
                }
                resolve();
            } catch (err) {
                console.log(err);
                resolve();
            }
        }
      };
      window.addEventListener('message', eventHandler);
      window.parent.postMessage("loaded", "*");
    });
  }

  remove(handler) {
    window.removeEventListener('message', handler);
  }

  isInIframe() {
    return process.env.REACT_APP_ENV === ENVIRONMENT_TYPE.PRODUCTION ? this.checkInIframeParameter() : this.isParentIframeExist();
  }

  isParentIframeExist() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  checkInIframeParameter() {
    return PartnerModule.getConfigItem(APP_CONSTANTS.IN_IFRAME) === 'true';
  }

  goToHome(){
    window.location.href = `${window.location.protocol}//${window.location.host.replace(/^([a-zA-Z]+)\./, '')}`
  }

}

const postMessageService = new PostMessageService();
export default postMessageService;
