import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';

class InfoOperations extends Operations{
  constructor(infoApiHandler){
    super();
    this.infoApiHandler = infoApiHandler;
  }

  async getInfo (store, action){
    const response = await this.infoApiHandler.getInfo(action.payload);
    return responseErrorCheck(response)
  }
}

export default InfoOperations;
