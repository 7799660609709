import React from 'react';
import {getDate} from '../../../core/helpers/dateHelpers';
import {EGameStatuses, ERoundStatuses} from '../../../core/constants/enums';
import useTranslation from '../../../core/customHooks/useTranslation';
import StatisticIcon from './statisticIcon';
import useGoogleAnalytics from '../../../core/customHooks/useGoogleAnalytics';
import { GA_CATEGORIES, GA_ACTIONS } from '../../../core/constants/googleAnalyticsConstants';
import t from '../../../core/staticData/translation';
import { TOOLTIP_TARGET_IDS } from '../../../core/constants/userTooltipGuide';
import {useSelector} from 'react-redux';

const GameHeader = ({ setShowBackSpace, status, date, point, currentMinute, hasPredictionResult, isRotateDisabled }) => {
  const translate = useTranslation();
  const { emitEvent } = useGoogleAnalytics();

  const isParticipant = useSelector(state => state.lobby.data.round.isParticipant);
  const roundStatus = useSelector(state => state.lobby.data.round.status);

  const [ day, time ] = getDate(date);
  const getRightSideContent = status => {
    switch(status) {
      case EGameStatuses.UPCOMING:
        return `${day}   ${time}`;
      case EGameStatuses.LIVE:
        return currentMinute? <span>{currentMinute}<span className='blinking' >'</span></span> : `${day}   ${time}`;
      case EGameStatuses.FINISHED:
        return translate(t.FINISHED);
      case EGameStatuses.CANCELED:
        return translate(t.CANCELED);
      case EGameStatuses.POSTPONED:
        return translate(t.POSTPONED);
      default:
        return `${day}   ${time}`;
    }
  };

  const getRightSideTitle = status => {
    switch(status) {
      case EGameStatuses.UPCOMING:
        return null;
      case EGameStatuses.LIVE:
        return null;
      case EGameStatuses.FINISHED:
        return translate(t.FINISHED);
      case EGameStatuses.CANCELED:
        return translate(t.CANCELED);
      case EGameStatuses.POSTPONED:
        return translate(t.POSTPONED);
      default:
        return null;
    }
  };

  const handlePointsClick = () => {
    setShowBackSpace(true);
    emitPointDetailsToGA();
  };

  const emitPointDetailsToGA = () => {
    emitEvent({
      category: GA_CATEGORIES.USAGE,
      action: GA_ACTIONS.POINT_DETAILS
    });
  };

  return (
    <div className="for-top-of-inside-game-list-i">
      <div className="top-of-inside-game-list-i">
        <div className="top-item-of-game-list-i">
          {status === EGameStatuses.UPCOMING ?
           <StatisticIcon setShowBackSpace={setShowBackSpace} isDisabled={isRotateDisabled} /> :
            (status === EGameStatuses.LIVE || status === EGameStatuses.FINISHED) && roundStatus !== ERoundStatuses.CANCELED && (hasPredictionResult || isParticipant)?
            <div
                className="flip-wr"
                data-tooltip={TOOLTIP_TARGET_IDS.EARNED_POINTS}
                onClick={handlePointsClick}
                title={`${point} ${translate(t.POINTS)}`}>
              <span className="bc-i-flip top-game-i-icon" />
              <span className="top-pts-spn">{point} {translate(t.PTS)}</span>
            </div>:
          null}
        </div>
        <div className="top-item-of-game-list-i" title={getRightSideTitle(status)}>{getRightSideContent(status)}</div>
      </div>
    </div>
  );
};

export default GameHeader;
