import { useContext } from 'react'
import { TranslationContext } from '../../contexts';


const useTranslation = () => {
  const translations = useContext(TranslationContext);

  /**
   * 
   * @param {string} key 
   * @param {object | undefined} values 
   * @returns {string} translation value
   */
  const translate = (key, values) => {
    let result = Object.keys(translations).length && translations[key] ? translations[key] : key;
    if (values) {
      Object.keys(values).forEach(key => {
        result = result.replaceAll(`{{${key}}}`, values[key]);
      });
    }
    return result;
  }

  return translate;
};

export default useTranslation
