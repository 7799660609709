import ApiHandler from '../../core/classes/ApiHandler';
import {API} from '../../core/constants/urls';
import TypeChecker from '../../core/helpers/TypeCheker';

class PredictorWebApiHandler extends ApiHandler {
  constructor(prefix){
    super(API, prefix)
  }
  /**
   * @name _checkResponse
   * @desc Check Response validator
   * @param response
   * @returns {{success}|Object}
   * @private
   */
  _checkResponse = (response) => {
    return response;
  };

  _createQuery(queryFields) {
    // when queryFields is undefined
    if (!queryFields) {
      return ''
    }

    // when queryFields is string or number
    if (typeof queryFields === 'string' || typeof queryFields === 'number') {
      return `${queryFields}`;
    }

    // when queryFields is array
    if (Array.isArray(queryFields)) {
      let query = '?';
      queryFields.forEach((queryField, index) => {
        if (index) {
          query += `&${queryField.key}=${queryField.value}`
        } else {
          query += `${queryField.key}=${queryField.value}`
        }
      });
      return query;
    }

    //when queryFields is object
    if (Object.keys(queryFields).length) {
      let searchQuery = '';
      for(let key in queryFields){
        if (Array.isArray(queryFields[key])) {
          queryFields[key].forEach(value => {
            searchQuery = `${searchQuery}&${key}=${value}`
          })
        } else if(!TypeChecker.isNull(queryFields[key]) && !TypeChecker.isUndefined(queryFields[key]) && !TypeChecker.isEmptyString()){
          searchQuery = `${searchQuery}&${key}=${queryFields[key]}`
        }
      }
      return `?${searchQuery.substring(1)}`;
    }
    return ;
  }

  /**
   * Override Get Method
   * @name get
   * @param endPoint
   * @param queryFields : string , array or object -> {key: '', value: ''}
   * @returns {Promise<Response>}
   */
  get(endPoint = '', queryFields = '', signal) {
    return super.get(`${this.domain}/${this.prefix}/${endPoint ? endPoint + '/' : ''}${this._createQuery(queryFields)}`, signal);
  }

  /**
   * Override post Method
   * @name post
   * @param endPoint
   * @param queryFields
   * @param formData
   * @returns {*}
   */
  post(endPoint, formData = {}, queryFields = '', signal, isFormData) {
    return super.post(`${this.domain}/${this.prefix}/${endPoint ? endPoint + '/' : ''}${this._createQuery(queryFields)}`, formData, signal, isFormData);
  }


  /**
   * Override put Method
   * @name put
   * @param endPoint
   * @param queryFields
   * @param formData
   * @returns {Promise<Response>}
   */
  put(endPoint, formData, queryFields = '', signal) {
    return super.put(`${this.domain}/${this.prefix}/${endPoint}${this._createQuery(queryFields)}`, formData, signal);
  }

  /**
   * Override delete Method
   * @name delete
   * @param endPoint
   * @param queryFields
   * @returns {Promise<Response>}
   */
  delete(endPoint, queryFields = '', signal) {
    return super.delete(`${this.domain}/${this.prefix}/${endPoint}${this._createQuery(queryFields)}`, signal);
  }
}

export default PredictorWebApiHandler;
