import { USERS_TYPES } from '../../core/constants/actionTypes';

export const cntrlGetUserInfo = payload => ({
  type: USERS_TYPES.GET_USER_INFO.CNTRL,
  payload,
});

export const rxGetUserInfoDone = payload => ({
  type: USERS_TYPES.GET_USER_INFO.DONE,
  payload,
});

export const rxGetUserInfoPending = payload => ({
  type: USERS_TYPES.GET_USER_INFO.PENDING,
  payload,
});

export const cntrlPostUserInfo = payload => ({
  type: USERS_TYPES.POST_USER_INFO.CNTRL,
  payload,
});

export const cntrlCheckUserName = (payload, isSameNickname) => ({
  type: USERS_TYPES.CNTRL_CHECK_USERNAME,
  payload,
  isSameNickname,
});

export const rxSetNickNameError = payload => ({
  type: USERS_TYPES.RX_SET_NICKNAME_ERROR_BOOL,
  payload,
});

export const cntrlGenerateNickname = payload => ({
  type: USERS_TYPES.CNTRL_GENERATE_NICKNAME,
  payload,
});

export const cntrlChangeChristmasMood = payload => ({
  type: USERS_TYPES.CNTRL_CHANGE_CHRISTMAS_MOOD,
  payload
});


