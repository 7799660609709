import socialMediaApiHandler from '../../services/api/socialMediaApiHandler';
import SocialMediaOperations from '../../services/operations/socialMediaOperations';
import SocialMediaController from '../../controllers/socialMediaController';
import { SOCIAL_MEDIA_TYPES } from '../../core/constants/actionTypes';

const socialMediaOperations = new SocialMediaOperations(socialMediaApiHandler);
const socialMediaController = new SocialMediaController(socialMediaOperations);

const socialMediaConfig = {
  [SOCIAL_MEDIA_TYPES.CNTRL_GET_SHARED_TOKEN] : socialMediaController.getSharedToken,
};

export default socialMediaConfig;
