import PartnerController from '../../controllers/partner';
import PartnerOperations from '../../services/operations/partnerOperations';
import partnerApiHandler from '../../services/api/partnerApiHandler';
import { PARTNER_TYPES } from '../../core/constants/actionTypes';

/** operations*/
const partnerOperation = new PartnerOperations(partnerApiHandler);

/** controller*/
const partnerController = new PartnerController(partnerOperation);

const partnerConfig = {
  [PARTNER_TYPES.GET_CURRENT_GLOBAL_ROUND.CNTRL]: partnerController.getCurrentGlobalRound,
  [PARTNER_TYPES.CNTRL_GET_PARTNER_COLORS]: partnerController.getPartnerColors,
  [PARTNER_TYPES.GET_PARTNER_SETTINGS.CNTRL]: partnerController.getPartnerSettings,
  [PARTNER_TYPES.GET_COMPETITIONS.CNTRL]: partnerController.getCompetitions,
};


export default partnerConfig;
