import PredictorApiHandler from '../pedictorWebApiHandler.js';
import PartnerModule from '../../../core/moduls/PartnerModul';
import {APP_CONSTANTS} from '../../../core/constants/constants';
import {abortFetch} from '../../../core/helpers/abortController';
import ABORT_API_KEYS from '../../../core/constants/abortApiKeys';

const END_POINT = {
  prefix : 'Statistics',
  competitionsTables: 'competitionsTables',
  matchStat: 'matchStat',
  competitionTable: 'competitionTable',
};

class StatisticsApiHandler extends PredictorApiHandler{
  constructor(prefix){
    super(prefix);
  }

  getCompetitionsTables(query) {
    return this.get(END_POINT.competitionsTables, query);
  }

  getMatchStat (matchId) {
    const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);
    return this.get(`${END_POINT.matchStat}/${matchId}`, { partnerId });
  }

  getCompetitionTable(competitionId) {
    const abortSignal = abortFetch(ABORT_API_KEYS.getCompetitionTable);
    const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);
    return this.get(`${END_POINT.competitionTable}/${competitionId}`, { partnerId }, abortSignal);
  }
}


const statisticsApiHandler = new StatisticsApiHandler(END_POINT.prefix);
export default statisticsApiHandler;
