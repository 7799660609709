import TypeChecker from './TypeCheker';

export const combineQuery = (queries, n) => {
  if (typeof queries === 'string') {
    return queries;
  } else {
    let query = '?';
    for (let key in queries) {
      if (key) {
        if (Array.isArray(queries[key])) {
          queries[key].forEach(val => {
            query += `${key}=${val}&`
          })
        } else {
          if(!TypeChecker.isUndefined(queries[key]) && !TypeChecker.isNull(queries[key]) && !TypeChecker.isEmptyString(queries[key])) {
            query += `${key}=${queries[key]}&`
          }
        }
      }
    }
    return query.slice(0, -1);
  }
};
