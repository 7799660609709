import {
    TOOLTIP_TYPES,
    TOOLTIP_TARGET_IDS,
    SCROLLABLE_CHILDREN,
    SCROLLABLE_WRAPPERS,
    TOOLTIP_ARROW_POSITIONS
} from './userTooltipGuide';
import t from '../staticData/translation.json';
import { MOBILE_TABS, NAVIGATION_ITEMS } from './navigationConstants';
import { SelectionTypes } from './enums';

export const TOOLTIP_HINT_TYPES = {
    SCORE_PREDICTION: SelectionTypes.CorrectScore,
    FIRST_TEAM_TO_SCORE_PREDICTION: SelectionTypes.FirstTeamToScore,
    DOUBLE_PREDICTION: 'DOUBLE_PREDICTION',
    EDIT_BUTTON: 'EDIT_BUTTON'
};

export const TOOLTIP_HINTS = {
    [TOOLTIP_HINT_TYPES.SCORE_PREDICTION]: {
        type: TOOLTIP_TYPES.SCORE_PREDICTION,
        description: t.SCORE_PREDICTION_PROMPT,
        title: t.SCORE_PREDICTION,
        scrollWr: SCROLLABLE_WRAPPERS.HOME,
        calcScrollFrom: SCROLLABLE_CHILDREN.GAME_ITEM,
        web: {
            targetElemId: TOOLTIP_TARGET_IDS.SCORE_PREDICTION,
            arrow: TOOLTIP_ARROW_POSITIONS.TOP_CENTER
        },
        mobile: {
            targetElemId: TOOLTIP_TARGET_IDS.SCORE_PREDICTION,
            arrow: TOOLTIP_ARROW_POSITIONS.TOP_CENTER,
            navigationItem: NAVIGATION_ITEMS.HOME,
            activeTab: MOBILE_TABS.HOME
        }
    },
    [TOOLTIP_HINT_TYPES.FIRST_TEAM_TO_SCORE_PREDICTION]: {
        type: TOOLTIP_TYPES.FIRST_TEAM_TO_SCORE_PREDICTION,
        description: t.FIRST_TEAM_TO_SCORE_PREDICTION_PROMPT,
        title: t.FIRST_TEAM_TO_SCORE_PREDICTION,
        scrollWr: SCROLLABLE_WRAPPERS.HOME,
        calcScrollFrom: SCROLLABLE_CHILDREN.GAME_ITEM,
        web: {
            targetElemId: TOOLTIP_TARGET_IDS.FIRST_TEAM_TO_SCORE_PREDICTION,
            arrow: TOOLTIP_ARROW_POSITIONS.BOTTOM_RIGHT
        },
        mobile: {
            targetElemId: TOOLTIP_TARGET_IDS.FIRST_TEAM_TO_SCORE_PREDICTION,
            arrow: TOOLTIP_ARROW_POSITIONS.BOTTOM_RIGHT,
            navigationItem: NAVIGATION_ITEMS.HOME,
            activeTab: MOBILE_TABS.HOME
        }
    },
    [TOOLTIP_HINT_TYPES.DOUBLE_PREDICTION]: {
        type: TOOLTIP_TYPES.X2_PREDICTION,
        description: t.X2_SELECTION_PROMPT,
        title: t.X2_SELECTION,
        scrollWr: SCROLLABLE_WRAPPERS.HOME,
        calcScrollFrom: SCROLLABLE_CHILDREN.GAME_ITEM,
        web: {
            targetElemId: TOOLTIP_TARGET_IDS.X2_PREDICTION,
            arrow: TOOLTIP_ARROW_POSITIONS.BOTTOM_CENTER
        },
        mobile: {
            targetElemId: TOOLTIP_TARGET_IDS.X2_PREDICTION,
            arrow: TOOLTIP_ARROW_POSITIONS.BOTTOM_CENTER,
            navigationItem: NAVIGATION_ITEMS.HOME,
            activeTab: MOBILE_TABS.HOME
        }
    },
    [TOOLTIP_HINT_TYPES.EDIT_BUTTON]: {
        type: TOOLTIP_TYPES.EDIT_BUTTON,
        description: t.EDIT_DESC,
        title: t.EDIT_TITLE,
        web: {
            targetElemId: TOOLTIP_TARGET_IDS.BET_OR_FREE_BUTTON,
            arrow: TOOLTIP_ARROW_POSITIONS.BOTTOM_CENTER
        },
        mobile: {
            targetElemId: TOOLTIP_TARGET_IDS.BET_OR_FREE_BUTTON_MOBILE,
            arrow: TOOLTIP_ARROW_POSITIONS.BOTTOM_CENTER,
            navigationItem: NAVIGATION_ITEMS.HOME,
            activeTab: MOBILE_TABS.HOME
        }
    }
};
