import React from 'react';
import t from '../../../core/staticData/translation';
import useTranslation from '../../../core/customHooks/useTranslation';

const VerificationPopup = ({ setModalState }) => {
  const translate = useTranslation();

  const handleGoClick = () => {
    setModalState('');
  };

  return (
    <div className="wr-user-popup-layout"  style={{maxHeight: 310}} id='userPopup'>
      <div className="user-popup-layout">
        <div className="top-of-user-popup-layout">
          <div className="popup-profile-header">
            <p className="popup-profile-name" title={translate(t.NOTICE)}>{translate(t.NOTICE)}</p>
            <div className="popup-profile-header-icon"
                 onClick={()=>setModalState('')}
            >
              <span className="bc-i-close"/>
            </div>
          </div>
        </div>
        <div className="bottom-of-user-popup-layout">
          <div className="scroll-wr">
            <div className="popup-profile-content">
              <p className="popup-profile-avatar" style={{whiteSpace: 'initial', height: 100}}>{translate(t.IT_IS_NECESSARY_TO_VERIFY_YOUR_ACCOUNT)}</p>
              {/*TODO replace text with key*/}
              <div className="popup-profile-nichname-d">
                <div className="popup-profile-button-d" >
                  <button
                    className='popup-profile-button'
                    title={translate(t.GO)}
                    onClick={handleGoClick}
                  >
                    {translate(t.GO)}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationPopup;
