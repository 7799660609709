import React, { useState, useEffect, useContext } from 'react';
import TeamChooseBlock from './teamChooseBlock';
import TeamIconsBlock from './teamIconsBlock';
import NoneScoreSelectedBlock from './noneScoreSelectedBlock';
import TeamSelectedBlock from './teamSelectedBlock';
import OpenSelectTeamBlock from './openSelectTeamBlock';
import useDidUpdateEffect from '../../../core/customHooks/useDidUpdateEffect';
import { getUserInfo } from '../../../state-management/accessors/usersAccessors';
import { getUserId } from '../../../state-management/accessors/lobbyAccessors';
import { connect } from 'react-redux';
import { EGameStatuses } from '../../../core/constants/enums';
import { getPartnerSettings } from '../../../state-management/accessors/partnerAccessors';
import {FieldStateContext} from '../../../contexts';
import { TOOLTIP_TARGET_IDS } from '../../../core/constants/userTooltipGuide';

const UpcomingGameFooter = ({ homeTeam,
                              awayTeam,
                              predictionsForBet,
                              setPredictionsForBet,
                              onPredictionsClick,
                              status,
                              roundMatchId,
                              usesLogoImages,
                              clearAll,
                              currentUserId,
                              tooltipValidationData,
                              index,
                              showPredictionTooltips,
                              user: { id } }) => {

  const [ active, setActive ] = useState('');
  const showFooter = currentUserId === id && status === EGameStatuses.UPCOMING ;
  const homeTeamUrl = usesLogoImages ? homeTeam.imageUrl : homeTeam.jerseyUrl;
  const awayTeamUrl = usesLogoImages ? awayTeam.imageUrl : awayTeam.jerseyUrl;

  const { fieldIsDisabled } = useContext(FieldStateContext);


  const prediction = predictionsForBet[roundMatchId];
  const ftts = prediction?.ftts;

  const getComponentObj = {
    0: <NoneScoreSelectedBlock setActive={setActive} onDisableClick={onPredictionsClick} />,
    1: <TeamSelectedBlock setActive={setActive} onDisableClick={onPredictionsClick} imageUrl={homeTeamUrl} title={homeTeam.shortName && homeTeam.shortName.length ? homeTeam.shortName : homeTeam.name} />,
    2: <TeamSelectedBlock setActive={setActive} onDisableClick={onPredictionsClick} imageUrl={awayTeamUrl} title={awayTeam.shortName && awayTeam.shortName.length ? awayTeam.shortName : awayTeam.name} />
  };

  const [ component, setComponent ] = useState( getComponentObj[ftts] || <OpenSelectTeamBlock setActive={setActive} />);

  const addFtts = ftts => {
    setPredictionsForBet(prev => ({
      ...prev,
      [roundMatchId]: {
        ...prev[roundMatchId],
        ftts
      }
    }));
    setActive('');
  };

  useEffect(() => {
    let timer = null;
    if( active ) {
      timer = setTimeout(() => {
        setActive('');
      }, 30000)
    }
    return () => clearTimeout(timer);
  }, [active]);

  useDidUpdateEffect(() => {
    setComponent(getComponentObj[ftts] || <OpenSelectTeamBlock setActive={setActive} />);
    setActive('');
  }, [ftts]);

  useDidUpdateEffect(() => {
    setComponent(<OpenSelectTeamBlock setActive={setActive}/>);
    setActive('');
  }, [clearAll]);

  useDidUpdateEffect(() => {
    if (fieldIsDisabled && active) {
      setActive('');
    }
  }, [fieldIsDisabled]);

  useDidUpdateEffect(() => {
    if (tooltipValidationData && index === tooltipValidationData.index) {
      setActive('');
    }
  }, [tooltipValidationData]);


  return (
    <div className="bottom-bottom-inside-game-list">
      <div
        className={`bottom-game-list-wrapper ${active}`}
      >{/*add/remove class active*/}
        { showFooter &&
        <div className="inside-bottom-wr-bottom-game-list">
          <TeamChooseBlock homeTeam={homeTeam} awayTeam={awayTeam} addFtts={addFtts}/>
          <TeamIconsBlock>
            {component}
          </TeamIconsBlock>
        </div>}
      </div>
      {
        showPredictionTooltips &&
        <span className="invisible-plus" data-tooltip={TOOLTIP_TARGET_IDS.FIRST_TEAM_TO_SCORE_PREDICTION} />
      }
      <span className="bottom-line"/>
    </div>
  )
};

const mapStateToProps = state => ({
  user: getUserInfo(state),
  currentUserId: getUserId(state),
  usesLogoImages: getPartnerSettings(state).usesLogoImages,
});

export default connect(mapStateToProps)(UpcomingGameFooter);
