import React, {useContext, useEffect} from 'react';
import withPartnerSettings from '../../../hocs/withPartnerSettingsHoc';
import {PARTNER_SETTINGS_CONSTANTS} from '../../../core/constants/partnerSettingsConstants';
import PartnerModule from '../../../core/moduls/PartnerModul';
import {APP_CONSTANTS} from '../../../core/constants/constants';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {getGlobalRound} from '../../../state-management/accessors/partnerAccessors';
import {NavigationContext} from '../../../contexts';
import {cntrlGetGlobalLobby} from '../../../state-management/actions/lobbyActions';
import {getStartAndEndDate} from '../../../core/helpers/dateHelpers';
import {getLobby} from '../../../state-management/accessors/lobbyAccessors';
import {useHistory, useLocation} from 'react-router';
import {combineQuery} from '../../../core/helpers/combineQuery';
import {parseQuery} from '../../../core/helpers/parseQuery';
import {MOBILE_TABS, NAVIGATION_ITEMS} from '../../../core/constants/navigationConstants';
import useTranslation from '../../../core/customHooks/useTranslation';
import {ERoundStatuses} from '../../../core/constants/enums';


const HistoryItemGlobal = ({ selected, getCurrentGlobalRound, getGlobalLobby,  globalRound: { name, status } }) => {
  const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);
  const history = useHistory();
  const location = useLocation();

  const translate = useTranslation();

  const {
    setActiveTab,
    setActiveNavItem
  } = useContext(NavigationContext);

  useEffect(() => {
    // getCurrentGlobalRound()
  }, []);

  const handleClick = () => {
      getGlobalLobby({ partnerId, skip: 0, take: 30 });
      history.push(combineQuery({
          ...parseQuery(location.search),
          [APP_CONSTANTS.GLOBAL_ROUND]: true,
          [APP_CONSTANTS.ROUNDID]: null,
          [APP_CONSTANTS.USERID]: null,
        }
      ));
    setActiveTab(MOBILE_TABS.HOME);
    setActiveNavItem(NAVIGATION_ITEMS.HOME);
  };

  return (
    <div className="history-item-wr">
      <div className={`history-item ${selected? 'selected' : ''} global have-status`}
          onClick={handleClick}
          title={name}
      >
        <p className="status-txt">{translate(ERoundStatuses[status])}</p>
        <p className="history-game-info"  >{name} </p>
      </div>
    </div>
  );
};


const mapStateToProps = state => ({
  globalRound: getGlobalRound(state),
  selected: !!getLobby(state).data.globalRound,
});

const mapDispatchToProps = dispatch => ({
  // getCurrentGlobalRound: () => dispatch(cntrlGetCurrentGlobalRound(PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID))),
  getGlobalLobby: payload => dispatch(cntrlGetGlobalLobby(payload)),
});



export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPartnerSettings(PARTNER_SETTINGS_CONSTANTS.HAS_GLOBAL_ROUND)
)(HistoryItemGlobal);
