import React from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation.json';

const GuidePopupHeader = ({ title, onClose }) => {

    const translate = useTranslation();
    const titleTr = translate(title);

    return (
        <div className="tooltip-inner-top">
            <div className="inner-tooltip-inner-top">
                <div className="inner-tooltip-inner-top-left">
                    <p className="inner-tooltip-inner-top-left-txt" title={titleTr}>
                        { titleTr }
                    </p>
                </div>
                <div className="inner-tooltip-inner-top-right">
                    <div
                        onClick={onClose}
                        className="close-icon-block"
                        title={translate(t.CLOSE)}
                    >
                        <span className="bc-i-close"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GuidePopupHeader;