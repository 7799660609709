import React, { useEffect } from 'react';
import DistributionTable from './distributionTable';
import { connect } from 'react-redux';
import {cntrlGetCurrentDistributions, cntrlGetPrizeInfo} from '../../../state-management/actions/prizeActions';
import {getCurrentDistributions, getPrizeInfo} from '../../../state-management/accessors/prizeInfoAccessor';
import Loader from '../loader';
import PrizeInfoBlock from './prizeInfoBlock';
import removeHTMLTags from '../../../core/helpers/removeHTMLTags';
import {getCurrencySign} from '../../../core/helpers/getCurrency';
import PartnerModule from '../../../core/moduls/PartnerModul';
import {APP_CONSTANTS} from '../../../core/constants/constants';

const Prizes = ({ getPrizeInfo,
                  getCurrentDistributions,
                  currentDistributions,
                  prizeInfo: {isLoading, data: {prizeInfos}} }) => {

  useEffect(() => {
    !prizeInfos.length && getPrizeInfo();
    getCurrentDistributions();
  }, []);

  const { data: { globalDistribution, localDistribution } } = currentDistributions;
  const currencySign = getCurrencySign();

  return (
    <div  className="popup-bottom-wr">
      {isLoading? <Loader/> :
        prizeInfos.map(prizeInfo => {
          switch (removeHTMLTags(prizeInfo.title).slice(0, -1)){
            case 'Round Distribution Table':
              return <DistributionTable title='round distribution'
                                        key={prizeInfo.priority}
                                        distribution={localDistribution}
                                        currencySign={currencySign}/>;
            case 'Global Distribution Table':
              return <DistributionTable title='global distribution'
                                        key={prizeInfo.priority}
                                        distribution={globalDistribution}
                                        currencySign={currencySign} />
            default:
              return <PrizeInfoBlock title={prizeInfo.title}
                                description={prizeInfo.description}
                                key={prizeInfo.priority}/>
          }
        })}
    </div>
  );
}

const mapStateToProps = state => ({
  prizeInfo: getPrizeInfo(state),
  currentDistributions: getCurrentDistributions(state),
});

const mapDispatchToProps = dispatch => ({
  getPrizeInfo: () => dispatch(cntrlGetPrizeInfo({orderExpression: 'priority', isEnable: true, partnerId: PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID)})),
  getCurrentDistributions: () => dispatch(cntrlGetCurrentDistributions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Prizes);



