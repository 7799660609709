import RoundOperations from '../../services/operations/roundOperations';
import roundApiHandler from '../../services/api/roundApiHandler';
import RoundController from '../../controllers/roundController';
import {ROUND_TYPES} from '../../core/constants/actionTypes';

const roundOperations = new RoundOperations(roundApiHandler);
const roundController = new RoundController(roundOperations);

const roundConfig = {
  [ROUND_TYPES.CNTRL_GET_DISTRIBUTION]: roundController.getDistribution,
  [ROUND_TYPES.CNTRL_BET]: roundController.bet,
};

export default roundConfig;
