/**
 * @name Operations
 * @desc this is an abstraction of operations services
 */
class Operations {

  // -- Contructor -- //

  /**
   * @name constructor
   */


// -- Logic -- //

}

export default Operations;