import {PRIZE_INFO_TYPES} from '../../core/constants/actionTypes';

export const cntrlGetPrizeInfo = payload => ({
  type: PRIZE_INFO_TYPES.GET_PRIZE_INFO.CNTRL,
  payload,
});

export const rxGetPrizeInfoPending = payload => ({
  type: PRIZE_INFO_TYPES.GET_PRIZE_INFO.PENDING,
  payload,
});

export const rxGetPrizeInfoDone = payload => ({
  type: PRIZE_INFO_TYPES.GET_PRIZE_INFO.DONE,
  payload,
});

export const cntrlGetCurrentDistributions = payload => ({
  type: PRIZE_INFO_TYPES.GET_CURRENT_DISTRIBUTIONS.CNTRL,
  payload,
});

export const rxGetCurrentDistributionsPending = payload => ({
  type: PRIZE_INFO_TYPES.GET_CURRENT_DISTRIBUTIONS.PENDING,
  payload,
});

export const rxGetCurrentDistributionsDone = payload => ({
  type: PRIZE_INFO_TYPES.GET_CURRENT_DISTRIBUTIONS.DONE,
  payload,
});
