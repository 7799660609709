import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationContext } from '../../../contexts';
import { LS_KEYS } from '../../../core/constants/localStorageKeys';
import { SMART_PICK_AND_CLEAR_ALL, MAIN_TOOLTIP_GUIDE_STEPS } from '../../../core/constants/userTooltipGuide';
import { isMobile } from '../../../core/helpers/isMobile';
import localStorageService from '../../../services/services/LocalStorageService';
import { getIsAuth } from '../../../state-management/accessors/appAccessors';
import GuidePopup from './guidePopup';
import { cntrlPostUserInfo } from '../../../state-management/actions/usersActions'
import { scrollIntoView } from '../../../core/helpers/scrollIntoView';
import { getParentNodes } from '../../../core/helpers/getParentNodes';
import { getLobby, getRoundInfo } from '../../../state-management/accessors/lobbyAccessors';
import { EGameStatuses } from '../../../core/constants/enums';
import { LEADERBOARD_TABS, LEFT_COLUMN_ACTIVE_TABS } from '../../../core/constants/navigationConstants';
import { KEY_CODES } from '../../../core/constants/keyboardKeyCodes';
import { createPortal } from 'react-dom';
import macrotask from '../../../core/helpers/macrotask';

const UserTooltipGuide = ({ onComplete, preventHotKeys })=> {

    const dispatch = useDispatch();
    const isAuth = useSelector(getIsAuth);
    const round = useSelector(getRoundInfo);
    const isRoundGlobal = !!useSelector(getLobby).data.globalRound;
    const scrollWrapperRef = useRef(null);
    const allSteps = useRef([...MAIN_TOOLTIP_GUIDE_STEPS]);

    const {
        setActiveTab,
        activeNavItem,
        setActiveNavItem,
        activeLeaderBoardTab,
        setLeftColumnActiveTab,
        setActiveLeaderBoardTab
    } = useContext(NavigationContext);

    const stepDirectionRef = useRef(1);
    const smartPickAndClearAllAdded = useRef(false);
    const [ target, setTarget ] = useState(null);
    const [ stepIndex, setStepIndex ] = useState(0);
    const currentStep = allSteps.current[stepIndex];

    const isArrowDisabled = useRef({
        prev: stepIndex === 0,
        next: stepIndex === allSteps.current.length - 1,
        keyUpDisabled: false
    });

    useEffect(() => {
        addKeyUpListener();
        return () => {
            removeKeyUpListener();
        };
    }, [preventHotKeys]);

    useEffect(() => {
        if (!isRoundGlobal
            && round.status === EGameStatuses.UPCOMING
            && !smartPickAndClearAllAdded.current) {
            allSteps.current = [...MAIN_TOOLTIP_GUIDE_STEPS, ...SMART_PICK_AND_CLEAR_ALL];
            smartPickAndClearAllAdded.current = true;
        }
    }, [round, isRoundGlobal]);

    useEffect(() => {
        if (!currentStep) {
            isAuth
                ? dispatch(cntrlPostUserInfo({ isToolTipDisplayed: true, dontUpdate: true }))
                : localStorageService.set(LS_KEYS.IS_TOOLTIP_DISPLAYED, true);
            removeKeyUpListener();
            onComplete();
            return;
        }
        isArrowDisabled.current.prev = stepIndex === 0;
        isArrowDisabled.current.next = stepIndex === allSteps.current.length - 1;
        const isDeviceMobile = isMobile();
        const { mobile, web, scrollWr, calcScrollFrom } = currentStep;
        const webOrMobileData = isDeviceMobile ? mobile : web;
        const elemId = isDeviceMobile ? mobile.targetElemId : web.targetElemId;
        const target = document.querySelector(`[data-tooltip=${elemId}]`);
        scrollWrapperRef.current = document.querySelector(`[data-scroll-wr=${scrollWr}]`);
        const scrollCalcElem = getParentNodes(target).find(elem => {
            return elem.getAttribute && elem.getAttribute('data-scroll-child') === calcScrollFrom;
        });
        if (!target && stepIndex < allSteps.current.length) {
            setStepIndex(prev => prev + stepDirectionRef.current);
            return;
        }
        if (
            isDeviceMobile
            || webOrMobileData.changeLeaderBoardTab
            || webOrMobileData.changeRoundTab
            || webOrMobileData.changeTableTab ) {
            changeTab(webOrMobileData, target);
        } else {
            setTarget(target);
        }
        macrotask(() => {
            scrollIntoView(scrollWrapperRef.current, scrollCalcElem || target);
        });
    }, [stepIndex]);

    const handleNext = () => {
        stepDirectionRef.current = 1;
        setStepIndex(prev => prev + 1);
    };

    const handlePrev = () => {
        stepDirectionRef.current = -1;
        setStepIndex(prev => prev - 1);
    };

    const handleClose = () => {
        setStepIndex(-1);
    };

    const addKeyUpListener = () => {
        window.addEventListener('keyup', keyUpHandler);
    };

    const removeKeyUpListener = () => {
        window.removeEventListener('keyup', keyUpHandler);
    };

    const debounceKeyUp = () => {
        isArrowDisabled.current.keyUpDisabled = true;
        setTimeout(() => {
            isArrowDisabled.current.keyUpDisabled = false;
        }, 500);
    };
    const keyUpHandler = event => {
        if (preventHotKeys) return;
        const { code } = event;
        const { prev, next, keyUpDisabled } = isArrowDisabled.current;
        switch (code) {
            case KEY_CODES.left:
                if (prev || keyUpDisabled) return;
                debounceKeyUp();
                handlePrev();
                break;
            case KEY_CODES.right:
                if (next || keyUpDisabled) return;
                debounceKeyUp();
                handleNext();
                break;
            default:
                break;
        }
    };

    const changeTab = (data, target) => {
        const { activeTab, navigationItem, changeLeaderBoardTab, changeRoundTab, changeTableTab } = data;
        const shouldOpenLeaderBoardTab = changeLeaderBoardTab && activeLeaderBoardTab !== LEADERBOARD_TABS.LEADERBOARD;
        if ((activeNavItem !== navigationItem) || shouldOpenLeaderBoardTab || changeRoundTab || changeTableTab) {
            if (shouldOpenLeaderBoardTab) {
                setActiveLeaderBoardTab(LEADERBOARD_TABS.LEADERBOARD);
            } else if (changeRoundTab) {
                setLeftColumnActiveTab(LEFT_COLUMN_ACTIVE_TABS.ROUNDS);
            } else if (changeTableTab) {
                setLeftColumnActiveTab(LEFT_COLUMN_ACTIVE_TABS.TABLES);
            }
            setActiveNavItem(navigationItem);
            setActiveTab(activeTab);
            setTarget(target);
        } else {
            setTarget(target);
        }
    };

    if (!currentStep || !target) {
        return null;
    }

    const getTargetElement = () => {
        const { mobile, web } = currentStep;
        const elemId = isMobile() ? mobile.targetElemId : web.targetElemId;
        return document.querySelector(`[data-tooltip=${elemId}]`);
    };

    const targetElem = getTargetElement();

    if (!targetElem) {
        return null;
    }

    return (
        <>
            {
                createPortal(
                    <GuidePopup
                        target={target}
                        onNext={handleNext}
                        onPrev={handlePrev}
                        onClose={handleClose}
                        currentStep={currentStep}
                        isPrevDisabled={isArrowDisabled.current.prev}
                        isNextDisabled={isArrowDisabled.current.next}
                    />,
                    targetElem
                )
            }
        </>
    );
}

export default UserTooltipGuide;