const VALIDATIONS = {
  IMAGE : /\.(git|jpeg|png|svg)$/,
  IMAGE_URL : /^(https\:\/\/|http\:\/\/)[a-zA-Z\/\.0-9]*\.(git|jpeg|png|svg)$/,
  PROFILE_PAGE_INPUT: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
  NICKNAME_INPUT: /^[\x21-\x7F]{0,30}$/,
  NICKNAME_SUBMIT: /^[\x21-\x7F]{4,30}$/,
  NUMBER: /^[1-9]?[0-9]?$/,
  STARTING_WITH_ZEROS: /^0[0-9].*$/,
};

export default VALIDATIONS;
