import React from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation';

const DistributionTable = ({ title, distribution, currencySign }) => {
  const translate = useTranslation();

  return (
    <>
      <div className="d-box-place box3-place">
        <span className="s-pl-f">{title}</span>
        <p className="p-pl-euro">
        </p>
      </div>
      <div className="d-max max-prizes">
        <div className="d-max-title-table max-tab">
          <div className="d-max-title-table-cell">{translate(t.PLACES)}</div>
          <div className="d-max-title-table-cell">{translate(t.WINNING_FREE_BET)}</div>
        </div>
        {distribution.rows.map(item => {
          const { places, winning } = item;
          return (
            <div className="d-max-table max-tab" key={item.places}>
              <div className="d-max-table-cell">{places}</div>
              <div className="d-max-table-cell">{winning} {currencySign}</div>
            </div>
          );
        })}
      </div>
    </>
  )
};

DistributionTable.defaultProps = {
  distribution: {
    rows: [],
  },
};

export default DistributionTable;
