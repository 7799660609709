import Controller from '../../core/classes/AppController';
import {
  cntrlCreateLeaderboardConnection,
  cntrlCreateLiveMatchesConnection,
  cntrlCreatePredictionsConnection, cntrlCreateRoundsConnection, cntrlSetLeaderBoardPosition, cntrlSetPartnerId,
  cntrlSetRoundAndUser
} from '../../state-management/actions/signalRActions';
import {isConnected} from '../../core/helpers/signalRConnectionChecker';
import PartnerModule from '../../core/moduls/PartnerModul';
import {APP_CONSTANTS} from '../../core/constants/constants';

const SIGNAL_R_CONSTANTS = {
  PREDICTION_STORED: 'PredictionStored',
  MATCH_UPDATE: 'matchUpdate',
  PREDICTION_RESULT_UPDATE: 'predictionResultUpdate',
  LEADERBOARD_UPDATE: 'leaderboardUpdate',
  ROUND_STATUS_CHANGED: 'RoundStatusChanged',
  SET_ROUND_USER: 'SetRoundUser',
  SET_ROUND_LEADERBOARD_POSITION: 'SetRoundLeaderboardPosition',
  SET_PARTNER_ID: 'SetPartnerId',
  ROUND_CREATED: 'RoundCreated',
  ROUND_PRIZE_FUND_CHANGED: 'RoundPrizeFundChanged',
  ROUND_ENTRIES_CHANGED: 'RoundEntriesChanged'
};


class SignalRController extends Controller {

  constructor(signalROperations) {
    super();
    this.signalROperations = signalROperations;
  }

  createPredictionsConnection = (store, action) => {
    this.predictionsConnection = this.signalROperations.createPredictionsConnection();

    this.predictionsConnection.on(SIGNAL_R_CONSTANTS.PREDICTION_STORED, data => {
      this.signalROperations.updatePredictions(store, data);
    });

    this.predictionsConnection.onclose(error => {
      console.log(error, 'predictionConnection Error');
      store.dispatch(cntrlCreatePredictionsConnection());
    });

    this.predictionsConnection.start().catch(() => {
      console.log('Failed to start predictions connection');
      setTimeout(() => {
        store.dispatch(cntrlCreatePredictionsConnection());
      }, 5000);
    });
  };

  createLiveMatchesConnection = async(store, action) => {
    this.liveMatchesConnection = this.signalROperations.createLiveMatchesConnection();

    this.liveMatchesConnection.on(SIGNAL_R_CONSTANTS.MATCH_UPDATE, data => {
      this.signalROperations.updateLiveMatch(store, data);
    });

    this.liveMatchesConnection.on(SIGNAL_R_CONSTANTS.PREDICTION_RESULT_UPDATE, data => {
      this.signalROperations.updatePredictionResult(store, data);
    });

    this.liveMatchesConnection.onclose(error => {
      console.log(error, 'liveMatchesConnection Error');
      store.dispatch(cntrlCreateLiveMatchesConnection())
        .then(() => {
          const roundId = store.getState().lobby.data.round.id;
          const userId = store.getState().lobby.data.user?.id;
          const isGlobal = !!store.getState().lobby.data.globalRound;
          store.dispatch(cntrlSetRoundAndUser({
            roundId,
            userId,
            isGlobal
          }));
        });
    });

    await this.liveMatchesConnection.start()
      .catch(() => {
        console.log('Failed to start liveMatchesConnection');
        setTimeout(() => {
          store.dispatch(cntrlCreateLiveMatchesConnection())
            .then(() => {
              const roundId = store.getState().lobby.data.round.id;
              const userId = store.getState().lobby.data.user?.id;
              const isGlobal = !!store.getState().lobby.data.globalRound;
              store.dispatch(cntrlSetRoundAndUser({
                roundId,
                userId,
                isGlobal
              }));
            });
        }, 5000);
      });
  };

  createLeaderBoardConnection = async(store, action)=> {
    this.leaderBoardConnection = this.signalROperations.createLeaderBoardConnection();

    this.leaderBoardConnection.on(SIGNAL_R_CONSTANTS.LEADERBOARD_UPDATE, data => {
      this.signalROperations.updateLeaderBoard(store, data);
    });

    this.leaderBoardConnection.onclose(error => {
      console.log(error, 'leaderBoardConnection Error');
      store.dispatch(cntrlCreateLeaderboardConnection())
        .then (() => {
          const roundId = store.getState().lobby.data.round.id;
          const isGlobal = !!store.getState().lobby.data.globalRound;
          const length = store.getState().weeklyLeaderBoard.data.users.length;
          const leaderBoardLength = length < 50 ? length + 1 : length; //store.getState().weeklyLeaderBoard.data.users.length;
          store.dispatch(cntrlSetLeaderBoardPosition({roundId, isGlobal, leaderBoardLength}));
        });
    });

    await this.leaderBoardConnection.start()
      .catch(() => {
        console.log('Failed to start leaderBoardConnection');
        setTimeout(() => {
          store.dispatch(cntrlCreateLeaderboardConnection())
            .then(() => {
              const roundId = store.getState().lobby.data.round.id;
              const isGlobal = !!store.getState().lobby.data.globalRound;
              const leaderBoardLength = store.getState().weeklyLeaderBoard.data.users.length;
              store.dispatch(cntrlSetLeaderBoardPosition({roundId, isGlobal, leaderBoardLength}));
            });
        }, 5000);
      });
  };

  createRoundsConnection = async (store, action) => {
    this.roundsConnection = this.signalROperations.createRoundsConnection();

    this.roundsConnection.on(SIGNAL_R_CONSTANTS.ROUND_STATUS_CHANGED, (...data) => {
      this.signalROperations.updateRounds(store, data);
    });

    this.roundsConnection.on(SIGNAL_R_CONSTANTS.ROUND_CREATED, data => {
      this.signalROperations.addRound(store, data);
    });

    this.roundsConnection.on(SIGNAL_R_CONSTANTS.ROUND_PRIZE_FUND_CHANGED, data => {
      this.signalROperations.updatePrizeFund(store, data);
    });

    this.roundsConnection.on(SIGNAL_R_CONSTANTS.ROUND_ENTRIES_CHANGED, (...data) => {
      this.signalROperations.updateRoundEntries(store, data);
    });

    this.roundsConnection.onclose(error => {
      console.log(error, 'roundsBoardConnection Error');
      store.dispatch(cntrlCreateRoundsConnection());
    });

    await this.roundsConnection.start()
      .then(() => {
        const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);
        store.dispatch(cntrlSetPartnerId(Number(partnerId)));
      })
      .catch(() => {
        console.log('Failed to start roundsConnection');
        setTimeout(() => {
          store.dispatch(cntrlCreateRoundsConnection());
        }, 5000);
      })
  };

  setPartnerId = async (store, action) => {
    isConnected(this.roundsConnection) && this.roundsConnection.invoke(SIGNAL_R_CONSTANTS.SET_PARTNER_ID, action.payload)
      .then(() => console.log('Success sending message'))
      .catch(err => console.error(err.toString()));
  };

  setRoundAndUserforLiveMatchesConnection = async(store, action) => {
    const { roundId, userId, isGlobal } = action.payload;
    isConnected(this.liveMatchesConnection) && this.liveMatchesConnection.invoke(SIGNAL_R_CONSTANTS.SET_ROUND_USER, roundId, userId, isGlobal)
      .then(() => console.log('Success sending message'))
      .catch(err => console.error(err.toString()));
  };

  setLeaderBoardPosition = async(store, action) => {
    const { roundId, isGlobal, leaderBoardLength } = action.payload;
    isConnected(this.leaderBoardConnection) &&
    this.leaderBoardConnection.invoke(SIGNAL_R_CONSTANTS.SET_ROUND_LEADERBOARD_POSITION, roundId, isGlobal, leaderBoardLength)
      .then(() => console.log('Success sending leaderboardRank'))
      .catch(err => console.error(err.toString()));
  };

  closePredictionsConnection = () => {
    return this.predictionsConnection.stop();
  };

  closeLeaderBoardConnection = () => {
    return this.leaderBoardConnection.stop();
  }
}

export default SignalRController;
